import React, { useRef, useState } from "react";
import * as yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import "../../Styles/styles.css";

import { useAuth } from "../Authentication/AuthContext";
import { useMessage } from "../Utils/MessageContext";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingBackdrop from "../Utils/LoadingBackdrop";

export default function LoginForm() {
  // useRef to reference the current value of the text field
  const emailRef = useRef();
  const passwordRef = useRef();
  const rememberRef = useRef();

  const [showPassword, setShowPassword] = useState(false);

  // Import login() from Firebase Auth
  const { login, loading, setLoading } = useAuth();
  // Import MessageContext features to display
  const {
    showMessage,
    setMessage,
    statusMessage,
    messageType,
    displayMessage,
  } = useMessage();

  // Routing features from react-router-dom
  const navigate = useNavigate();
  const location = useLocation();
  // Go back to the previous location othewise go to main page
  // If use get to login page by trying to view a private route without login
  // The location variable in PrivateRoute component stores the from location
  const from = location.state?.from?.pathname || "/";

  // Set default value for login formik field
  const defaultValues = {
    email: "",
    password: "",
    remember: true, // remember me
  };

  // Show password function
  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  // Signup function passing from AuthContext
  async function handleLogIn() {
    /*
    get request to Wordpress DB contact form
    if(res.data == null) -> User never registered
    If user is member from previous semester, get the 
    */
    try {
      setLoading(true);
      // Set submitting message
      setMessage("Logging In...", "info");
      showMessage(true);
      // get current text field value passed to signup function
      // wait for firebase API to create an account
      await login(emailRef.current.value, passwordRef.current.value);
      // Set successful message
      setMessage("Log In Successfully! Redirecting...", "success");
      showMessage(true);
      // Wait 1s then redirect
      setTimeout(() => {
        navigate(from, { replace: true });
        showMessage(false);
      }, 1500);
    } catch (e) {
      let errorMessage = JSON.stringify(e.message);
      // If this is a firebase error, print the error message
      if (errorMessage.includes("Firebase")) {
        // console.log(errorMessage);
        // errorMessage =
        //   errorMessage.substring(
        //     errorMessage.indexOf(":") + 1,
        //     errorMessage.indexOf(".") + 1
        //   ) + " If you just signed up for membership and have yet created a MISSO App account, please click \'Create an Account\' above. (Code: 2246)";
        errorMessage = "Login failed. If you just signed up for membership and have not created a MISSO App account, please click 'Create an Account' above. (Code: 2246)"
      }
      // If not firebase error, print general error message
      else {
        errorMessage =
          "Cannot create an account, please try again or contact tech@misso.org. (2247)";
      }
      setMessage(errorMessage, "error");
      showMessage(true);
      // console.log(e);
    }
    setLoading(false);
  }

  // Validation for signup form using Yup package
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Please enter a valid email.")
      .required("Email is required."),
    password: yup
      .string()
      .required("Password is required.")
      .min(6, "Password must have at least 6 characters"),
  });

  // Initiate formik
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: handleLogIn,
  });

  const {
    touched,
    values,
    errors, // error condition for text field
    isSubmitting, // boolean of submi status
    handleSubmit, // method to handle form submission
    getFieldProps, // method to get field value
  } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Stack spacing={2} justifyContent="center" padding={"5px"}>
            <TextField
              className="signupInput"
              fullWidth
              autoFocus
              id="email"
              name="email"
              label="Email Address"
              inputRef={emailRef}
              {...getFieldProps("email")} // Set field value
              error={Boolean(touched.email && errors.email)} // set error condition
              helperText={formik.touched.email && formik.errors.email} // display error message
            />
            <TextField
              className="signupInput"
              fullWidth
              id="password"
              name="password"
              label="Password"
              inputRef={passwordRef}
              type={showPassword ? "text" : "password"}
              {...getFieldProps("password")}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  {...getFieldProps("remember")}
                  inputRef={rememberRef}
                  checked={values.remember}
                />
              }
              label="Remember Me"
            />

            <Button
              className="signUpButton"
              variant="contained"
              type="submit"
              disabled={isSubmitting ? true : false}
            >
              Log In
            </Button>
            {displayMessage && (
              <Alert severity={messageType}>
                {messageType.toUpperCase()}: {statusMessage}
              </Alert>
            )}
          </Stack>
        </Form>
      </FormikProvider>
      <LoadingBackdrop loading={loading} />
    </>
  );
}
