import React, { useState } from "react";

import { Button, CardMedia, Stack, Typography } from "@mui/material";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import "../../Styles/styles-education.css";
import VideoModal from "../Modals/VideoModal";

export default function VideoItem({ video }) {
  const [openVideoModal, setOpenVideoModal] = useState(false);

  function handlePlayModal() {
    setOpenVideoModal(!openVideoModal);
  }
  return (
    <div className="eduCard">
      {/* Stack horizontally flyer and event info */}
      <Stack direction="row">
        
        {/* Thumbnail */}
        <div className="eduLogo">
          <CardMedia
            component="img"
            sx={{ width: 150, height: 150 }}
            image={`${video.snippet.thumbnails.default.url}`}
            id="thumbnail"
          />
        </div>
        <Stack className="videoInfo">
          {/* Title */}
          <Typography variant="subtitle1" fontWeight="500" p={"5px"}>
            {video.snippet.title}
          </Typography>

          {/* Action Button */}
          <Button className="eduButton" id="play" onClick={handlePlayModal}>
            <PlayCircleOutlinedIcon />
            Play
          </Button>
        </Stack>
      </Stack>

      <VideoModal
        openModal={openVideoModal}
        handleModal={handlePlayModal}
        video={video}
      />
    </div>
  );
}
