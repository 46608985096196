import { Alert, Box } from "@mui/material";
import React from "react";
import AdminOpportunityList from "../../Components-Admin/Admin-Opportunities/AdminOpportunityList";
import Layout from "../../Components/Utils/Layout";
import { useMessage } from "../../Components/Utils/MessageContext";
import TitleBar from "../../Components/Utils/TitleBar";

export default function AdminOpportunities() {
  const { displayMessage, messageType, statusMessage } = useMessage();

  return (
    <div>
      <Layout>
        {displayMessage && (
          <Box className="pageMessage">
            <Alert severity={messageType}>
              {messageType.toUpperCase()}: {statusMessage}
            </Alert>
          </Box>
        )}
        <TitleBar
          title="Job Opportunities"
          description="Feature: Add, Edit, Delete Job Opportunities"
        />
        <AdminOpportunityList />
      </Layout>
    </div>
  );
}
