import React, { useContext, useState } from "react";

const MessageContext = React.createContext();

export function useMessage() {
  return useContext(MessageContext);
}

export function MessageProvider({ children }) {
// Temporary Message disapear after set second
  const [statusMessage, setStatusMessage] = useState(""); // success or error message
  const [displayMessage, setDisplayMessage] = useState(false); // boolean for show message
  const [messageType, setMessageType] = useState("");

  function setMessage(message, type) {
    setStatusMessage(message);
    setMessageType(type);
  }
  function showMessage(show) {
    setDisplayMessage(show);
    setTimeout(() => {
      setDisplayMessage(false);
      setMessage("", "")
    }, 10000);
  }

  const value = {
    statusMessage,
    displayMessage,
    messageType,
    setMessage,
    showMessage,
  };

  return (
    <MessageContext.Provider value={ value }>
      {children}
    </MessageContext.Provider>
  );
}
