import { Alert } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import AdminMyAccount from "../../Components-Admin/Admin-Officers/AdminMyAccount";
import Layout from "../../Components/Utils/Layout";
import { useMessage } from "../../Components/Utils/MessageContext";
import TitleBar from "../../Components/Utils/TitleBar";

export default function AdminProfile() {
  const { displayMessage, messageType, statusMessage } = useMessage();
  return (
    <div>
      <Layout>
        {displayMessage && (
          <Box className="pageMessage">
            <Alert severity={messageType}>
              {messageType.toUpperCase()}: {statusMessage}
            </Alert>
          </Box>
        )}
        <TitleBar title="My Officer Profile" />
        <AdminMyAccount />
      </Layout>
    </div>
  );
}
