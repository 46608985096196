import React, { useState } from "react";

import { Box, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function AdminOpportunityListSearch(props) {
  const [searchValue, setSearchValue] = useState("");

  function handleSearchChange(e) {
    setSearchValue(e.target.value);
    props.setSearchValue(e.target.value);
  }
  function handleSearch() {
    console.log(searchValue);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "300px",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
      >
        <Box>
          <TextField
            label="Search"
            defaultValue=""
            size="medium"
            fullWidth
            placeholder="Title, Company, Type, Recruiter"
            onChange={(e) => handleSearchChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </form>
    </Box>
  );
}
