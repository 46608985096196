import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableSortLabel,
  Button,
} from "@mui/material/";
import { fDateTime } from "../../Formatter/DateTimeFormat";
import AdminPointHistoryFilterBar from "./AdminPointHistoryFilterBar";

import "../../Styles/styles-myaccount.css";
import { getActivityLog } from "../../Admin-APIs/admin_members_functions";
import { useAuth } from "../../Components/Authentication/AuthContext";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteActivityLogModal from "./Admin-Member-Modals/DeleteActivityLogModal";
import AdminMemberListSearch from "./AdminMemberListSearch";

const TABLE_HEAD = [
  { id: "name", label: "Name", align: "left" }, // first & Last Name
  { id: "PSID", label: "PSID", align: "left" }, // 1111111
  { id: "type", label: "Type", align: "left" }, // PM, volunteer, social, committee
  { id: "points", label: "Points", align: "left" }, // 35, 15, 25
  { id: "description", label: "Description", align: "left" }, // check in [event name], committee duties, join [committee name]
  { id: "date", label: "Date", align: "left" }, //
];

export default function AdminActivityLog() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pointLogList, setPointLogList] = useState([]);
  const [filteredPointLogList, setFilteredPointLogList] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();
  const [sortIconDirection, setSortIconDirection] = useState("asc");
  const [filterBy, setFilterBy] = useState("all");
  const { setLoading } = useAuth();
  const [openDeleteLogModal, setOpenDeleteLogModal] = useState(false);
  const [logToDelete, setLogToDelete] = useState();

  function handleDeleteLogModal(logToDelete) {
    setOpenDeleteLogModal(!openDeleteLogModal);
    setLogToDelete(logToDelete);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortData = (column) => {
    if (order === "asc") {
      const sortedData = [...pointLogList].sort((a, b) =>
        a[column] > b[column] ? 1 : -1
      );
      setPointLogList(sortedData);
      setOrderBy(column);
      setSortIconDirection("asc");
      setOrder("desc");
    }
    if (order === "desc") {
      const sortedData = [...pointLogList].sort((a, b) =>
        a[column] < b[column] ? 1 : -1
      );
      setPointLogList(sortedData);
      setOrderBy(column);
      setSortIconDirection("desc");
      setOrder("asc");
    }
  };
  // Sort descending function by date
  const sortList = (arr) =>
    arr.sort(function (a, b) {
      var keyA = a.date,
        keyB = b.date;
      // Compare 2 'date' fields
      if (keyA < keyB) return 1; // if a < b -> switch
      if (keyA > keyB) return -1;
      return 0;
    });

  async function fetchData() {
    setLoading(true);
    const res = await getActivityLog();
    if (res.data) {
      setPointLogList(res.data);
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let point_log = sortList(pointLogList);
    handleChangePage(null, 0);
    // setFilteredPointLogList(point_log);
    // setFilteredPointLogList(
    //   filterBy === "all"
    //     ? point_log
    //     : filterBy === "event"
    //     ? point_log.filter((log) => !log.activity_code.startsWith("committee"))
    //     : filterBy === "committee"
    //     ? point_log.filter((log) => log.activity_code.startsWith("committee"))
    //     : point_log.filter(
    //         (log) =>
    //           log.first_name.toLowerCase().startsWith(filterBy.toLowerCase()) ||
    //           log.last_name.toLowerCase().startsWith(filterBy.toLowerCase()) ||
    //           log.psid.startsWith(filterBy)
    //       )
    // );
    setFilteredPointLogList(() => {
      if (filterBy === "all") {
        return point_log;
      }
      if (filterBy === "event") {
        return point_log.filter(
          (log) => !log.activity_code.startsWith("committee")
        );
      }
      if (filterBy === "committee") {
        return point_log.filter((log) =>
          log.activity_code.startsWith("committee")
        );
      }
      return point_log.filter(
        (log) =>
          log?.first_name?.toLowerCase().startsWith(filterBy.toLowerCase()) ||
          log?.last_name?.toLowerCase().startsWith(filterBy.toLowerCase()) ||
          log?.member_psid?.toString().startsWith(filterBy)
      );
    });
    setLoading(false);
  }, [filterBy, pointLogList]);

  return (
    <div>
      <TableContainer className="accountCard">
        {/* FILTER OPTIONS */}
        <AdminPointHistoryFilterBar setFilterBy={setFilterBy} />
        <AdminMemberListSearch setSearchValue={setFilterBy} />

        <Table sx={{ minWidth: 650 }} aria-label="point log table">
          {/* TABLE HEAD */}
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((item) => (
                <TableCell
                  key={item.id}
                  align={item.align}
                  onClick={() => sortData(item.id)}
                >
                  <TableSortLabel
                    active={orderBy === item.id}
                    direction={sortIconDirection}
                  >
                    {item.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* TABLE BODY */}
          <TableBody>
            {filteredPointLogList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((log) => {
                return (
                  <TableRow key={log.log_id} id="row">
                    <TableCell>
                      {log.first_name} {log.last_name}
                    </TableCell>
                    <TableCell>{log.member_psid}</TableCell>
                    <TableCell>
                      {!log.activity_code.startsWith("committee")
                        ? "Event Attendance"
                        : "Committee Activity"}
                    </TableCell>
                    <TableCell>
                      <Typography className="points">{log.points}</Typography>
                    </TableCell>
                    <TableCell>{log.description}</TableCell>
                    <TableCell>{fDateTime(log.created_time)}</TableCell>
                    {/* Delete activity log button */}
                    <TableCell>
                      {/* 20005 and above is all committee activity_id that can be deleted without deleting data in member_committee or event_attendance  */}
                      {20005 <= log.activity_id && (
                        <Button
                          size="small"
                          onClick={() => handleDeleteLogModal(log)}
                        >
                          <ClearIcon />
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {filteredPointLogList.length === 0 && (
          <Typography align="center" fontStyle="italic">
            No data to show.
          </Typography>
        )}
        {/* PAGE SELECTION */}
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={pointLogList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
      </TableContainer>
      {openDeleteLogModal && (
        <DeleteActivityLogModal
          openModal={openDeleteLogModal}
          handleModal={handleDeleteLogModal}
          logToDelete={logToDelete}
        />
      )}
    </div>
  );
}
