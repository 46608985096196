import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  Stack,
} from "@mui/material/";

import "../../Styles/styles-memberboard.css";
import { useAuth } from "../../Components/Authentication/AuthContext";
import { getAllCommitteeMembers } from "../../Admin-APIs/admin_members_functions";
import AdminMemberListSearch from "./AdminMemberListSearch";
import { fDate } from "../../Formatter/DateTimeFormat";
import AdminCommitteeMemberFilterBar from "./AdminCommitteeMemberFilterBar";
import AdminCommitteeMemberMenuOptions from "./AdminCommitteeMemberMenuOptions";

const TABLE_HEAD = [
  { id: "menu", label: "", align: "left" },
  { id: "com", label: "Committee", align: "left" },
  { id: "psid", label: "PSID", align: "left" },
  { id: "name", label: "Full Name", align: "left" },
  { id: "date", label: "Date Joined", align: "left" },
];

export default function AdminCommitteeMemberList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [coMemberList, setCoMemberList] = useState([]);
  const [filterMemberList, setFilteredMemberList] = useState([]);
  const [filterBy, setFilterBy] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const { setLoading } = useAuth();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Get members in committees function
  async function fetchMembers() {
    setLoading(true);
    const res = await getAllCommitteeMembers();
    if (res.data) {
      setCoMemberList(res.data);
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchMembers();
  }, []);

  useEffect(() => {
    // Set member list based on the search value. Default is ""
    setFilteredMemberList(
      searchValue === ""
        ? coMemberList
        : filterMemberList.filter(
            (member) =>
              member.first_name
                .toLowerCase()
                .startsWith(searchValue.toLowerCase()) ||
              member.last_name
                .toLowerCase()
                .startsWith(searchValue.toLowerCase()) ||
              member.psid.toString().startsWith(searchValue)
          )
    );
  }, [searchValue, coMemberList]);

  useEffect(() => {
    let com_list = coMemberList;
    setFilteredMemberList(
      filterBy === "all"
        ? com_list
        : com_list.filter((item) => item.name.includes(filterBy))
    );
    setSearchValue("");

    setLoading(false);
  }, [filterBy]);

  return (
    <>
      <TableContainer className="accountCard">
        {/* FILTER BAR */}
        <AdminCommitteeMemberFilterBar setFilterBy={setFilterBy} />
        <Stack direction="row" alignItems="center" spacing={2}>
          {/* SEARCH */}
          <AdminMemberListSearch
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          />
          {/* EXPORT COMMITTEE MEMBERS */}
          <Button
            variant="contained"
            sx={{
              maxWidth: "fit-content",
              marginBottom: "10px",
            }}
          >
            <CSVLink
              id="fileExport"
              data={filterMemberList}
              filename={"MISSO " + filterBy + " Committee"}
              target="_blank"
            >
              Export Committee Members
            </CSVLink>
          </Button>
        </Stack>
        {/* TABLE HEADER */}
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((item) => (
                <TableCell key={item.id} align={item.align}>
                  {item.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* COMMITTEE MEMBER LIST */}
          <TableBody>
            {filterMemberList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                return (
                  <TableRow key={index} id="row">
                    <TableCell>
                      <AdminCommitteeMemberMenuOptions chosenItem={item} />
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.psid}</TableCell>
                    <TableCell>
                      {item.first_name} {item.last_name}
                    </TableCell>
                    <TableCell>{fDate(item.created_time)}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {filterMemberList.length === 0 && (
          <Typography align="center" fontStyle="italic">
            No data to show.
          </Typography>
        )}

        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={filterMemberList.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
      </TableContainer>
    </>
  );
}
