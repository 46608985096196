import { Alert, Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EventFilterBar from "./EventFilterBar";
import EventItem from "./EventItem";
import { getAllEventsOrderByTime } from "../../APIs/event_functions";
import { fDate, fMonthOnly, today } from "../../Formatter/DateTimeFormat";
import { useAuth } from "../Authentication/AuthContext";
import { compareAsc, parseISO } from "date-fns";
import getISOWeek from "date-fns/getISOWeek";

// Event list contains multiple cards of event item
export default function EventList() {
  const [eventList, setEventList] = useState([]);
  const [filterTime, setFilterTime] = useState("all");
  const [showError, setShowError] = useState(false);
  const { setLoading } = useAuth();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const res = await getAllEventsOrderByTime(); // oldest to newest
      if (res.data) {
        // console.log(res.data);
        // Filter to show only events that have not ended and future events
        // const filteredEvents = res.data.filter(
        //   (event) =>
        //     /*
        //      * Compare the two dates and return 1 if the first date is after the second,
        //      * -1 if the first date is before the second or 0 if dates are equal.
        //      * Only show if events end date is after today
        //      * parseISO() convert string date to JS date because compareAsc doesn't take string as arg
        //      */
        //     compareAsc(parseISO(event.end), new Date(today)) >= 0
        // );
        const filteredEvents = res.data;
        // Filter By Type
        setEventList(
          filterTime === "all"
            ? filteredEvents
            : filteredEvents.filter(
                (event) => event.activity_code === filterTime
              )
        );

        // Filter By Time
        switch (filterTime) {
          case "all":
            setEventList(filteredEvents);
            break;

          case "today":
            setEventList(
              filteredEvents.filter(
                (event) =>
                  fDate(event.start) === fDate(today) ||
                  fDate(event.end) === fDate(today)
              )
            );
            break;

          case "thisWeek":
            setEventList(
              filteredEvents.filter(
                (event) =>
                  getISOWeek(new Date(event.start)) === getISOWeek(today) ||
                  getISOWeek(new Date(event.end)) === getISOWeek(today)
              )
            );
            break;

          case "nextWeek":
            setEventList(
              filteredEvents.filter(
                (event) =>
                  getISOWeek(new Date(event.start)) === getISOWeek(today) + 1 ||
                  getISOWeek(new Date(event.end)) === getISOWeek(today) + 1
              )
            );
            break;

          case "thisMonth":
            setEventList(
              filteredEvents.filter(
                (event) =>
                  fMonthOnly(event.start) === fMonthOnly(today) ||
                  fMonthOnly(event.end) === fMonthOnly(today)
              )
            );
            break;

          case "nextMonth":
            console.log(parseInt(fMonthOnly(today)));
            setEventList(
              filteredEvents.filter(
                (event) =>
                  parseInt(fMonthOnly(event.start)) ===
                    parseInt(fMonthOnly(today)) + 1 ||
                  parseInt(fMonthOnly(event.end)) ===
                    parseInt(fMonthOnly(today)) + 1
              )
            );
            break;
        }
      } else setShowError(true);
      setLoading(false);
    }
    fetchData();
  }, [filterTime]);

  return (
    <>
      {showError && (
        <Alert severity="error">
          ERROR: Cannot get event data. (Code: 7248)
        </Alert>
      )}

      <EventFilterBar setFilterTime={setFilterTime} />
      {eventList && (
        <Grid container spacing={5}>
          {eventList.map((event) => {
            return (
              <Grid item xs={12} xl={6} key={event.event_id}>
                <EventItem event={event} />
              </Grid>
            );
          })}
        </Grid>
      )}
      {eventList.length === 0 && (
        <Box mt={5} ml={5} maxWidth="fit-content">
          <Typography fontStyle="italic">No event found.</Typography>
        </Box>
      )}
    </>
  );
}
