import React, { useEffect, useState } from "react";

import { Box, Button, Stack } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { getAllCommitteeInfo } from "../../APIs/getinvolved_functions";

export default function AdminCommitteeMemberFilterBar(props) {
  const [committees, setCommittees] = useState([]);
  function handleFilter(type) {
    // console.log(type);
    props.setFilterBy(type);
  }

  useEffect(() => {
    async function fetchData() {
      const res = await getAllCommitteeInfo();
      if (res.data) {
        setCommittees(res.data);
      }
    }
    fetchData();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack className="eventFilterBar" direction="row" spacing={2}>
        <FilterListIcon color="primary" />
        <Button id="filter" onClick={() => handleFilter("all")}>
          All
        </Button>
        {committees.map((item) => {
          return (
            <Button id="filter" onClick={() => handleFilter(item.name)}>
              {item.name}
            </Button>
          );
        })}
      </Stack>
    </Box>
  );
}
