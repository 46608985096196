import React, { useState } from "react";

import { Button, CardMedia, Stack, Typography } from "@mui/material";
import "../../Styles/styles-education.css";

import "../../Styles/styles-development.css";
import ResumeUploadModal from "../Modals/ResumeUploadModal";

export default function DevelopmentItem({ pdItem }) {
  const [openResumeModal, setOpenResumeModal] = useState(false);

  function uploadResumeModal() {
    setOpenResumeModal(!openResumeModal);
  }

  function handleSignUp() {
    if (pdItem.name !== "Resume Review")
      window.open(`${pdItem.appointment_url}`);
    else {
      uploadResumeModal();
    }
  }

  return (
    <div className="developmentCard">
      {/* Stack horizontally flyer and event info */}
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        {/* Flyer */}
        <div className="developmentFlyer">
          <CardMedia
            component="img"
            sx={{
              width: { xs: "100%", sm: 200 },
              height: { xs: "100%", sm: 200 },
            }}
            image={`${pdItem.logo_url}`}
            id="photo"
          />
        </div>
        {/* Content */}
        <Stack spacing={1} alignItems={{ xs: "center", sm: "start" }} pb={2}>
          {/* Title */}
          <Typography variant="h3" p={"5px"}>
            {pdItem.name}
          </Typography>
          {/* Description */}
          <Typography p={2}>{pdItem.description}</Typography>

          {/* Action Button */}
          <Button
            className="developmentButton"
            id="signup"
            onClick={handleSignUp}
          >
              
           {
            pdItem.name == "Networking 101" ? "Open" :
            pdItem.name == "Resume Template" ? "Open" : "Sign Up"
            }

          </Button>
            
        </Stack>
      </Stack>
      {openResumeModal && (
        <ResumeUploadModal
          openModal={openResumeModal}
          handleModal={handleSignUp}
        />
      )}
    </div>
  );
}

