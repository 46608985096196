import { Alert, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllCommitteesByMember } from "../../APIs/member_functions";
import { fDate } from "../../Formatter/DateTimeFormat";

import "../../Styles/styles-myaccount.css";

export default function UserCommitttees({ profile }) {
  const [memberCommittee, setMemberCommittee] = useState([]);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const res = await getAllCommitteesByMember(profile.psid);
      if (res.data) {
        setMemberCommittee(res.data);
      } else setShowError(true);
    }
    fetchData();
  }, []);
  return (
    <div className="accountCard">
      {showError && (
        <Alert severity="error">
          ERROR: Cannot get committee info. (Code: 2222)
        </Alert>
      )}

      <Grid container p={3}>
        <Grid item xs={4}>
          <Typography id="label">Committees</Typography>
        </Grid>
        {memberCommittee.length > 0 ? (
          <Grid item xs={8}>
            <Stack spacing={2}>
              {/* Use map() to get all committees */}
              {memberCommittee.map((item, index) => {
                return (
                  <Stack key={index}>
                    <Typography>{item.name}</Typography>
                    <Typography variant="subtile1" fontSize="12px">
                      Joined: {fDate(item.created_time)}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        ) : "Not in any committee"}
      </Grid>
    </div>
  );
}
