import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Stack,
} from "@mui/material/";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import "../../Styles/styles-memberboard.css";
import { useMessage } from "../Utils/MessageContext";
import { openLinkedIn } from "../Utils/LinkedIn";

export default function OfficerList({ officers, team }) {
  const { setMessage, showMessage } = useMessage();

  function handleNoSocialMedia(type, person) {
    setMessage(`${person} has not updated their ${type}.`, "info");
    showMessage(true);
  }

  return (
    <>
      {/* TABLE for Each Team */}
      <TableContainer className="teamCard">
        <Table sx={{ minWidth: 650 }}>
          <TableBody>
            {officers.map((officer) => {
              return (
                officer.team === team.name && (
                  <TableRow key={officer.officer_id} sx={{ display: "flex" }}>
                    <TableCell id="cell">{officer.position}</TableCell>
                    <TableCell id="cell">
                      {officer.first_name} {officer.last_name}
                    </TableCell>

                    <TableCell id="cell">
                      <Stack direction="row" spacing={1}>
                        <LinkedInIcon
                          id={
                            officer.linkedIn
                              ? "linkedInButton"
                              : "socialIconDisable"
                          }
                          onClick={() =>
                            officer.linkedIn
                              ? openLinkedIn(`${officer.linkedIn}`)
                              : handleNoSocialMedia(
                                  "LinkedIn",
                                  officer.first_name
                                )
                          }
                        />
                        <InstagramIcon
                          id={
                            officer.instagram
                              ? "instagramButton"
                              : "socialIconDisable"
                          }
                          onClick={() =>
                            officer.instagram
                              ? window.open(
                                  `https://www.instagram.com/${officer.instagram}`
                                )
                              : handleNoSocialMedia(
                                  "Instagram",
                                  officer.first_name
                                )
                          }
                        />
                      </Stack>
                    </TableCell>
                    <TableCell id="cell">
                      <Button
                        sx={{
                          textTransform: "lowercase",
                          borderRadius: "12px",
                          padding: "0px 10px",
                          overflow: "hidden",
                        }}
                        onClick={() => window.open(`mailto:${officer.alias}`)}
                      >
                        <MailOutlineIcon sx={{ paddingRight: "5px" }} />
                        {officer.alias}
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
