import React from "react";
import "../../Styles/styles.css";

import Layout from "../../Components/Utils/Layout";
import TitleBar from "../../Components/Utils/TitleBar";
import DevelopmentList from "../../Components/Development/DevelopmentList";
import { useMessage } from "../../Components/Utils/MessageContext";
import { Alert, Box } from "@mui/material";


export default function Events() {
  const { displayMessage, messageType, statusMessage } = useMessage();
  return (
    <Layout>
      {displayMessage && (
        <Box className="pageMessage">
          <Alert severity={messageType}>
            {messageType.toUpperCase()}: {statusMessage}
          </Alert>
        </Box>
      )}
      <TitleBar title={"Professional Development"} />
      <DevelopmentList />
    </Layout>
  );
}
