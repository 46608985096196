import React, { useState } from "react";
import {
  Alert,
  Link,
  Stack,
  Typography,
  TableContainer,
  TableCell,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import { fDateTime } from "../../Formatter/DateTimeFormat";
import "../../Styles/styles-myaccount.css";

import EditIcon from "@mui/icons-material/Edit";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import EditSocialMediaModal from "../Modals/EditSocialMediaModal";
import { useMessage } from "../Utils/MessageContext";
import { openLinkedIn } from "../Utils/LinkedIn";

export default function UserInfo({ profile }) {
  const [openSocialMediaModal, setOpenSocialMediaModal] = useState(false);
  const { setMessage, showMessage } = useMessage();

  function handleEditSocialModal() {
    setOpenSocialMediaModal(!openSocialMediaModal);
  }

  function handleNoSocialMedia(type) {
    setMessage(`You have not updated your ${type}.`, "info");
    showMessage(true);
  }

  return (
    <div className="accountCard">
      {!profile && (
        <Alert severity="error">
          ERROR: Cannot get user info. (Code: 2242)
        </Alert>
      )}

      <EditSocialMediaModal
        openModal={openSocialMediaModal}
        handleModal={handleEditSocialModal}
        profile={profile}
      />

      <TableContainer p={3}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography id="label">Full Name</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">
                  {profile.first_name} {profile.last_name}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">Email</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">{profile.email}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">Cougarnet ID</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">{profile.cougarnet}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">Gender</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">{profile.gender}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">Classification</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">{profile.classification}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">Major</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">{profile.major}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">Second Major</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">{profile.second_major}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">Minor</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">{profile.minor}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">Graduation</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">
                  {profile.grad_semester} {profile.grad_year}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">Ethnicity</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">{profile.ethnicity}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">Age</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">{profile.age}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">Member Status</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">{profile.status}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">Membership Type</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">{profile.membership_type}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">T-Shirt Size</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">{profile.shirt_size}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">GroupMe Username</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">{profile.groupme}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">Social Media</Typography>
              </TableCell>
              <TableCell>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <LinkedInIcon
                    id={
                      profile.linkedIn ? "linkedInButton" : "socialIconDisable"
                    }
                    onClick={() =>
                      profile.linkedIn
                        ? openLinkedIn(`${profile.linkedIn}`)
                        : handleNoSocialMedia("LinkedIn")
                    }
                  />
                  <InstagramIcon
                    id={
                      profile.instagram
                        ? "instagramButton"
                        : "socialIconDisable"
                    }
                    onClick={() =>
                      profile.instagram
                        ? window.open(
                            `https://www.instagram.com/${profile.instagram}`
                          )
                        : handleNoSocialMedia("Instagram")
                    }
                  />
                  <Link
                    component="button"
                    variant="body2"
                    onClick={handleEditSocialModal}
                  >
                    <EditIcon fontSize="10px" />
                    Edit
                  </Link>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">Account Created On</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">
                  {fDateTime(profile.created_time)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="label">
                <Typography id="label">Account Updated On</Typography>
              </TableCell>
              <TableCell>
                <Typography id="value">
                  {fDateTime(profile.updated_time)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography fontStyle="italic" p={2}>
        Please contact tech@misso.org if you wish to update your personal
        information
      </Typography>
    </div>
  );
}
