import React from "react";

import { Box, Button, Stack } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

export default function PointHistoryFilterBar(props) {
  function handleFilter(type) {
    // console.log(type);
    props.setFilterBy(type);
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack className="eventFilterBar" direction="row" spacing={2}>
        <FilterListIcon color="primary" />
        <Button id="filter" onClick={() => handleFilter("all")}>
          All
        </Button>
        <Button id="filter" onClick={() => handleFilter("event")}>
          Event Attendance
        </Button>
        <Button id="filter" onClick={() => handleFilter("committee")}>
          Committee Activity
        </Button>
        <Button id="filter" onClick={() => handleFilter("other")}>
          Other Activity
        </Button>
      </Stack>
    </Box>
  );
}
