import React from "react";
import "../../Styles/styles.css";

import Layout from "../../Components/Utils/Layout";
import TitleBar from "../../Components/Utils/TitleBar";

import UserInfo from "../../Components/My-Account/UserInfo";
import UserCommittees from "../../Components/My-Account/UserCommittees";
import UserPoints from "../../Components/My-Account/UserPoints";
import PointHistory from "../../Components/My-Account/PointHistory";

import { Alert, Box, Grid, Stack } from "@mui/material";
import { useAuth } from "../../Components/Authentication/AuthContext";
import { useMessage } from "../../Components/Utils/MessageContext";
import CareerMixerResume from "../../Components/My-Account/CareerMixerResume";

export default function Events() {
  const { profile, isAdmin } = useAuth();
  const { displayMessage, statusMessage, messageType } = useMessage();

  return (
    <Layout>
      {displayMessage && (
        <Box className="pageMessage">
          <Alert severity={messageType}>
            {messageType.toUpperCase()}: {statusMessage}
          </Alert>
        </Box>
      )}
      <TitleBar title={"My Information"} />
      <Grid container>
        <Grid item xs={12}>
          <Stack spacing={2}>
            {!isAdmin() && <UserInfo profile={profile} />}
            <CareerMixerResume profile={profile} />
            <UserCommittees profile={profile} />
            {!isAdmin() && <UserPoints profile={profile} />}
            <PointHistory profile={profile} />
          </Stack>
        </Grid>
      </Grid>
    </Layout>
  );
}
