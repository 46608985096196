import React from "react";

import {
  Box,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TablePagination,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function VideoFilterBar(props) {
  function handleSearchChange(e) {
    props.setSearchValue(e.target.value);
    props.setPage(0);
  }

  async function handlePlaylist(event) {
    props.setFilterBy(event.target.value);
    props.setPage(0);
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(event.target.value);
    props.setPage(0);
  };

  return (
    <>
      <Box
        sx={{
          justifyContent: "",
          alignItems: "flex-start",
          flexDirection: "column",
          display: "flex",
        }}
      >
        {/* Stack playlist and search box horizontally */}
        <Stack
          // alignItems="flex-end"
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          width="100%"
        >
          <TextField
            label="Search"
            value={props.searchValue}
            sx={{ minWidth: 250 }}
            placeholder="Search for Videos"
            onChange={(e) => handleSearchChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Select
            value={props.filterBy}
            sx={{ minWidth: 250 }}
            onChange={handlePlaylist}
          >
            <MenuItem value={"all"}>All Playlists</MenuItem>
            {props.playlists.map((playlist, index) => {
              return (
                <MenuItem key={index} value={playlist.id}>
                  {playlist.snippet.title}
                </MenuItem>
              );
            })}
          </Select>
        </Stack>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          labelRowsPerPage="Videos"
          component="div"
          count={props.filteredList.length}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
      </Box>
    </>
  );
}
