import React from "react";

import { Button, CardMedia, Stack, Typography } from "@mui/material";
import EventNoteIcon from "@mui/icons-material/EventNote";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";

import { fDate } from "../../Formatter/DateTimeFormat";
import "../../Styles/styles-opportunities.css";
import { Box } from "@mui/system";

// Card layout for each opportunity item
export default function OpportunityItem(props) {
  const opportunity = props.opportunity;

  function handleOpenLink(link) {
    window.open(`${link}`, "_blank");
  }

  // Format date to number
  const deadline = fDate(opportunity.opp_deadline);

  return (
    <div className="oppCard">
      {/* Stack flyer-description and details-buttons */}
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        {/* Company Logo */}
        <div className="oppFlyer">
          <CardMedia
            component="img"
            p={2}
            sx={{
              width: { xs: "100%", md: 200 },
              height: { xs: "250px", md: 200 },
            }}
            image={opportunity.opp_logo}
            id="photo"
          />
        </div>
        <Stack p={2} spacing={1}>
          {/* Stack Opportunity flags - Company - Job Title */}
          <Stack spacing={1} alignItems={{ xs: "center", md: "flex-start" }}>
            {/* Opportunity Type Flag */}
            <Typography
              id="type"
              bgcolor={"#A4F9C8"}
              textTransform="uppercase"
              variant="subtitle2"
              alignItems="center"
            >
              {opportunity.opp_type}
            </Typography>

            {/* Opportunity Company */}
            <Typography variant="h3" textTransform="capitalize">
              {opportunity.opp_company}
            </Typography>
          </Stack>
          <Stack width="100%" spacing={0.8}>
            {/* Job Title */}
            <Stack direction="row" spacing="5px" alignItems="center">
              <WorkIcon />
              <Typography>Job Title: {opportunity.opp_job_title}</Typography>
            </Stack>

            {/* Recruiter Name */}
            <Stack direction="row" spacing="5px" alignItems="center">
              <PersonIcon />
              <Typography>Recruiter: {opportunity.opp_recruiter}</Typography>
            </Stack>

            {/* Contact Info */}
            <Stack direction="row" spacing="5px" alignItems="center">
              <EmailIcon />
              <Typography>Contact: {opportunity.opp_contact}</Typography>
            </Stack>

            {/* Deadline */}
            <Stack direction="row" spacing="5px" alignItems="center">
              <EventNoteIcon />
              <Typography>
                {/* */}
                Application Deadline: {deadline}
              </Typography>
            </Stack>
          </Stack>
          {/* Stack Buttons */}
          <Stack
            direction="row"
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            {/* Flyer Link Button*/}
            {opportunity.opp_flyer_link && (
              <Button
                className="oppButton"
                id="flyer"
                onClick={() => handleOpenLink(opportunity.opp_flyer_link)}
              >
                More Info
              </Button>
            )}
            {/* Application Link Button */}
            {opportunity.opp_link && (
              <Button
                className="oppButton"
                id="app"
                onClick={() => handleOpenLink(opportunity.opp_link)}
              >
                Apply
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}
