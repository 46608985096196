import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { useAuth } from "./Components/Authentication/AuthContext";

import SignupPage from "./Pages/AuthPages/SignupPage";
import LoginPage from "./Pages/AuthPages/LoginPage";
import ResetPassPage from "./Pages/AuthPages/ResetPassPage";

import Events from "./Pages/App/Events";
import Education from "./Pages/App/Education";
import GetInvolved from "./Pages/App/GetInvolved";
import Development from "./Pages/App/Development";
import Opportunities from "./Pages/App/Opportunities";
import MemberBoard from "./Pages/App/MemberBoard";
import OfficerBoard from "./Pages/App/OfficerBoard";
import MyAccount from "./Pages/App/MyAccount";

import PrivateRoute from "./Components/Utils/PrivateRoute";

import AdminRoute from "./Components/Utils/AdminRoute";
import AdminEvent from "./Pages/Admin/AdminEvent";
import AdminEducation from "./Pages/Admin/AdminEducation";
import AdminProfile from "./Pages/Admin/AdminProfile";
import AdminRoster from "./Pages/Admin/AdminRoster";
import AdminGetInvolved from "./Pages/Admin/AdminGetInvolved";
import AdminMembers from "./Pages/Admin/AdminMembers";
import AdminOpportunities from "./Pages/Admin/AdminOpportunities";

export default function Router() {
  const { currentUser } = useAuth();
  return (
    <Routes>
      <Route
        path="/"
        // If logged in redirect to events dashboard, else login
        element={<Navigate to={currentUser ? "/events" : "/login"} />}
      />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/resetpassword" element={<ResetPassPage />} />

      {/* ROUTE TO APP FEATURES */}
      <Route
        path="/events"
        element={
          <PrivateRoute>
            <Events />
          </PrivateRoute>
        }
      />
      <Route
        path="/education"
        element={
          <PrivateRoute>
            <Education />
          </PrivateRoute>
        }
      />
      <Route
        path="/getinvolved"
        element={
          <PrivateRoute>
            <GetInvolved />
          </PrivateRoute>
        }
      />
      <Route
        path="/development"
        element={
          <PrivateRoute>
            <Development />
          </PrivateRoute>
        }
      />
      <Route
        path="/opportunities"
        element={
          <PrivateRoute>
            <Opportunities />
          </PrivateRoute>
        }
      />
      <Route
        path="/memberboard"
        element={
          <PrivateRoute>
            <MemberBoard />
          </PrivateRoute>
        }
      />
      <Route
        path="/officerboard"
        element={
          <PrivateRoute>
            <OfficerBoard />
          </PrivateRoute>
        }
      />
      <Route
        path="/myaccount"
        element={
          <PrivateRoute>
            <MyAccount />
          </PrivateRoute>
        }
      />

      <Route
        path="/admin/events"
        element={
          <AdminRoute>
            <AdminEvent />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/members"
        element={
          <AdminRoute>
            <AdminMembers />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/education"
        element={
          <AdminRoute>
            <AdminEducation />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/getinvolved"
        element={
          <AdminRoute>
            <AdminGetInvolved />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/opportunities"
        element={
          <AdminRoute>
            <AdminOpportunities />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/profile"
        element={
          <AdminRoute>
            <AdminProfile />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/roster"
        element={
          <AdminRoute>
            <AdminRoster />
          </AdminRoute>
        }
      />
    </Routes>
  );
}
