import EventNoteIcon from "@mui/icons-material/EventNote"; // Events
import SchoolIcon from "@mui/icons-material/School"; // Education
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"; // Get Involved
import WorkIcon from "@mui/icons-material/Work"; // Development
import LinkIcon from "@mui/icons-material/Link";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents"; // Member Board
import LocalPoliceIcon from "@mui/icons-material/LocalPolice"; // Officer Board
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"; // My Account
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

export const SidebarData = [
  {
    title: "Events",
    path: "/events",
    icon: <EventNoteIcon />,
    className: "navData",
  },
  {
    title: "Education",
    path: "/education",
    icon: <SchoolIcon />,
    className: "navData",
  },
  {
    title: "Get Involved",
    path: "/getinvolved",
    icon: <PeopleAltIcon />,
    className: "navData",
  },
  {
    title: "Development",
    path: "/development",
    icon: <WorkIcon />,
    className: "navData",
  },
  {
    title: "Opportunities",
    path: "/opportunities",
    icon: <LinkIcon />,
    className: "navData",
  },
  {
    title: "Member Board",
    path: "/memberboard",
    icon: <EmojiEventsIcon />,
    className: "navData",
  },
  {
    title: "Officer Board",
    path: "/officerboard",
    icon: <LocalPoliceIcon />,
    className: "navData",
  },
  {
    title: "My Account",
    path: "/myaccount",
    icon: <ManageAccountsIcon />,
    className: "navData",
  },
];

export const AdminSidebarData = [
  {
    title: "Admin Members",
    path: "/admin/members",
    icon: <AdminPanelSettingsIcon />,
    className: "navData",
  },
  {
    title: "Admin Events",
    path: "/admin/events",
    icon: <AdminPanelSettingsIcon />,
    className: "navData",
  },
  {
    title: "Admin Education",
    path: "/admin/education",
    icon: <AdminPanelSettingsIcon />,
    className: "navData",
  },
  {
    title: "Admin Activities",
    path: "/admin/getinvolved",
    icon: <AdminPanelSettingsIcon />,
    className: "navData",
  },
  {
    title: "Admin Jobs",
    path: "/admin/opportunities",
    icon: <AdminPanelSettingsIcon />,
    className: "navData",
  },
  {
    title: "My Officer Profile",
    path: "/admin/profile",
    icon: <AdminPanelSettingsIcon />,
    className: "navData",
  },
  {
    title: "Officer Roster",
    path: "/admin/roster",
    icon: <AdminPanelSettingsIcon />,
    className: "navData",
  },
];
