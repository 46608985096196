import React from "react";
import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import "../../Styles/styles-modal.css";
import { useMessage } from "../../Components/Utils/MessageContext";
import { useAuth } from "../../Components/Authentication/AuthContext";
import { updateCodingTime } from "../../Admin-APIs/admin_education_functions";

export default function EditEducationModal({
  openModal,
  handleModal,
  chosenItem,
  fetchCodingTimes,
}) {
  const { showMessage, setMessage } = useMessage();
  const { setLoading } = useAuth();

  async function handleEditEducation() {
    setLoading(true);
    try {
      const updateEduSuccessfully = await updateCodingTime(formik.values);
      if (updateEduSuccessfully.data) {
        setMessage(
          `${chosenItem.name} Coding Time was updated successfully.`,
          "success"
        );
        fetchCodingTimes();
      } else {
        setMessage(`Unable to update event. (Code: 42156)`, "error");
      }
    } catch (e) {
      setMessage("Failed to update event. (Code: 12486)", "error");
    }
    showMessage(true);
    handleModal(!openModal);
    setLoading(false);
  }

  const validationSchema = yup.object({
    officer_name: yup
      .string()
      .max(45, "Max 45 characters.")
      .required("This field is required."),
    weekday1: yup
      .string()
      .max(45, "Max 45 characters.")
      .required("This field is required."),
    time1: yup
      .string()
      .max(45, "Max 45 characters.")
      .required("This field is required."),
    weekday2: yup
      .string()
      .max(45, "Max 45 characters.")
      .required("This field is required."),
    time2: yup
      .string()
      .max(45, "Max 45 characters.")
      .required("This field is required."),
    meeting_link: yup
      .string()
      .max(255, "Max 255 characters.")
      .required("This field is required.")
      .url("Must include 'https://' at the beginning."),
    groupme_link: yup
      .string()
      .max(255, "Max 255 characters.")
      .required("This field is required.")
      .url("Must include 'https://' at the beginning."),
    drive_link: yup
      .string()
      .max(255, "Max 255 characters.")
      .required("This field is required.")
      .url("Must include 'https://' at the beginning."),
  });

  const defaultValues = {
    name: chosenItem.name,
    officer_name: chosenItem.officer_name,
    weekday1: chosenItem.weekday1,
    time1: chosenItem.time1,
    weekday2: chosenItem.weekday2,
    time2: chosenItem.time2,
    meeting_link: chosenItem.meeting_link,
    groupme_link: chosenItem.groupme_link,
    drive_link: chosenItem.drive_link,
  };

  // Initiate formik
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: handleEditEducation,
  });

  const {
    touched,
    errors, // error condition for text field
    getFieldProps, // method to help touch error
    handleSubmit,
    isSubmitting,
  } = formik;

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalAdminForm" overflow="auto">
          <Typography variant="h6">
            Update {chosenItem.name} Coding Time
          </Typography>
          <FormikProvider value={formik}>
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Stack spacing={2} padding={2}>
                <TextField
                  fullWidth
                  size="small"
                  name="officer_name"
                  label="Officer Name"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("officer_name")}
                  error={Boolean(touched.officer_name && errors.officer_name)}
                  helperText={touched.officer_name && errors.officer_name}
                />
                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    size="small"
                    name="weekday1"
                    label="Day 1"
                    //   inputRef={linkedInRef}
                    {...getFieldProps("weekday1")}
                    error={Boolean(touched.weekday1 && errors.weekday1)}
                    helperText={touched.weekday1 && errors.weekday1}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    name="time1"
                    label="Start Time - End Time"
                    //   inputRef={linkedInRef}
                    {...getFieldProps("time1")}
                    error={Boolean(touched.time1 && errors.time1)}
                    helperText={touched.time1 && errors.time1}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    size="small"
                    name="weekday2"
                    label="Day 2"
                    //   inputRef={linkedInRef}
                    {...getFieldProps("weekday2")}
                    error={Boolean(touched.weekday2 && errors.weekday2)}
                    helperText={touched.weekday2 && errors.weekday2}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    name="time2"
                    label="Start Time - End Time"
                    //   inputRef={linkedInRef}
                    {...getFieldProps("time2")}
                    error={Boolean(touched.time2 && errors.time2)}
                    helperText={touched.time2 && errors.time2}
                  />
                </Stack>
                <TextField
                  fullWidth
                  size="small"
                  name="meeting_link"
                  label="Meeting Link"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("meeting_link")}
                  error={Boolean(touched.meeting_link && errors.meeting_link)}
                  helperText={touched.meeting_link && errors.meeting_link}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="groupme_link"
                  label="GroupMe Link"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("groupme_link")}
                  error={Boolean(touched.groupme_link && errors.groupme_link)}
                  helperText={touched.groupme_link && errors.groupme_link}
                />
              </Stack>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  className="checkInButton"
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
                <Button
                  className="checkInButton"
                  variant="text"
                  onClick={handleModal}
                >
                  Cancel
                </Button>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </div>
  );
}
