import React from "react";
import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import "../../Styles/styles-modal.css";
import { useMessage } from "../../Components/Utils/MessageContext";
import { useAuth } from "../../Components/Authentication/AuthContext";
import { insertOpportunity } from "../../Admin-APIs/admin_opportunity_functions";
import { fDateHtml } from "../../Formatter/DateTimeFormat";

export default function AddOpportunityModal({
  openModal,
  handleModal,
  fetchItems,
}) {
  const { showMessage, setMessage } = useMessage();
  const { setLoading } = useAuth();

  async function handleAddOpp() {
    setLoading(true);
    if (formik.values.opp_recruiter.trim() === "") {
      formik.values.opp_recruiter = "N/A";
    }
    if (formik.values.opp_contact.trim() === "") {
      formik.values.opp_contact = "N/A";
    }
    if (formik.values.opp_logo.trim() === "") {
      formik.values.opp_logo =
        "https://misso.org/wp-content/uploads/2019/01/cropped-misso-logo-1.jpg";
    }
    try {
      const addOppSuccesfully = await insertOpportunity(formik.values);
      if (addOppSuccesfully.data) {
        setMessage(`New job opportunity was added successfully`, "success");
      } else {
        setMessage(`Unable to add job opportunity. (Code: 975636223)`, "error");
      }
      fetchItems();
    } catch (e) {
      setMessage("Failed to add job opportunity. (Code: 132464745)", "error");
    }
    showMessage(true);
    handleModal(!openModal);
    setLoading(false);
  }

  const validationSchema = yup.object({
    opp_company: yup
      .string()
      .max(45, "Max 45 characters.")
      .required("This field is required."),
    opp_job_title: yup
      .string()
      .max(255, "Max 45 characters.")
      .required("This field is required."),
    opp_type: yup
      .string()
      .max(15, "Max 15 characters.")
      .required("This field is required."),
    opp_recruiter: yup.string().max(45, "Max 45 characters."),
    opp_contact: yup.string().max(45, "Max 45 characters."),
    opp_link: yup
      .string()
      .max(255, "Max 255 characters.")
      .url("Must include 'https://' at the beginning."),
    opp_flyer_link: yup
      .string()
      .max(255, "Max 255 characters.")
      .url("Must include 'https://' at the beginning."),
  });

  const defaultValues = {
    opp_company: "",
    opp_job_title: "",
    opp_recruiter: "N/A",
    opp_contact: "N/A",
    opp_type: "",
    opp_deadline: fDateHtml(new Date()),
    opp_link: "",
    opp_flyer_link: "",
    opp_logo: "",
  };

  // Initiate formik
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: handleAddOpp,
  });

  const {
    touched,
    errors, // error condition for text field
    getFieldProps, // method to help touch error
    handleSubmit,
    isSubmitting,
  } = formik;

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalAdminForm" overflow="auto">
          <Typography variant="h6">Add New Job opportunity</Typography>
          <FormikProvider value={formik}>
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Stack spacing={2} padding={2}>
                <TextField
                  fullWidth
                  size="small"
                  name="opp_company"
                  label="Company Name"
                  {...getFieldProps("opp_company")}
                  error={Boolean(touched.opp_company && errors.opp_company)}
                  helperText={touched.opp_company && errors.opp_company}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="opp_job_title"
                  label="Job Title"
                  {...getFieldProps("opp_job_title")}
                  error={Boolean(touched.opp_job_title && errors.opp_job_title)}
                  helperText={touched.opp_job_title && errors.opp_job_title}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="opp_type"
                  label="Job Type"
                  {...getFieldProps("opp_type")}
                  error={Boolean(touched.opp_type && errors.opp_type)}
                  helperText={touched.opp_type && errors.opp_type}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="opp_recruiter"
                  label="Recruiter (Optional)"
                  {...getFieldProps("opp_recruiter")}
                  error={Boolean(touched.opp_recruiter && errors.opp_recruiter)}
                  helperText={touched.opp_recruiter && errors.opp_recruiter}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="opp_contact"
                  label="Contact (Optional)"
                  {...getFieldProps("opp_contact")}
                  error={Boolean(touched.opp_contact && errors.opp_contact)}
                  helperText={touched.opp_contact && errors.opp_contact}
                />
                <TextField
                  name="opp_deadline"
                  size="small"
                  label="Deadline"
                  {...getFieldProps("opp_deadline")}
                  type="date"
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="opp_link"
                  label="Link to Application (Optional)"
                  {...getFieldProps("opp_link")}
                  error={Boolean(touched.opp_link && errors.opp_link)}
                  helperText={touched.opp_link && errors.opp_link}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="opp_flyer_link"
                  label="Link to Flyer (Optional)"
                  {...getFieldProps("opp_flyer_link")}
                  error={Boolean(
                    touched.opp_flyer_link && errors.opp_flyer_link
                  )}
                  helperText={touched.opp_flyer_link && errors.opp_flyer_link}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="opp_logo"
                  label="Company Logo (Optional - Default MISSO Logo)"
                  {...getFieldProps("opp_logo")}
                  error={Boolean(touched.opp_logo && errors.opp_logo)}
                  helperText={touched.opp_logo && errors.opp_logo}
                />
              </Stack>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  className="checkInButton"
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
                <Button
                  className="checkInButton"
                  variant="text"
                  onClick={handleModal}
                >
                  Cancel
                </Button>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </div>
  );
}
