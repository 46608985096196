import { Modal, Typography, Stack, Button } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { removeActivityLog } from "../../../Admin-APIs/admin_members_functions";
import { useAuth } from "../../../Components/Authentication/AuthContext";
import { useMessage } from "../../../Components/Utils/MessageContext";

export default function DeleteActivityLogModal({
  openModal,
  handleModal,
  logToDelete,
}) {
  const { showMessage, setMessage } = useMessage();
  const { setLoading } = useAuth();

  async function handleDeleteLog() {
    setLoading(true);
    try {
      const removeActivityLogSucessfully = await removeActivityLog(logToDelete);
      if (removeActivityLogSucessfully.data) {
        setMessage(`Delete activity log successfully`, "success");
      } else {
        setMessage(`Unable to delete activity log. (Code: 2313246)`, "error");
      }
    } catch (e) {
      console.log(e);
      setMessage(`Failed to delete activity log. (Code: 2313247)`, "error");
    }
    showMessage(true);
    handleModal(!openModal);
    setLoading(false);
    window.location.reload();
  }

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalForm">
          <Typography variant="h6">
            Are you sure you want to delete this activity log?
          </Typography>
          <Typography>
            Member: {logToDelete.first_name} {logToDelete.last_name}
          </Typography>
          <Typography>PSID: {logToDelete.member_psid}</Typography>
          <Typography>Points to remove: {logToDelete.points}</Typography>
          <Typography>Description: {logToDelete.description}</Typography>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={() => {
                handleDeleteLog();
              }}
            >
              Confirm
            </Button>
            <Button variant="text" onClick={() => handleModal()}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
