import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "../../Styles/styles.css";
import logo from "../../Styles/MISSO-logo.png";
import ResetPasswordForm from "../../Components/AuthForm/ResetPasswordForm";
import { Link } from "react-router-dom";

const ResetPassPage = () => {
  return (
    <div className="signUpBackground">
      <Container className="signUpContainer" maxWidth="sm">
        <Box className="signUpBox" textAlign={"center"}>
          <img src={logo} className="logo" alt="MISSO Logo"/>
          <Typography variant="h3" align="center">
            Reset Your Password
          </Typography>
          <Typography variant="body1" align="center" mb={2}>
            <Link to="/login" className="authLink">
              Log In
            </Link>
          </Typography>
          <ResetPasswordForm />
        </Box>
      </Container>
    </div>
  );
};

export default ResetPassPage;
