import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../Authentication/AuthContext";
import { useMessage } from "./MessageContext";

export default function PrivateRoute({ children }) {
  const { currentUser } = useAuth();
  const { showMessage, setMessage } = useMessage();
  const location = useLocation();

  // Print message if user attempt to view a private page without logging in
  useEffect(() => {
    if (!currentUser) {
      setMessage("You are not logged in.", "info");
      showMessage(true);
    } else {
      // setProfileForFrontEnd(currentUser.email);
    }
  }, []);

  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected (const location).
  // This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  return (
    <>
      {currentUser ? ( // if there is firebase user and profile from logging in
        children
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </>
  );
}
