import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import SignupForm from "../../Components/AuthForm/SignupForm";
import "../../Styles/styles.css";
import logo from "../../Styles/MISSO-logo.png";
import { Link } from "react-router-dom";
import { useAuth } from "../../Components/Authentication/AuthContext";

const SignupPage = () => {
  const { signOut } = useAuth();
  useEffect(() => {
    signOut();
  }, []);
  return (
    <div className="signUpBackground">
      <Container className="signUpContainer" maxWidth="sm">
        <Box className="signUpBox" textAlign={"center"}>
          <img src={logo} className="logo" alt="logo" />

          <Typography variant="h3" align="center">
            Create An Account
          </Typography>

          <Typography variant="body1" align="center" mt={1} mb={2}>
            Need to sign up for membership?
            <a
              href="https://misso.org/signup/"
              target="_blank"
              className="authLink"
              rel="noreferrer"
            >
              Register
            </a>
          </Typography>

          <SignupForm />
        </Box>

        <Typography variant="body1" align="center" mt={1}>
          Arealdy have an account?
          <Link to="/login" className="authLink">
            Log In
          </Link>
        </Typography>
      </Container>
    </div>
  );
};

export default SignupPage;
