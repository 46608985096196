import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableSortLabel,
  Stack,
} from "@mui/material/";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

import MemberListSearch from "./MemberListSearch";
import { getMemberBoard } from "../../APIs/member_functions";
import { useAuth } from "../Authentication/AuthContext";

import "../../Styles/styles-memberboard.css";
import { useMessage } from "../Utils/MessageContext";
import { openLinkedIn } from "../Utils/LinkedIn";

const TABLE_HEAD = [
  { id: "first_name", label: "First", align: "left" }, // PM, volunteer, social, committee
  { id: "last_name", label: "Last", align: "left" }, // EY, Beach social, bake sales, Operation
  { id: "social", label: "Social Media", align: "left" }, // EY, Beach social, bake sales, Operation // EY, Beach social, bake sales, Operation
  { id: "total_points", label: "Points", align: "left" }, // 35, 15, 25
  { id: "rank", label: "Rank", align: "left" }, // add, remove
];

export default function MemberList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [memberList, setMemberList] = useState([]);
  const [filterMemberList, setFilteredMemberList] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();
  const [sortIconDirection, setSortIconDirection] = useState("asc");
  const [searchValue, setSearchValue] = useState("");
  const { setLoading } = useAuth();
  const { setMessage, showMessage } = useMessage();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleNoSocialMedia(type, person) {
    setMessage(`${person} has not updated their ${type}.`, "info");
    showMessage(true);
  }

  const sortData = (column) => {
    if (order === "asc") {
      const sortedData = [...memberList].sort((a, b) =>
        a[column] > b[column] ? 1 : -1
      );
      setMemberList(sortedData);
      setOrderBy(column);
      setSortIconDirection("asc");
      setOrder("desc");
    }
    if (order === "desc") {
      const sortedData = [...memberList].sort((a, b) =>
        a[column] < b[column] ? 1 : -1
      );
      setMemberList(sortedData);
      setOrderBy(column);
      setSortIconDirection("desc");
      setOrder("asc");
    }
  };
  /* Sort descending function
    This function loops through each pair of elements in the array
    Pass the pair of elements to the function that returns 1 or -1
    If 1, switch position of 2 element
  */
  // ********* Something wrong with this function (8/20/2022)
  // function sortList(arr) {
  //   arr.sort(function (a, b) {
  //     var keyA = a.total_points,
  //       keyB = b.total_points;
  //     // Compare 2 'points' fields
  //     if (keyA < keyB) return 1; // if a < b -> switch
  //     if (keyA > keyB) return -1;
  //     return 0;
  //   });
  //   console.log(arr);
  //   return arr;
  // }

  // Get member board API

  // Ranking function
  useEffect(() => {
    async function fetchMembers() {
      setLoading(true);
      let members_;
      const res = await getMemberBoard();
      if (res.data.length > 0) {
        // Sort res.data from highest points to lowest
        // console.log(res.data);
        // members_ = sortList(res.data);
        members_ = res.data;
        let cur_rank = 1;
        let cur_points = members_[0].total_points; // members_[0] has the most points
        members_ = members_.map((member) => {
          // If the first one has 0 point, meaning no one has gotten any points yet so no need to compare
          if (member.total_points > 0) {
            if (member.total_points === cur_points) {
              // equal
              member.rank = cur_rank;
            } else {
              /* if second member points lower than first one,
              increment current rank and assign that to the second
              */
              cur_rank += 1;
              member.rank = cur_rank;
              // Set new current point to continue evaluation
              cur_points = member.total_points;
            }
          }
          return member;
        });
        setMemberList(members_);
      }
      setLoading(false);
    }
    fetchMembers();
  }, []);

  useEffect(() => {
    // Set member list based on the search value. Default is ""
    setFilteredMemberList(
      searchValue === ""
        ? memberList
        : memberList.filter(
            (member) =>
              member.first_name
                .toLowerCase()
                .startsWith(searchValue.toLowerCase()) ||
              member.last_name
                .toLowerCase()
                .startsWith(searchValue.toLowerCase()) ||
              CryptoJS.AES.decrypt(member.psid.toString(), "Hello")
                .toString(CryptoJS.enc.Utf8)
                .startsWith(searchValue)
            // Decrypt the psid from backend response
          )
    );
  }, [searchValue, memberList]);

  return (
    <>
      <TableContainer className="accountCard">
        {/* SEARCH */}
        <MemberListSearch setSearchValue={setSearchValue} />
        {/* TABLE HEADER */}
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((item, index) => (
                <TableCell
                  key={index}
                  align={item.align}
                  onClick={() => sortData(item.id)}
                >
                  <TableSortLabel
                    active={orderBy === item.id}
                    direction={sortIconDirection}
                  >
                    {item.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* MEMBER LIST */}
          <TableBody>
            {filterMemberList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((member, index) => {
                return (
                  <TableRow key={index} className="row">
                    <TableCell>{member.first_name.toLowerCase()}</TableCell>
                    <TableCell>{member.last_name.toLowerCase()}</TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1} ml="10px">
                        <LinkedInIcon
                          id={
                            member.linkedIn
                              ? "linkedInButton"
                              : "socialIconDisable"
                          }
                          onClick={() =>
                            member.linkedIn
                              ? openLinkedIn(`${member.linkedIn}`)
                              : handleNoSocialMedia(
                                  "LinkedIn",
                                  member.first_name
                                )
                          }
                        />
                        <InstagramIcon
                          id={
                            member.instagram
                              ? "instagramButton"
                              : "socialIconDisable"
                          }
                          onClick={() =>
                            member.instagram
                              ? window.open(
                                  `https://www.instagram.com/${member.instagram}`
                                )
                              : handleNoSocialMedia(
                                  "Instagram",
                                  member.first_name
                                )
                          }
                        />
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography id="points">{member.total_points}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography id="rank">{member.rank}</Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {filterMemberList.length === 0 && (
          <Typography align="center" fontStyle="italic">
            No data to show.
          </Typography>
        )}
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={memberList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
      </TableContainer>
    </>
  );
}
