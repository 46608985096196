import { Alert, Box } from "@mui/material";
import React from "react";
import AdminCommitteeList from "../../Components-Admin/Admin-GetInvolved/AdminCommitteeList";
import AdminSocialMedia from "../../Components-Admin/Admin-GetInvolved/AdminSocialList";
import Layout from "../../Components/Utils/Layout";
import { useMessage } from "../../Components/Utils/MessageContext";
import TitleBar from "../../Components/Utils/TitleBar";

export default function AdminGetInvolved() {
  const { displayMessage, messageType, statusMessage } = useMessage()

  return (
    <div>
      <Layout>
        {displayMessage && (
          <Box className="pageMessage">
            <Alert severity={messageType}>
              {messageType.toUpperCase()}: {statusMessage}
            </Alert>
          </Box>
        )}
        <TitleBar title="Committees" description="Feature: Edit committees information"/>
        <AdminCommitteeList />
        <TitleBar title="Social Media" description="Feature: Edit MISSO social media information"/>
        <AdminSocialMedia />
      </Layout>
    </div>
  );
}
