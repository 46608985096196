import React from "react";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";

import { useMessage } from "../../../Components/Utils/MessageContext";

import "../../../Styles/styles-modal.css";
import { deleteFromMemberCommittee } from "../../../APIs/member_functions";
import { useAuth } from "../../../Components/Authentication/AuthContext";
import { removeCommitteeMember } from "../../../Admin-APIs/admin_members_functions";

export default function RemoveCommitteeMemberModal({
  openModal,
  handleModal,
  chosenItem,
}) {
  const { setLoading } = useAuth();
  const { showMessage, setMessage } = useMessage();

  async function handleRemoveCommitteeMember() {
    setLoading(true);
    try {
      const res1 = await removeCommitteeMember(chosenItem);
      const res2 = await deleteFromMemberCommittee(
        chosenItem.psid,
        chosenItem.committee_id
      );
      if (res1.data && res2.data) {
        setMessage(
          `Successfully removed ${chosenItem.first_name} ${chosenItem.last_name} from ${chosenItem.name} committee.`,
          "success"
        );
      } else {
        setMessage(
          "Unable to remove member from committee. (Code: 98732123)",
          "error"
        );
      }
    } catch (e) {
      setMessage(
        "Failed to remove member from committee. (Code: 987324)",
        "error"
      );
    }
    setLoading(false);
    showMessage(true);
    handleModal();
    window.location.reload();
  }

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalConfirm">
          <Stack spacing={3}>
            <Typography variant="h6">
              {`Do you want to remove ${chosenItem.first_name} ${chosenItem.last_name} from ${chosenItem.name} committee?`}
            </Typography>
            <Stack direction="row" spacing={2} alignSelf="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  handleRemoveCommitteeMember();
                }}
              >
                Confirm
              </Button>
              <Button variant="text" onClick={() => handleModal()}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
