import React, { useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import "../../Styles/styles-modal.css";
import { useMessage } from "../../Components/Utils/MessageContext";
import { useAuth } from "../../Components/Authentication/AuthContext";
import { updateCommittee } from "../../Admin-APIs/admin_getinvoled_functions";

export default function EditCommitteeModal({
  openModal,
  handleModal,
  chosenItem,
  fetchItems
}) {
  const { showMessage, setMessage } = useMessage();
  const { setLoading } = useAuth();
  const [selected, handleSelected] = useState(chosenItem.is_open);

  async function handleEditCommittee() {
    setLoading(true);
    try {
      const updateCommitteeSuccessfully = await updateCommittee({
        committee_id: chosenItem.committee_id,
        ...formik.values,
      });
      if (updateCommitteeSuccessfully.data) {
        setMessage(`${chosenItem.name} was updated successfully`, "success");
      } else {
        setMessage(`Unable to update committee. (Code: 5432168)`, "error");
      }
      fetchItems();
    } catch (e) {
      setMessage("Failed to update event. (Code: 2308956)", "error");
    }
    showMessage(true);
    handleModal(!openModal);
    setLoading(false);
  }

  const validationSchema = yup.object({
    description: yup
      .string()
      .max(200, "Max 45 characters.")
      .matches(/^[ A-Za-z0-9!@#$%^&*()_+-.]*$/, "Enter letter, digits or _@./#&+- only.")
      .required("This field is required."),
    groupme_link: yup
      .string()
      .max(255, "Max 255 characters.")
      .required("This field is required.")
      .url("Must include 'https://' at the beginning."),
    officer_name: yup
      .string()
      .max(45, "Max 45 characters.")
      .matches(/^[ A-Za-z0-9!@#$%^&*()_+-.]*$/, "Enter letter, digits or _@./#&+- only.")
      .required("This field is required."),
    officer_email: yup
      .string()
      .email("Enter a valid email.")
      .max(45, "Max 45 characters.")
      .required("This field is required."),
  });

  const defaultValues = {
    description: chosenItem.description,
    groupme_link: chosenItem.groupme_link,
    officer_name: chosenItem.officer_name,
    officer_email: chosenItem.officer_email,
    is_open: chosenItem.is_open,
  };

  // Initiate formik
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: handleEditCommittee,
  });

  const {
    touched,
    errors, // error condition for text field
    getFieldProps, // method to help touch error
    handleSubmit,
    isSubmitting,
  } = formik;

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalAdminForm" overflow="auto">
          <Typography variant="h6">
            Update {chosenItem.name} Committee
          </Typography>
          <FormikProvider value={formik}>
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Stack spacing={2} padding={2}>
                <TextField
                  fullWidth
                  size="small"
                  name="officer_name"
                  label="Oficer Name"
                  {...getFieldProps("officer_name")}
                  error={Boolean(touched.officer_name && errors.officer_name)}
                  helperText={touched.officer_name && errors.officer_name}
                />
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  size="small"
                  name="description"
                  label="Description"
                  {...getFieldProps("description")}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="groupme_link"
                  label="GroupMe Link"
                  {...getFieldProps("groupme_link")}
                  error={Boolean(touched.groupme_link && errors.groupme_link)}
                  helperText={touched.groupme_link && errors.groupme_link}
                />
              </Stack>
              <Stack
                spacing={2}
                padding={2}
                direction="row"
                alignItems="center"
              >
                <Typography>Is Open?</Typography>
                <Select
                  value={selected}
                  size="small"
                  onChange={(e) => handleSelected(e)}
                  {...getFieldProps("is_open")}
                >
                  <MenuItem value={0}>No</MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                </Select>
              </Stack>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  className="checkInButton"
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
                <Button
                  className="checkInButton"
                  variant="text"
                  onClick={handleModal}
                >
                  Cancel
                </Button>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </div>
  );
}
