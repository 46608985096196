import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "../../Styles/styles.css";
import logo from "../../Styles/MISSO-logo.png";
import LoginForm from "../../Components/AuthForm/LogInForm";
import { Link } from "react-router-dom";

const SignupPage = () => {
  return (
    <div className="signUpBackground">
      <Container className="signUpContainer" maxWidth="sm">
        <Box className="signUpBox" textAlign={"center"}>
          <img src={logo} className="logo" alt="MISSO Logo" />
          <Typography variant="h3" align="center" p={1}>
            Member Log In
          </Typography>

          <Typography variant="body1" align="center" p={1}>
            Need a MISSO App Account?
            <Link to="/signup" className="authLink">
              Create an Account
            </Link>
          </Typography>

          {/* User Manual Link */}
          <Typography variant="body1" align="center" mb={2}>
            Need a guide to the App?
            <a
              href="https://misso.org/misso-member-app-guide/"
              target="_blank"
              className="authLink"
              rel="noreferrer"
            >
              User Manual
            </a>
          </Typography>

          <LoginForm />
        </Box>

        <Typography variant="body1" align="center">
          <Link to="/resetpassword" className="authLink">
            Forgot Password
          </Link>
        </Typography>
      </Container>
    </div>
  );
};

export default SignupPage;
