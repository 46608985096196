import React, { useRef } from "react";
import * as yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Alert, Button, Stack, TextField } from "@mui/material";

import "../../Styles/styles.css";

import { useAuth } from "../Authentication/AuthContext";
import { useMessage } from "../Utils/MessageContext";

export default function LoginForm() {
  // useRef to reference the current value of the text field
  const emailRef = useRef();

  // Import login() from Firebase Auth
  const { setLoading, resetPassword } = useAuth();

  // Import MessageContext features to display
  const {
    showMessage,
    setMessage,
    statusMessage,
    messageType,
    displayMessage,
  } = useMessage();

  // Set default value for login formik field
  const defaultValues = {
    email: "",
  };

  // Show password function

  // Signup function passing from AuthContext
  async function handleResetPass() {
    /*
    get request to Wordpress DB contact form
    if(res.data == null) -> User never registered
    If user is member from previous semester, get the 
    */
    // Set submitting message
    // get current text field value passed to resetPass()
    setLoading(true);
    setMessage("Resetting your password...", "info");
    showMessage(true);
    try {
          // wait for firebase API to reset password
      await resetPassword(emailRef.current.value);
      // Set successful message
      setMessage(
        "Password reset! Please check your email INBOX or SPAM for instruction to set new password.",
        "success"
      );
      showMessage(true);
    } catch (e) {
      let errorMessage = JSON.stringify(e.message);
      // If this is a firebase error, print the error message
      if (errorMessage.includes("Firebase")) {
        errorMessage =
          errorMessage.substring(
            errorMessage.indexOf(":") + 1,
            errorMessage.indexOf(".") + 1
          ) + " (Code: 3346)";
      }
      // If not firebase error, print general error message
      else {
        errorMessage =
          "Cannot reset password, please try again or contact tech@misso.org. (Code: 3347)";
      }
      setMessage(errorMessage, "error");
      showMessage(true);
      // console.log(e);
    }
    setLoading(false);
  }

  // Validation for signup form using Yup package
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Please enter a valid email.")
      .required("Email is required."),
  });

  // Initiate formik
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: handleResetPass,
  });

  const {
    touched,
    errors, // error condition for text field
    isSubmitting, // boolean of submi status
    handleSubmit, // method to handle form submission
    getFieldProps, // method to get field value
  } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Stack spacing={2} justifyContent="center" padding={"5px"}>
            <TextField
              className="signupInput"
              fullWidth
              id="email"
              name="email"
              label="Email Address"
              inputRef={emailRef}
              {...getFieldProps("email")} // Set field value
              error={Boolean(touched.email && errors.email)} // set error condition
              helperText={formik.touched.email && formik.errors.email} // display error message
            />
            <Button
              className="signUpButton"
              variant="contained"
              type="submit"
              disabled={isSubmitting ? true : false}
            >
              Reset Your Password
            </Button>
            {displayMessage && (
              <Alert severity={messageType}>
                {messageType.toUpperCase()}: {statusMessage}
              </Alert>
            )}
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
