import React from "react";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";

import { useMessage } from "../Utils/MessageContext";

import "../../Styles/styles-modal.css";
import {
  deleteFromMemberCommittee,
} from "../../APIs/member_functions";
import { useAuth } from "../Authentication/AuthContext";

export default function CancelCommitteeModal({
  openModal,
  handleModal,
  committee,
  setCancelSignUp,
}) {
  const { setLoading, profile } = useAuth();

  const { showMessage, setMessage } = useMessage();

  async function handleCancelSignUp() {
    setLoading(true);
    try {
      const deleteMemberCommittee = await deleteFromMemberCommittee(
        profile.psid,
        committee.committee_id
      );
      // Delete log
      if (deleteMemberCommittee.data) {
        // Fire trigger in member_committee to delete from activity log
        setMessage(`You are no longer in ${committee.name} committee.`, "info");
        setCancelSignUp(false); // Stop showing Cancel Signup Button
      } else {
        setMessage("Cannot cancel your join request at. (Code: 4218)", "error");
      }
    } catch (e){
      setMessage("Failed to sign up to event. (Code: 4219)", "error");
    }
    setLoading(false);
    showMessage(true);
    handleModal();
  }

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalConfirm">
          <Stack spacing={3}>
            <Typography variant="h6">
              Do you want to leave {committee.name} Committee?
            </Typography>
            <Typography variant="body1">
              You will lose {committee.points} points.
            </Typography>
            <Stack direction="row" spacing={2} alignSelf="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  handleCancelSignUp();
                }}
              >
                Confirm
              </Button>
              <Button variant="text" onClick={() => handleModal()}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
