import React, { useEffect, useState } from "react";
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardMedia,
} from "@mui/material/";
import { useAuth } from "../../Components/Authentication/AuthContext";

import "../../Styles/styles-memberboard.css";
import { useMessage } from "../../Components/Utils/MessageContext";
import { getAllSocialMedia } from "../../APIs/getinvolved_functions";
import AdminSocialMenuOption from "./AdminSocialMenuOption";

const TABLE_HEAD = [
  { label: "", align: "left" },
  { label: "SM Name", align: "left" },
  { label: "SM Link", align: "left" },
  { label: "SM Logo", align: "left" },
];

export default function AdminSocialMedia() {
  const [socialMedia, setSocialMedia] = useState([]);
  const { setLoading } = useAuth();
  const { setMessage, showMessage } = useMessage();

  // Get All Admin Events
  async function fetchItems() {
    setLoading(true);
    const res = await getAllSocialMedia();
    if (res.data) {
      setSocialMedia(res.data);
    } else {
      setMessage("Cannot get admin social media. Code (2186)");
      showMessage(true);
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      <TableContainer className="accountCard">
        {/* TABLE HEADER */}
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((item, index) => (
                <TableCell key={index} align={item.align}>
                  {item.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* MEMBER LIST */}
          <TableBody>
            {socialMedia.map((item, index) => {
              return (
                <TableRow key={index} id="row">
                  <TableCell>
                    <AdminSocialMenuOption chosenItem={item} fetchItems={fetchItems}/>
                  </TableCell>
                  <TableCell>{item.sm_name}</TableCell>
                  <TableCell>
                    <Link href={`${item.sm_link}`} target="_blank">
                      Link
                    </Link>
                  </TableCell>
                  <TableCell width="100px">
                    <CardMedia
                      component="img"
                      height="50px"
                      image={`${item.sm_logo}`}
                      sx={{ borderRadius: "12px", objectFit: "cover" }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
