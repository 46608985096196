import React from "react";
import "../../Styles/styles.css";

import Layout from "../../Components/Utils/Layout";
import TitleBar from "../../Components/Utils/TitleBar";
import MemberList from "../../Components/Member-Board/MemberList";
import { useMessage } from "../../Components/Utils/MessageContext";
import { Box } from "@mui/system";
import { Alert } from "@mui/material";

export default function Events() {
  const { displayMessage, messageType, statusMessage } = useMessage()
  return (
    <Layout>
      {displayMessage && (
        <Box className="pageMessage">
          <Alert severity={messageType}>
            {messageType.toUpperCase()}: {statusMessage}
          </Alert>
        </Box>
      )}
      <TitleBar title={"Member Board"} />
      <MemberList />
    </Layout>
  );
}
