import React, { useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import "../../Styles/styles-modal.css";
import { useMessage } from "../../Components/Utils/MessageContext";
import { useAuth } from "../../Components/Authentication/AuthContext";
import { updateOfficerProfile } from "../../Admin-APIs/admin_officers_functions";

export default function EditOfficerProfile({
  openModal,
  handleModal,
  adminProfile,
  fetchProfile,
}) {
  const { showMessage, setMessage } = useMessage();
  const { setLoading } = useAuth();
  const [selected, handleSelected] = useState(adminProfile.office_access);

  async function handleEditOfficer() {
    setLoading(true);
    try {
      const updateProfileSuccessfully = await updateOfficerProfile(
        formik.values,
        adminProfile.officer_id
      );
      if (updateProfileSuccessfully.data) {
        setMessage(`Your profile was updated successfully`, "success");
        fetchProfile();
      } else {
        setMessage(`Unable to update profile. (Code: 324656)`, "error");
      }
    } catch (e) {
      setMessage("Failed to update profile. (Code: 2534678)", "error");
    }
    showMessage(true);
    handleModal(!openModal);
    setLoading(false);
  }

  const validationSchema = yup.object({
    first_name: yup
      .string()
      .max(45, "Max 45 characters.")
      .required("This field is required."),
    last_name: yup
      .string()
      .max(45, "Max 45 characters.")
      .required("This field is required."),
    personal_email: yup
      .string()
      .email("Enter a valid email.")
      .max(45, "Max 45 characters.")
      .required("This field is required."),
    phone: yup.number().required("This field is required."),
    psid: yup.number().required("This field is required."),
    linkedIn: yup
      .string()
      .max(255, "Maximum 255 characters.")
      .url("Your URL should contain 'https://'")
      .matches(
        /(https:\/\/(www.)?linkedin\.com\/in\/)+([a-zA-Z]+)/,
        "Please enter a valid LinkedIn URL"
      ),
    instagram: yup.string().max(45, "Max 45 characters."),
    shirt_size: yup
      .string()
      .max(10, "Maximum 10 characters.")
      .required("This field is required."),
  });

  const defaultValues = {
    first_name: adminProfile.first_name,
    last_name: adminProfile.last_name,
    personal_email: adminProfile.personal_email,
    phone: adminProfile.phone,
    psid: adminProfile.psid,
    office_access: adminProfile.office_access,
    linkedIn: adminProfile.linkedIn,
    instagram: adminProfile.instagram,
    shirt_size: adminProfile.shirt_size,
  };

  // Initiate formik
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: handleEditOfficer,
  });

  const {
    touched,
    errors, // error condition for text field
    getFieldProps, // method to help touch error
    handleSubmit,
    isSubmitting,
  } = formik;

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalAdminForm" overflow="auto">
          <Typography variant="h6">
            Update {adminProfile.position} profile
          </Typography>
          <FormikProvider value={formik}>
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Stack spacing={2} padding={2}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    size="small"
                    name="first_name"
                    label="First Name"
                    //   inputRef={linkedInRef}
                    {...getFieldProps("first_name")}
                    error={Boolean(touched.first_name && errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    name="last_name"
                    label="Last Name"
                    //   inputRef={linkedInRef}
                    {...getFieldProps("last_name")}
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    size="small"
                    name="personal_email"
                    label="Personal Email"
                    //   inputRef={linkedInRef}
                    {...getFieldProps("personal_email")}
                    error={Boolean(
                      touched.personal_email && errors.personal_email
                    )}
                    helperText={touched.personal_email && errors.personal_email}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    size="small"
                    name="phone"
                    label="Phone (digits only)"
                    //   inputRef={linkedInRef}
                    {...getFieldProps("phone")}
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    name="psid"
                    label="PSID"
                    //   inputRef={linkedInRef}
                    {...getFieldProps("psid")}
                    error={Boolean(touched.psid && errors.psid)}
                    helperText={touched.psid && errors.psid}
                  />
                </Stack>
                <TextField
                  fullWidth
                  size="small"
                  name="linkedIn"
                  label="LinkedIn"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("linkedIn")}
                  error={Boolean(touched.linkedIn && errors.linkedIn)}
                  helperText={touched.linkedIn && errors.linkedIn}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="instagram"
                  label="Instagram (Username Only)"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("instagram")}
                  error={Boolean(touched.instagram && errors.instagram)}
                  helperText={touched.instagram && errors.instagram}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="shirt_size"
                  label="Shirt Size"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("shirt_size")}
                  error={Boolean(touched.shirt_size && errors.shirt_size)}
                  helperText={touched.shirt_size && errors.shirt_size}
                />
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="body2">Office Access</Typography>
                  <Select
                    value={selected}
                    size="small"
                    onChange={(e) => handleSelected(e)}
                    {...getFieldProps("office_access")}
                  >
                    <MenuItem value={0}>No</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                  </Select>
                </Stack>
              </Stack>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  className="checkInButton"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting ? true : false}
                >
                  Submit
                </Button>
                <Button
                  className="checkInButton"
                  variant="text"
                  onClick={handleModal}
                >
                  Cancel
                </Button>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </div>
  );
}
