import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";

import "../../../Styles/styles-modal.css";
import { insertToActivityLog } from "../../../APIs/member_functions";
import { useMessage } from "../../../Components/Utils/MessageContext";
import { useAuth } from "../../../Components/Authentication/AuthContext";

export default function AddFundPointsModal({
  openModal,
  handleModal,
  chosenItem,
  dutyTypes,
}) {
  const { setMessage, showMessage } = useMessage();
  const { profile, setLoading } = useAuth();
  const defaultActivityCode = dutyTypes[0].code;
  const defaultActivityID = dutyTypes[0].activity_id;

  async function handleAddPoints() {
    setLoading(true);
    try {
      const insertToActivity = await insertToActivityLog(
        formik.values.member_psid,
        formik.values.dutyCode.includes("fun") ? 30000 : 40000, // value for fundraising or volunteer
        formik.values.dutyCode, // activities column "code" in activities table
        formik.values.description,
        profile.email
      );
      if (insertToActivity.data) {
        setMessage("Added points successfully", "success");
      } else {
        setMessage("Unable to add points. (Code: 76543)", "error");
      }
    } catch (e) {
      setMessage("Failed to add points. (Code: 32168785)", "error");
    }
    handleModal();
    showMessage(true);
    formik.resetForm();
    window.location.reload();
  }

  // Validation for signup form using Yup package
  const validationSchema = yup.object({
    description: yup.string().required("Description is required"),
  });

  const defaultValues = {
    member_psid: chosenItem.psid,
    activity_id: defaultActivityID,
    dutyCode: defaultActivityCode,
    description: "",
  };

  // Initiate formik
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: handleAddPoints,
    enableReinitialize: true,
    validateOnMount: true,
  });

  const {
    touched,
    errors, // error condition for text field
    isSubmitting, // boolean of submit status
    handleSubmit, // method to handle form submission
    getFieldProps, // method to help touch error
  } = formik;

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalForm">
          <Typography variant="h6">
            Add Points for {chosenItem.first_name} {chosenItem.last_name} -{" "}
            {chosenItem.psid}
          </Typography>
          <FormikProvider value={formik}>
            <Form
              onSubmit={(e) => {
                // e.preventDefault();
                handleSubmit();
              }}
            >
              <Stack spacing={2} padding={"5px"}>
                <Select
                  value={formik.values.dutyCode}
                  onChange={formik.handleChange}
                  name="dutyCode"
                >
                  {dutyTypes.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={item.code}
                        name={item.activity_id}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <TextField
                  fullWidth
                  size="small"
                  name="description"
                  label="Description"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("description")}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Stack>
              <Stack spacing={2} padding={"5px"}>
                <Button
                  className="checkInButton"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting || !formik.isValid ? true : false}
                >
                  Submit
                </Button>
                <Button
                  className="checkInButton"
                  variant="text"
                  onClick={() => handleModal()}
                  disabled={isSubmitting ? true : false}
                >
                  Cancel
                </Button>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </div>
  );
}
