import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { useAuth } from "../../Components/Authentication/AuthContext";
import EditEventModal from "./EditEventModal";
import DeleteEventModal from "./DeleteEventModal";
import ManualCheckinModal from "./ManualCheckinModal";
import ViewRsvpModal from "./ViewRsvpModal";
import ViewAttendanceModal from "./ViewAttendanceModal";

// Pop up menu for adding point for members

export default function AdminEventsMenuOptions({
  chosenEvent,
  activityTypes,
  fetchEvents,
  setEventToCheckin,
  hasSignup,
}) {
  const [openCheckinModal, setOpenCheckinModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openViewRsvpModal, setOpenRsvpModal] = useState(false);
  const [openViewAttendanceModal, setOpenViewAttendanceModal] = useState(false);
  const { isTechGod } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  }

  function handleCheckinModal() {
    setAnchorEl(null);
    setOpenCheckinModal(!openCheckinModal);
    setEventToCheckin(chosenEvent);
  }

  function handleEditModal() {
    setAnchorEl(null);
    setOpenEditModal(!openEditModal);
  }

  function handleDeleteModal() {
    setAnchorEl(null);
    setOpenDeleteModal(!openDeleteModal);
  }

  function handleViewRsvpModal() {
    setAnchorEl(null);
    setOpenRsvpModal(!openViewRsvpModal);
  }

  function handleViewAttendanceModal() {
    setAnchorEl(null);
    setOpenViewAttendanceModal(!openViewAttendanceModal);
  }

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={(e) => handleCheckinModal()}>
          Check-in Members
        </MenuItem>
        {hasSignup && (
          <MenuItem onClick={(e) => handleViewRsvpModal()}>View RSVP</MenuItem>
        )}
        <MenuItem onClick={(e) => handleViewAttendanceModal()}>
          View Attendance
        </MenuItem>
        <MenuItem onClick={(e) => handleEditModal()}>Edit Event</MenuItem>
        {/* Only Tech can delete an event to prevent data loss */}
        {isTechGod() && (
          <MenuItem onClick={(e) => handleDeleteModal()}>Delete Event</MenuItem>
        )}
      </Menu>
      <ManualCheckinModal
        openModal={openCheckinModal}
        handleModal={handleCheckinModal}
        eventToCheckin={chosenEvent}
        fetchEvents={fetchEvents}
        
      />
      <ViewRsvpModal
        openModal={openViewRsvpModal}
        handleModal={handleViewRsvpModal}
        eventToView={chosenEvent}
        fetchEvents={fetchEvents}
      />
      <ViewAttendanceModal
        openModal={openViewAttendanceModal}
        handleModal={handleViewAttendanceModal}
        eventToView={chosenEvent}
      />
      <EditEventModal
        openModal={openEditModal}
        handleModal={handleEditModal}
        eventToEdit={chosenEvent}
        activityTypes={activityTypes}
        fetchEvents={fetchEvents}
      />
      <DeleteEventModal
        openModal={openDeleteModal}
        handleModal={handleDeleteModal}
        event={chosenEvent}
        fetchEvents={fetchEvents}
      />
    </div>
  );
}
