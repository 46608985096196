import React, { useEffect, useState } from "react";
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardMedia,
  Button,
  Stack,
  TablePagination,
} from "@mui/material/";
import { useAuth } from "../../Components/Authentication/AuthContext";

import "../../Styles/styles-memberboard.css";
import { useMessage } from "../../Components/Utils/MessageContext";
import AdminOpportunitiesMenuOption from "./AdminOpportunitiesMenuOption";
import { getAdminOpportunities } from "../../Admin-APIs/admin_opportunity_functions";
import { fDate } from "../../Formatter/DateTimeFormat";
import AdminOpportunityListSearch from "./AdminOpportunityListSearch";
import AddOpportunityModal from "./AddOpportunityModal";

const TABLE_HEAD = [
  { label: "", align: "left" },
  { label: "Company", align: "left" },
  { label: "Job Title", align: "left" },
  { label: "Job Type", align: "left" },
  { label: "Recruiter", align: "left" },
  { label: "Contact", align: "left" },
  { label: "Deadline", align: "left" },
  { label: "Application Link", align: "left" },
  { label: "Flyer Link", align: "left" },
  { label: "Logo", align: "center" },
];

export default function AdminOpportunityList() {
  const [oppList, setOppList] = useState([]);
  const [filteredOppList, setFilteredOppList] = useState([]);
  const { setLoading } = useAuth();
  const { setMessage, showMessage } = useMessage();
  const [searchValue, setSearchValue] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  function handleAddModal() {
    setOpenAddModal(!openAddModal);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Get All Admin Opportunities
  async function fetchItems() {
    setLoading(true);
    const res = await getAdminOpportunities();
    if (res.data) {
      setOppList(res.data);
    } else {
      setMessage("Cannot get admin opportunities list. Code (467327)");
      showMessage(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    // Set opp list based on the search value. Default is ""
    setFilteredOppList(
      searchValue === ""
        ? oppList
        : oppList.filter(
            (item) =>
              item.opp_company
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              item.opp_job_title
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              item.opp_type.toLowerCase().includes(searchValue.toLowerCase()) ||
              item.opp_recruiter
                .toLowerCase()
                .includes(searchValue.toLowerCase())
          )
    );
  }, [searchValue, oppList]);

  return (
    <>
      <AddOpportunityModal
        openModal={openAddModal}
        handleModal={handleAddModal}
        fetchItems={fetchItems}
      />
      <TableContainer className="accountCard">
        <Stack direction="row" alignItems="center" spacing={2}>
          <AdminOpportunityListSearch setSearchValue={setSearchValue} />
          <Button
            variant="contained"
            sx={{
              maxWidth: "fit-content",
            }}
            onClick={handleAddModal}
          >
            Add Job Opportunity
          </Button>
        </Stack>
        {/* TABLE HEADER */}
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((item, index) => (
                <TableCell key={index} align={item.align}>
                  {item.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* OPPORTUNITY LIST */}
          <TableBody>
            {filteredOppList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                return (
                  <TableRow key={index} id="row">
                    <TableCell>
                      <AdminOpportunitiesMenuOption
                        chosenItem={item}
                        fetchItems={fetchItems}
                      />
                    </TableCell>
                    <TableCell>{item.opp_company}</TableCell>
                    <TableCell>{item.opp_job_title}</TableCell>
                    <TableCell>{item.opp_type}</TableCell>
                    <TableCell>
                      {item.opp_recruiter.length > 0
                        ? item.opp_recruiter
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {item.opp_contact.length > 0 ? item.opp_contact : "N/A"}
                    </TableCell>
                    <TableCell>{fDate(item.opp_deadline)}</TableCell>
                    <TableCell>
                      {item.opp_link.trim() !== "" ? (
                        <Link href={`${item.opp_link}`} target="_blank">
                          Application
                        </Link>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                    <TableCell>
                      {item.opp_flyer_link.trim() !== "" ? (
                        <Link href={`${item.opp_flyer_link}`} target="_blank">
                          Flyer
                        </Link>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                    <TableCell>
                      <CardMedia
                        component="img"
                        height="50"
                        image={`${item.opp_logo}`}
                        sx={{ borderRadius: "12px", objectFit: "cover" }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={filteredOppList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
