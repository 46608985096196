import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TablePagination,
  TableSortLabel,
} from "@mui/material/";
import "../../Styles/styles-memberboard.css";
import { useMessage } from "../../Components/Utils/MessageContext";
import ManualCheckinMemberListSearch from "./ManualCheckinMemberListSearch";
import { useAuth } from "../../Components/Authentication/AuthContext";
import { getAllMembersForCheckin } from "../../Admin-APIs/admin_members_functions";
import {
  deleteMemberFromEventAttendance,
  insertMemberToEventAttendance,
  insertToActivityLog,
} from "../../APIs/member_functions";

const TABLE_HEAD = [
  { id: "first", label: "First", align: "left" },
  { id: "last", label: "Last", align: "left" },
  { id: "psid", label: "PSID", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "status", label: "Check-in Status", align: "left" },
];

export default function ManualCheckinMemberList({ eventToCheckin, fetchEvents }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [memberList, setMemberList] = useState([]);
  const [filterMemberList, setFilteredMemberList] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();
  const [sortIconDirection, setSortIconDirection] = useState("asc");
  const [searchValue, setSearchValue] = useState("");
  const { setLoading } = useAuth();
  const { setMessage, showMessage } = useMessage();

  const { event_id, activity_code, title } = eventToCheckin;
  const { profile } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortData = (column) => {
    if (order === "asc") {
      const sortedData = [...memberList].sort((a, b) =>
        a[column] > b[column] ? 1 : -1
      );
      setMemberList(sortedData);
      setOrderBy(column);
      setSortIconDirection("asc");
      setOrder("desc");
    }
    if (order === "desc") {
      const sortedData = [...memberList].sort((a, b) =>
        a[column] < b[column] ? 1 : -1
      );
      setMemberList(sortedData);
      setOrderBy(column);
      setSortIconDirection("desc");
      setOrder("asc");
    }
  };
  /* Sort descending function
    This function loops through each pair of elements in the array
    Pass the pair of elements to the function that returns 1 or -1
    If 1, switch position of 2 element
  */
  const sortList = (arr) =>
    arr.sort(function (a, b) {
      var keyA = a.total_points,
        keyB = b.total_points;
      // Compare 2 'points' fields
      if (keyA < keyB) return 1; // if a < b -> switch
      if (keyA > keyB) return -1;
      return 0;
    });

  // Get member list for check in
  async function fetchMembers() {
    setLoading(true);
    const res = await getAllMembersForCheckin(eventToCheckin.event_id);
    if (res.data) {
      setMemberList(res.data);
      console.log(res.data);
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchMembers();
  }, []);

  useEffect(() => {
    // Set member list based on the search value. Default is ""
    setFilteredMemberList(
      searchValue === ""
        ? memberList
        : memberList.filter(
            (member) =>
              member.first_name
                .toLowerCase()
                .startsWith(searchValue.toLowerCase()) ||
              member.last_name
                .toLowerCase()
                .startsWith(searchValue.toLowerCase()) ||
              member.psid.toString().startsWith(searchValue)
          )
    );
  }, [searchValue, memberList]);

  async function checkInMember(member) {
    try {
      // insert event attendance
      const insertEventAttendance = await insertMemberToEventAttendance(
        member.psid,
        event_id
      );
      // insert activity log
      if (insertEventAttendance.data) {
        const insertLog = await insertToActivityLog(
          member.psid,
          event_id,
          activity_code,
          `Check-in ${title}`,
          profile.email
        );
        if (insertLog.data)
          setMessage(
            `Check in ${profile.first_name} ${profile.last_name} successfully to ${title}`,
            "success"
          );
        else {
          // If failed to update log, cancel insert event attendance
          deleteMemberFromEventAttendance(member.psid, event_id);
          setMessage(
            "Manual Check-in was unsuccessful. Failed to update activity log. (Code: 2340928)",
            "error"
          );
        }
      } else
        setMessage(
          "Manual Check-in was unsuccessful. Failed to update activity log. (Code: 873459387)",
          "error"
        );
    } catch {
      setMessage(
        "Failed to check-in this member to event. (Code: 348707)",
        "error"
      );
    }
    showMessage(true);
    fetchMembers();
    fetchEvents();
    setLoading(false);
  }

  async function uncheckinMember(member) {
    try {
      const deleteEventAttendance = await deleteMemberFromEventAttendance(
        member.psid,
        event_id
      );
      if (deleteEventAttendance.data) {
        setMessage("Successfully remove check-in status.", "success");
      }
    } catch (e) {
      setMessage(
        "Failed to check-in this member to event. (Code: 348709)",
        "error"
      );
    }
    showMessage(true);
    fetchMembers();
    fetchEvents();
    setLoading(false);
  }

  return (
    <>
      <TableContainer className="accountCard">
        {/* SEARCH */}
        <ManualCheckinMemberListSearch setSearchValue={setSearchValue} />
        {/* TABLE HEADER */}
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((item) => (
                <TableCell
                  key={item.id}
                  align={item.align}
                  onClick={() => sortData(item.id)}
                >
                  <TableSortLabel
                    active={orderBy === item.id}
                    direction={sortIconDirection}
                  >
                    {item.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* MEMBER LIST */}
          <TableBody>
            {filterMemberList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((member) => {
                return (
                  <TableRow key={member.member_id} id="row">
                    <TableCell>{member.first_name}</TableCell>
                    <TableCell>{member.last_name}</TableCell>
                    <TableCell>{member.psid}</TableCell>
                    <TableCell>{member.email}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={member.event_id !== null}
                        onChange={
                          member.event_id === null
                            ? () => checkInMember(member)
                            : () => uncheckinMember(member)
                        }
                      ></Checkbox>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={memberList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
