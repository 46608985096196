import { Alert } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import AdminMemberList from "../../Components-Admin/Admin-Members/AdminMemberList";
import AdminCommitteeMemberList from "../../Components-Admin/Admin-Members/AdminCommitteeMemberList";
import MembersPointHistory from "../../Components-Admin/Admin-Members/AdminActivityLog";
import Layout from "../../Components/Utils/Layout";
import { useMessage } from "../../Components/Utils/MessageContext";
import TitleBar from "../../Components/Utils/TitleBar";

export default function AdminMembers() {
  const { displayMessage, messageType, statusMessage } = useMessage();
  return (
    <div>
      <Layout>
        {displayMessage && (
          <Box className="pageMessage">
            <Alert severity={messageType}>
              {messageType.toUpperCase()}: {statusMessage}
            </Alert>
          </Box>
        )}
        <TitleBar
          title="Admin Member List"
          description="Features: View member information, Add points"
        />
        <AdminMemberList />
        <TitleBar
          title="Committee Members"
          description="Features: Search, Remove committee member"
        />
        <AdminCommitteeMemberList />
        <TitleBar title="Activity Log" description="Features: View point history of members" />
        <MembersPointHistory />
      </Layout>
    </div>
  );
}
