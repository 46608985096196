import React from "react";

import { Button, CardMedia, Stack, Typography } from "@mui/material";
import TerminalOutlinedIcon from "@mui/icons-material/TerminalOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import "../../Styles/styles-education.css";
import { Box } from "@mui/system";

export default function CodingTimeItem(props) {
  const codingTime = props.codingTime;

  function handleEmail() {
    window.open(`mailto:${codingTime.officer_email}`);
  }

  function handleJoinMeeting() {
    window.open(`${codingTime.meeting_link}`);
  }

  function handleJoinGroupme() {
    window.open(`${codingTime.groupme_link}`);
  }

  function handleResources() {
    window.open(`${codingTime.drive_link}`);
  }

  return (
    <div className="eduCard">
      <Stack justifyContent="center">
        {/* Flyer */}
        <div className="eduLogo">
          <CardMedia
            component="img"
            sx={{ width: 100, height: 100 }}
            image={`${codingTime.logo_link}`}
            id="photo"
          />
        </div>
        {/* Content */}
        <div className="eduInfo">
          {/* Title */}
          <Typography variant="h6">{codingTime.name}</Typography>

          <Box p={1}>
            <Button className="eduButton" id="name" onClick={handleEmail}>
              <EmailOutlinedIcon sx={{ padding: "2px" }} />
              {codingTime.officer_name}
            </Button>
          </Box>

          {/* Date Time */}
          <div id="dateTime">
            <Stack direction="row" spacing="5px" p={"5px"}>
              <TerminalOutlinedIcon />
              <Typography>{`${codingTime.weekday1} | ${codingTime.time1}`}</Typography>
            </Stack>
            <Stack direction="row" spacing="5px" p={"5px"}>
              <TerminalOutlinedIcon />
              <Typography>{`${codingTime.weekday2} | ${codingTime.time2}`}</Typography>
            </Stack>
          </div>

          {/* Action Button */}
          <Stack spacing={1} direction="row" justifyContent="center" pt={2}>
            <Button className="eduButton" id="join" onClick={handleJoinMeeting}>
              Meeting
            </Button>
            <Button
              className="eduButton"
              id="groupme"
              onClick={handleJoinGroupme}
            >
              GroupMe
            </Button>
            <Button
              className="eduButton"
              id="resources"
              onClick={handleResources}
            >
              Resources
            </Button>
          </Stack>
        </div>
      </Stack>
    </div>
  );
}
