import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function TitleBar(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <div className="titleBar" position="static">
        <Typography variant="h3">{props.title}</Typography>
      </div>
      <div className="titleBarDescription">
      {props.description && (
        <Typography variant="body1" paddingLeft={2} paddingBottom={2}>
          {props.description}
        </Typography>
      )}
      </div>
    </Box>
  );
}
