import React from "react";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";

import { useMessage } from "../Utils/MessageContext";

import "../../Styles/styles-modal.css";
import {
  deleteFromEventSignUp,
} from "../../APIs/member_functions";
import { useAuth } from "../Authentication/AuthContext";

export default function CancelSignUpModal({
  openModal,
  handleModal,
  event,
  setCancelSignUp,
}) {
  const { setLoading, profile } = useAuth();

  const { showMessage, setMessage } = useMessage();

  async function handleCancelSignUp() {
    setLoading(true);
    try {
      const deleteEventSignUp = await deleteFromEventSignUp(
        profile.psid,
        event.event_id
      );
      if (deleteEventSignUp.data) {
        setMessage(
          `Your RSVP response for ${event.title} was cancelled.`,
          "info"
        );
        setCancelSignUp(false); // Stop showing Cancel Signup Button
      } else {
        setMessage("Cannot cancel sign up for this event.", "error");
      }
    } catch {
      setMessage("Failed to cancel sign up to event. (Code: 4218)", "error");
    }
    setLoading(false);
    showMessage(true);
    handleModal();
  }

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalConfirm">
          <Stack spacing={3}>
            <Typography variant="h6">
              Do you want to cancel sign up for {event.title}?
            </Typography>
            <Stack direction="row" spacing={2} alignSelf="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  handleCancelSignUp();
                }}
              >
                Confirm
              </Button>
              <Button variant="text" onClick={() => handleModal()}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
