import React from "react";
import "../../Styles/styles.css";

import Layout from "../../Components/Utils/Layout";
import EventList from "../../Components/Events/EventList";
import TitleBar from "../../Components/Utils/TitleBar";
import { useMessage } from "../../Components/Utils/MessageContext";
import { Alert, Box } from "@mui/material";

import "../../Styles/styles.css"

export default function Events() {
  const { displayMessage, messageType, statusMessage } = useMessage()
  return (
    <Layout>
      {displayMessage && (
        <Box className="pageMessage">
        <Alert severity={messageType} >
          {messageType.toUpperCase()}: {statusMessage}
        </Alert>
        </Box>
      )}
      <TitleBar title={"Events"} description={"Members who do not follow the professional dress code will not be allowed to attend the event."}/>
      <EventList />
    </Layout>
  );
}
