import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  Stack,
  Typography,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import "../../../Styles/styles-modal.css";
import { fDateTime } from "../../../Formatter/DateTimeFormat";
import { getPointsByMember } from "../../../APIs/member_functions";
import { openLinkedIn } from "../../../Components/Utils/LinkedIn";

// const LABELS = [
//   { label: "Full Name", align: "left" },
//   { label: "PSID", align: "left" },
//   { label: "Email", align: "left" },
//   { label: "Cougarnet", align: "left" },
//   { label: "Classification", align: "left" },
//   { label: "Graduation", align: "left" },
//   { label: "Status", align: "left" },
//   { label: "Ethnicity", align: "left" },
//   { label: "Age", align: "left" },
//   { label: "Membership Type", align: "left" },
//   { label: "Shirt Size", align: "left" },
//   { label: "Social Media", align: "left" },
//   { label: "GroupMe", align: "left" },
//   { label: "Total Points", align: "left" },
//   { label: "Event Points", align: "left" },
//   { label: "Committee Points", align: "left" },
//   { label: "Month 1 Points", align: "left" },
//   { label: "Month 2 Points", align: "left" },
//   { label: "Month 3 Points", align: "left" },
//   { label: "Picked Up Shirt?", align: "left" },
//   { label: "In GroupMe?", align: "left" },
// ];

export default function ViewMemberModal({ openModal, handleModal, profile }) {
  const [points, setPoints] = useState(0);

  useEffect(() => {
    async function fetchPoints() {
      const res = await getPointsByMember(profile.psid);
      if (res.data.length > 0) {
        setPoints(res.data[0]);
      }
    }
    fetchPoints();
  }, []);

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalAdminForm" overflow="auto">
          <Typography variant="h6">Member Information</Typography>{" "}
          <TableContainer p={3}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography id="label">Full Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">
                      {profile.first_name} {profile.last_name}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">PSID</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">{profile.psid}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Email</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">{profile.email}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Cougarnet ID</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">{profile.cougarnet}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Gender</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">{profile.gender}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Primary Major</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">{profile.major}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Second Major</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">{profile.second_major}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Minor</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">{profile.minor}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Classification</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">{profile.classification}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Graduation</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">
                      {profile.grad_semester} {profile.grad_year}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Ethnicity</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">{profile.ethnicity}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Age</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">{profile.age}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Member Status</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">{profile.status}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Membership Type</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">
                      {profile.membership_type}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">T-Shirt Size</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">{profile.shirt_size}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">GroupMe Username</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">{profile.groupme}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Social Media</Typography>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <LinkedInIcon
                        id={
                          profile.linkedIn
                            ? "linkedInButton"
                            : "socialIconDisable"
                        }
                        onClick={() => {
                          if (profile.linkedIn)
                            openLinkedIn(`${profile.linkedIn}`);
                        }}
                      />
                      <InstagramIcon
                        id={
                          profile.instagram
                            ? "instagramButton"
                            : "socialIconDisable"
                        }
                        onClick={() => {
                          if (profile.instagram)
                            window.open(
                              `https://www.instagram.com/${profile.instagram}`
                            );
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Account Created On</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">
                      {fDateTime(profile.created_time)}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Account Updated On</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="value">
                      {fDateTime(profile.updated_time)}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Total Points</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="points">{points.total_points}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Event Points</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="points">{points.e_points}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Committee Points</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="points">{points.c_points}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Month 1 Points</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="m1">{points.m1_points}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Month 2 Points</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="m2">{points.m2_points}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="label">
                    <Typography id="label">Month 3 Points</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="m3">{points.m3_points}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" justifyContent="center" pt={2}>
            <Button
              variant="outlined"
              sx={{ width: "fit-content" }}
              onClick={handleModal}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
