import React from "react";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";

import "../../Styles/styles-modal.css";
import ManualCheckinMemberList from "./ManualCheckinMemberList";

export default function ManualCheckinModal({
  openModal,
  handleModal,
  eventToCheckin,
  fetchEvents
}) {

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalManualCheckin">
          <Typography variant="h6">{`Check-in to ${eventToCheckin.title}`}</Typography>
          <ManualCheckinMemberList eventToCheckin={eventToCheckin} fetchEvents={fetchEvents}/>
          <Stack direction="row" justifyContent="center" pt={1}>
            <Button variant="text" onClick={handleModal}>
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
