import React from "react";
import "../../Styles/styles.css";

import Layout from "../../Components/Utils/Layout";
import TitleBar from "../../Components/Utils/TitleBar";
import VideoPlaylist from "../../Components/Education/VideoPlaylist";
import CodingTimes from "../../Components/Education/CodingTimes";



export default function Events() {
  return (
    <Layout>
      <TitleBar title={"Education"}/>
      <CodingTimes />
      <TitleBar title={"Videos"}/>
      <VideoPlaylist />
    </Layout>
  );
}
