import React from "react";
import "../../Styles/styles.css";

import Layout from "../../Components/Utils/Layout";
import TitleBar from "../../Components/Utils/TitleBar";
import CommitteeList from "../../Components/Get-Involved/CommitteeList";
import SocialMediaList from "../../Components/Get-Involved/SocialMediaList";

import "../../Styles/styles.css";
import { useMessage } from "../../Components/Utils/MessageContext";
import { Alert, Box } from "@mui/material";

export default function Events() {
  const { displayMessage, messageType, statusMessage } = useMessage();
  return (
    <Layout>
      {displayMessage && (
        <Box className="pageMessage">
          <Alert severity={messageType}>
            {messageType.toUpperCase()}: {statusMessage}
          </Alert>
        </Box>
      )}
      <TitleBar title={"Committees"} />
      <CommitteeList />
      <TitleBar title={"Social Media"} />
      <SocialMediaList />
    </Layout>
  );
}
