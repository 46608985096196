import React, { useEffect, useState } from "react";
import { Box, Grid, TablePagination } from "@mui/material";
import VideoItem from "./VideoItem";
import VideoFilterBar from "./VideoFilterBar";
import {
  getPlaylists,
  getVideoInPlaylist,
} from "../../APIs/education_functions";
import { useAuth } from "../Authentication/AuthContext";

export default function VideoPlaylist() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [videoList, setVideoList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filterBy, setFilterBy] = useState("all");
  const [playlists, setPlaylist] = useState([]);
  const { setLoading } = useAuth();
  let playlists_ = [];
  let videos_ = [];

  async function fetchVideos(playlistId) {
    const res = getVideoInPlaylist(playlistId);
    return res.data;
  }

  // Set initial playlists and videos
  useEffect(() => {
    async function fetchPlaylists() {
      setLoading(true);
      const res = await getPlaylists();
      // Get playlist information to get playlistId
      if (res.data) {
        playlists_ = res.data;
        setPlaylist(res.data);
      }
      // Iterarate through playlists array and use each playlistId to fetch video in each playlist
      for (const element of playlists_) {
        const res = await getVideoInPlaylist(element.id);
        // Append values in new videos array with the current ones
        videos_ = [...videos_, ...res.data];
      }
      // console.log(videos_);
      setVideoList(videos_);
      setLoading(false);
    }
    fetchPlaylists();
    // console.log(playlists);
  }, [playlists.length]);

  useEffect(() => {
    setFilteredList(
      filterBy === "all"
        ? videoList
        : videoList.filter(
            (video) =>
              video.snippet.playlistId.toLowerCase() === filterBy.toLowerCase()
          )
    );
    // console.log(filteredList);
  }, [videoList.length, filterBy]);

  useEffect(() => {
    setFilteredList(
      searchValue === ""
        ? videoList
        : videoList.filter((video) =>
            video.snippet.title
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          )
    );
    // console.log(filteredList);
  }, [videoList.length, searchValue]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <>
      {playlists && (
        <div>
          <VideoFilterBar
            setSearchValue={setSearchValue}
            setFilterBy={setFilterBy}
            filterBy={filterBy}
            playlists={playlists}
            filteredList={filteredList}
            setVideoList={setVideoList}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchValue={searchValue}
          />

          <Grid container justifyContent="center">
            {filteredList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((video, index) => {
                return (
                  <Grid key={index} item xs={12} lg={6} p={"10px"}>
                    <VideoItem video={video} />
                  </Grid>
                );
              })}
          </Grid>
          <Box
            sx={{
              justifyContent: "",
              alignItems: "flex-start",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              labelRowsPerPage="Videos"
              component="div"
              count={filteredList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </Box>
        </div>
      )}
    </>
  );
}
