import React from "react";

import { Box, InputAdornment, Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function AdminMemberListSearch(props) {
  function handleSearchChange(e) {
    props.setSearchValue(e.target.value);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          label="Search"
          value={props.searchValue}
          size="medium"
          placeholder="Search by Name or PSID"
          onChange={(e) => handleSearchChange(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Box>
  );
}
