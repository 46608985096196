import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableSortLabel,
  Checkbox,
  Button,
  Stack,
} from "@mui/material/";
import { CSVLink } from "react-csv";

import "../../Styles/styles-memberboard.css";
import { useMessage } from "../../Components/Utils/MessageContext";
import { useAuth } from "../../Components/Authentication/AuthContext";
import {
  getAllMembersAdmin,
  getAllMixerResume,
  updateGotShirt,
  updateInGroupme,
} from "../../Admin-APIs/admin_members_functions";
import AdminMembersMenuOptions from "./AdminMembersMenuOptions";
import AdminMemberListSearch from "./AdminMemberListSearch";
import { getCommitteeDutyTypes } from "../../Admin-APIs/admin_getinvoled_functions";
import { getAllCommitteeInfo } from "../../APIs/getinvolved_functions";

const TABLE_HEAD = [
  { id: "menu", label: "", align: "left" },
  { id: "first_name", label: "Full Name", align: "left" },
  // { id: "psid", label: "ID", align: "left" },
  { id: "total_points", label: "Total Points", align: "left" },
  { id: "m1", label: "Month 1", align: "left" },
  { id: "m2", label: "Month 2", align: "left" },
  { id: "m3", label: "Month 3", align: "left" },
  { id: "rank", label: "Rank", align: "left" },
  { id: "in_groupme", label: "Joined Groupme?", align: "left" },
  { id: "got_shirt", label: "Picked up Shirt?", align: "left" },
];

export default function AdminMemberList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [memberList, setMemberList] = useState([]);
  const [filterMemberList, setFilteredMemberList] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();
  const [sortIconDirection, setSortIconDirection] = useState("asc");
  const [searchValue, setSearchValue] = useState("");
  const { setLoading, profile } = useAuth();
  const { setMessage, showMessage } = useMessage();
  // dutyType and committee are passed down to AddPointsModal
  const [dutyTypes, setDutyTypes] = useState([]);
  const [committees, setCommittees] = useState([]);
  // const [showPsid, setShowPsid] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const res1 = await getCommitteeDutyTypes();
      const res2 = await getAllCommitteeInfo();
      setDutyTypes(res1.data);
      setCommittees(res2.data);
    }
    fetchData();
  }, []);

  // const handleShowPsid = () => {
  //   setShowPsid(!showPsid);
  // };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleGroupme = async (psid) => {
    const res = await updateInGroupme(psid);
    if (res.data && res.data.affectedRows > 0) {
      // console.log(res.data);
      setMessage("Groupme status updated", "success");
      setMemberList(memberList);
    } else {
      setMessage("Server Error! Fail to update Groupme", "error");
    }
    showMessage(true);
    fetchMembers();
  };

  const handleGotShirt = async (psid) => {
    const res = await updateGotShirt(psid);
    if (res.data && res.data.affectedRows > 0) {
      // console.log(res.data);
      setMessage("Shirt pickup status updated", "success");
      setMemberList(memberList);
    } else {
      setMessage("Server Error! Fail to update Shirt status", "error");
    }
    showMessage(true);
    fetchMembers();
  };

  const sortData = (column) => {
    if (order === "asc") {
      const sortedData = [...memberList].sort((a, b) =>
        a[column] > b[column] ? 1 : -1
      );
      setMemberList(sortedData);
      setOrderBy(column);
      setSortIconDirection("asc");
      setOrder("desc");
    }
    if (order === "desc") {
      const sortedData = [...memberList].sort((a, b) =>
        a[column] < b[column] ? 1 : -1
      );
      setMemberList(sortedData);
      setOrderBy(column);
      setSortIconDirection("desc");
      setOrder("asc");
    }
  };
  /* Sort descending function
    This function loops through each pair of elements in the array
    Pass the pair of elements to the function that returns 1 or -1
    If 1, switch position of 2 element
  */
  // ********* Something wrong with this function (8/20/2022)
  // const sortList = (arr) =>
  //   arr.sort(function (a, b) {
  //     var keyA = a.total_points,
  //       keyB = b.total_points;
  //     // Compare 2 'points' fields
  //     if (keyA < keyB) return 1; // if a < b -> switch
  //     if (keyA > keyB) return -1;
  //     return 0;
  //   });

  // Get member board API

  // Ranking function
  async function fetchMembers() {
    setLoading(true);
    let members_;
    const res = await getAllMembersAdmin();
    if (res.data) {
      // console.log(res.data);
      // Sort res.data from highest points to lowest
      members_ = res.data;
      let cur_rank = 1;
      let cur_points = members_[0].total_points; // members_[0] has the most points
      members_ = members_.map((member) => {
        // If the first one has 0 point, meaning no one has gotten any points yet so no need to compare
        if (member.total_points > 0) {
          if (member.total_points === cur_points) {
            // equal
            member.rank = cur_rank;
          } else {
            /* if second member points lower than first one,
            increment current rank and assign that to the second
            */
            cur_rank += 1;
            member.rank = cur_rank;
            // Set new current point to continue evaluation
            cur_points = member.total_points;
          }
        }
        return member;
      });
      setMemberList(members_);
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchMembers();
  }, []);

  useEffect(() => {
    // Set member list based on the search value. Default is ""
    setFilteredMemberList(
      searchValue === ""
        ? memberList
        : memberList.filter(
            (member) =>
              member.first_name
                .toLowerCase()
                .startsWith(searchValue.toLowerCase()) ||
              member.last_name
                .toLowerCase()
                .startsWith(searchValue.toLowerCase()) ||
              member.psid.toString().startsWith(searchValue)
          )
    );
  }, [searchValue, memberList]);

  // Download all resumes
  async function handleDownloadMixerResumes() {
    const res = await getAllMixerResume();
    // Create a hidden object to store file name and URL
    var a = document.createElement("a");
    a.href = URL.createObjectURL(res); // Create a URL for the blob file
    a.download = "Career_Mixer_Resumes.zip"; // File name
    a.click(); // Invoke click event to trigger download
  }

  return (
    <>
      <TableContainer className="accountCard">
        <Stack direction="row" alignItems="center" spacing={2}>
          {/* SEARCH */}
          <AdminMemberListSearch setSearchValue={setSearchValue} />
          {/* EXPORT MEMBERS */}
          <Button
            variant="contained"
            sx={{
              maxWidth: "fit-content",
              marginBottom: "20px",
            }}
          >
            <CSVLink
              id="fileExport"
              data={memberList}
              filename="MISSO-member-list"
              target="_blank"
            >
              Export Members
            </CSVLink>
          </Button>
          {/* BUTTON TO DOWNLOAD ALL RESUME */}
          {("tech@misso.org" === profile.email ||
            "corporate@misso.org" === profile.email) && (
            <Button
              variant="contained"
              sx={{
                maxWidth: "fit-content",
                marginBottom: "20px",
              }}
              onClick={handleDownloadMixerResumes}
            >
              Export Mixer Resumes
            </Button>
          )}
        </Stack>
        {/* TABLE HEADER */}
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((item) => (
                <TableCell
                  key={item.id}
                  align={item.align}
                  onClick={() => sortData(item.id)}
                >
                  <TableSortLabel
                    active={orderBy === item.id}
                    direction={sortIconDirection}
                  >
                    {item.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* MEMBER LIST */}
          <TableBody>
            {filterMemberList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((member) => {
                const { in_groupme, got_shirt } = member;
                return (
                  <TableRow key={member.member_id} id="row">
                    <TableCell>
                      <AdminMembersMenuOptions
                        chosenItem={member}
                        dutyTypes={dutyTypes}
                        committees={committees}
                      />
                    </TableCell>
                    <TableCell>
                      {member.first_name} {member.last_name}
                    </TableCell>
                    {/* <TableCell onClick={handleShowPsid}>
                      <Button size="small">{showPsid ? member.psid : "View"}</Button>
                    </TableCell> */}
                    <TableCell>
                      <Typography id="points">{member.total_points}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography id="m1">{member.m1_points}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography id="m2">{member.m2_points}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography id="m3">{member.m3_points}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography id="rank">{member.rank}</Typography>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={in_groupme === 1}
                        onChange={() => handleGroupme(member.psid)}
                      >
                        {member.in_groupme}
                      </Checkbox>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={got_shirt === 1}
                        onChange={() => handleGotShirt(member.psid)}
                      >
                        {member.got_shirt}
                      </Checkbox>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={filterMemberList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
      </TableContainer>
    </>
  );
}
