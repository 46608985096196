import React, { useEffect, useState } from "react";
import {
  Typography,
  Link,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material/";
import "../../Styles/styles-memberboard.css";

import { useAuth } from "../../Components/Authentication/AuthContext";
import { useMessage } from "../../Components/Utils/MessageContext";
import { getOfficerByAlias } from "../../Admin-APIs/admin_officers_functions";
import { fDateTime } from "../../Formatter/DateTimeFormat";
import EditOfficerProfile from "./EditOfficerProfileModal";

const TABLE_HEAD = [
  { label: "Name", align: "left" },
  { label: "Officer Name", align: "left" },
  { label: "Officer Email", align: "left" },
  { label: "Weekday 1", align: "left" },
  { label: "Time 1", align: "left" },
  { label: "Weekday 2", align: "left" },
  { label: "Time 2", align: "left" },
  { label: "Zoom", align: "left" },
  { label: "GroupMe Link", align: "left" },
  { label: "Drive Link", align: "left" },
  { label: "Logo", align: "center" },
  { label: "", align: "center" },
];

export default function AdminMyAccount() {
  const [adminProfile, setAdminProfile] = useState();
  const { setLoading, profile } = useAuth();
  const { setMessage, showMessage } = useMessage();
  const [openModal, setOpenModal] = useState(false);

  function handleModal() {
    setOpenModal(!openModal);
  }

  async function fetchProfile() {
    setLoading(true);
    const res = await getOfficerByAlias(profile.email);
    if (res.data) {
      setAdminProfile(res.data[0]);
      // console.log(res.data);
    } else {
      setMessage("Cannot get admin profile. Code (6968)");
      showMessage(true);
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <div className="accountCard">
      {adminProfile && (
        <TableContainer p={3}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography id="label">Full Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography id="value">
                    {adminProfile.first_name} {adminProfile.last_name}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell id="label">
                  <Typography id="label">Position</Typography>
                </TableCell>
                <TableCell>
                  <Typography id="value">{adminProfile.position}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell id="label">
                  <Typography id="label">Team</Typography>
                </TableCell>
                <TableCell>
                  <Typography id="value">{adminProfile.team}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell id="label">
                  <Typography id="label">Officer Email</Typography>
                </TableCell>
                <TableCell>
                  <Typography id="value">{adminProfile.email}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell id="label">
                  <Typography id="label">Officer Alias</Typography>
                </TableCell>
                <TableCell>
                  <Typography id="value">{adminProfile.alias}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell id="label">
                  <Typography id="label">Personal Email</Typography>
                </TableCell>
                <TableCell>
                  <Typography id="value">
                    {adminProfile.personal_email}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell id="label">
                  <Typography id="label">Phone</Typography>
                </TableCell>
                <TableCell>
                  <Typography id="value">{`(${adminProfile.phone
                    .toString()
                    .slice(0, 3)}) ${adminProfile.phone
                    .toString()
                    .slice(3, 6)}-${adminProfile.phone
                    .toString()
                    .slice(6, 10)}`}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell id="label">
                  <Typography id="label">PSID</Typography>
                </TableCell>
                <TableCell>
                  <Typography id="value">{adminProfile.psid}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell id="label">
                  <Typography id="label">Office Access</Typography>
                </TableCell>
                <TableCell>
                  <Typography id="value">
                    {adminProfile.office_access === 1 ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell id="label">
                  <Typography id="label">Shirt Size</Typography>
                </TableCell>
                <TableCell>
                  <Typography id="value">{adminProfile.shirt_size}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell id="label">
                  <Typography id="label">LinkedIn</Typography>
                </TableCell>
                <TableCell>
                  {adminProfile.linkedIn ? (
                    <Link href={`${adminProfile.linkedIn}`} target="_blank">
                      <Typography>LinkedIn</Typography>
                    </Link>
                  ) : (
                    <Typography>None</Typography>
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell id="label">
                  <Typography id="label">Instagram Username</Typography>
                </TableCell>
                <TableCell>
                  <Typography id="value">
                    {adminProfile.instagram || "None"}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell id="label">
                  <Typography id="label">Account Updated On</Typography>
                </TableCell>
                <TableCell>
                  <Typography id="value">
                    {fDateTime(adminProfile.updated_time)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Button
        variant="contained"
        sx={{
          maxWidth: "fit-content",
          margin: "20px"
        }}
        onClick={handleModal}
      >
        Edit Information
      </Button>

      {adminProfile && (
        <EditOfficerProfile
          openModal={openModal}
          handleModal={handleModal}
          adminProfile={adminProfile}
          fetchProfile={fetchProfile}
        />
      )}
    </div>
  );
}
