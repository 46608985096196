import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import EditOpportunityModal from "./EditOpportunityModal";
import RemoveOpportunityModal from "./RemoveOpportunityModal";

// Pop up menu for adding point for members

export default function AdminOpportunitiesMenuOption({
  chosenItem,
  fetchItems,
}) {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  function handleClose() {
    setAnchorEl(null);
  }
  function handleEditModal() {
    setAnchorEl(null);
    setOpenEditModal(!openEditModal);
  }
  function handleDeleteModal() {
    setAnchorEl(null);
    setOpenDeleteModal(!openDeleteModal);
  }

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={(e) => handleEditModal()}>Edit</MenuItem>
        <MenuItem onClick={(e) => handleDeleteModal()}>Remove</MenuItem>
      </Menu>
      <EditOpportunityModal
        openModal={openEditModal}
        handleModal={handleEditModal}
        chosenItem={chosenItem}
        fetchItems={fetchItems}
      />
      <RemoveOpportunityModal
        openModal={openDeleteModal}
        handleModal={handleDeleteModal}
        chosenItem={chosenItem}
        fetchItems={fetchItems}
      />
    </div>
  );
}
