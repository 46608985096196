import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableSortLabel,
  Button,
  Stack,
} from "@mui/material/";

import { useAuth } from "../../Components/Authentication/AuthContext";

import "../../Styles/styles-memberboard.css";
import { useMessage } from "../../Components/Utils/MessageContext";
import {
  getActivityTypes,
  getAllAdminEvents,
} from "../../Admin-APIs/admin_event_functions";
import { fDateTime } from "../../Formatter/DateTimeFormat";
import AdminEventsMenuOptions from "./AdminEventsMenuOptions";
import AddEventModal from "./AddEventModal";
import AdminEventListSearch from "./AdminEventListSearch";
import { CSVLink } from "react-csv";

const TABLE_HEAD = [
  { id: "event_id", label: "", align: "left" },
  { id: "title", label: "Title", align: "left" },
  { id: "activity_type", label: "Type", align: "left" },
  { id: "company", label: "Company", align: "left" },
  { id: "location", label: "Location", align: "left" },
  { id: "start", label: "Start", align: "left" },
  { id: "end", label: "End", align: "left" },
  { id: "points", label: "Points", align: "left" },
  { id: "code", label: "Code", align: "left" },
  { id: "rsvp", label: "RSVP", align: "left" },
  { id: "attendance", label: "Checkin", align: "left" },
];

export default function AdminEventList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [eventList, setEventList] = useState([]);
  const [filteredEventList, setFilteredEventList] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();
  const [sortIconDirection, setSortIconDirection] = useState("asc");
  const [searchValue, setSearchValue] = useState("");
  const { setLoading } = useAuth();
  const { setMessage, showMessage } = useMessage();
  const [openAddEventModal, setOpenAddEventModal] = useState(false);
  const [activityTypes, setActivityTypes] = useState([]);
  const [eventToCheckin, setEventToCheckin] = useState();

  function handleAddEvent() {
    setOpenAddEventModal(!openAddEventModal);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortData = (column) => {
    if (order === "asc") {
      const sortedData = [...eventList].sort((a, b) =>
        a[column] > b[column] ? 1 : -1
      );
      setEventList(sortedData);
      setOrderBy(column);
      setSortIconDirection("asc");
      setOrder("desc");
    }
    if (order === "desc") {
      const sortedData = [...eventList].sort((a, b) =>
        a[column] < b[column] ? 1 : -1
      );
      setEventList(sortedData);
      setOrderBy(column);
      setSortIconDirection("desc");
      setOrder("asc");
    }
  };
  /* Sort descending function
    This function loops through each pair of elements in the array
    Pass the pair of elements to the function that returns 1 or -1
    If 1, switch position of 2 element
  */

  // Get All Admin Events
  async function fetchEvents() {
    setLoading(true);
    try {
      const res = await getAllAdminEvents();
      if (res.data) {
        setEventList(res.data);
      } else {
        setMessage("No event found. (Code: 32135)", "warning");
        showMessage(true);
      }
    } catch (e) {
      setMessage("Cannot get admin event. (Code: 65735)", "error");
      showMessage(true);
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    // Set member list based on the search value. Default is ""
    setFilteredEventList(
      searchValue === ""
        ? eventList
        : eventList.filter(
            (event) =>
              event.title.toLowerCase().includes(searchValue.toLowerCase()) ||
              event.company.toLowerCase().includes(searchValue.toLowerCase()) || 
              event.activity_type.toLowerCase().includes(searchValue.toLowerCase()) 
          )
    );
  }, [searchValue, eventList]);

  useEffect(() => {
    async function fetchActivityType() {
      const res = await getActivityTypes();
      if (res.data) {
        setActivityTypes(res.data);
      }
    }
    fetchActivityType();
  }, []);

  useEffect(() => {}, [eventToCheckin]);

  return (
    <>
      <AddEventModal
        openModal={openAddEventModal}
        handleModal={handleAddEvent}
        fetchEvents={fetchEvents}
      />
      <TableContainer className="accountCard">
        <Stack direction="row" alignItems="center" spacing={2}>
          <AdminEventListSearch setSearchValue={setSearchValue} />
          <Button
            variant="contained"
            sx={{
              maxWidth: "fit-content",
            }}
            onClick={handleAddEvent}
          >
            Add Event
          </Button>
          {/* EXPORT EVENTS */}
          <Button
            variant="contained"
            sx={{
              maxWidth: "fit-content",
            }}
          >
            <CSVLink
              id="fileExport"
              data={eventList}
              filename="MISSO-event-list"
              target="_blank"
            >
              Export Events
            </CSVLink>
          </Button>
        </Stack>

        {/* TABLE HEADER */}
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((item, index) => (
                <TableCell
                  key={index}
                  align={item.align}
                  onClick={() => sortData(item.id)}
                >
                  <TableSortLabel
                    active={orderBy === item.id}
                    direction={sortIconDirection}
                  >
                    {item.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* MEMBER LIST */}
          <TableBody>
            {filteredEventList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((event, index) => {
                return (
                  <TableRow key={index} id="row">
                    <TableCell>
                      <AdminEventsMenuOptions
                        chosenEvent={event}
                        activityTypes={activityTypes}
                        fetchEvents={fetchEvents}
                        setEventToCheckin={setEventToCheckin}
                        hasSignup={event.has_signup === 1}
                      />
                    </TableCell>
                    <TableCell width="200px">{event.title}</TableCell>
                    <TableCell>{event.activity_type}</TableCell>
                    <TableCell>{event.company}</TableCell>
                    <TableCell width="200px">{event.location}</TableCell>
                    <TableCell>{fDateTime(event.start)}</TableCell>
                    <TableCell>{fDateTime(event.end)}</TableCell>
                    <TableCell>
                      <Typography id="points">{event.points}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography id="checkinCode">
                        {event.checkin_code}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {event.has_signup ? (
                        <Typography id="rsvp">{event.rsvp}</Typography>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography id="eventAttendance">
                        {event.attendance}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={filteredEventList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
