import React from "react";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";

import { useMessage } from "../Utils/MessageContext";

import "../../Styles/styles-modal.css";
import {
  insertToActivityLog,
  insertToMemberCommittee,
} from "../../APIs/member_functions";
import { useAuth } from "../Authentication/AuthContext";

export default function JoinCommitteeModal({
  openModal,
  handleModal,
  committee,
  setCancelSignUp,
  cancelSignUp,
}) {
  const { setLoading, profile } = useAuth();
  const { showMessage, setMessage } = useMessage();

  async function handleSignUp() {
    setLoading(true);
    try {
      const insertMemberCommittee = await insertToMemberCommittee(
        profile.psid,
        committee.committee_id
      );
      if (insertMemberCommittee.data) {
        // insert activity log
        const insertLog = await insertToActivityLog(
          profile.psid,
          committee.committee_id,
          "committee0",
          `Join ${committee.name} Committee`,
          "Self",
        );
        if (insertLog) {
          setMessage(
            `Congratulation! You are now part of the ${committee.name} Committee.`,
            "success"
          );
          setCancelSignUp(!cancelSignUp); // Show Cancel Sign Up button
        }
      } else {
        setMessage("You have already joined this committee.", "error");
      }
    } catch {
      setMessage("Failed to join committee. (Code: 5210)", "error");
    }
    setLoading(false);
    showMessage(true);
    handleModal();
  }

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalConfirm">
          <Stack spacing={3}>
            <Typography variant="h6">
              Do you want to join {committee.name} Committee?
            </Typography>
            <Typography>
              You will receive {committee.points} points for joining.
            </Typography>
            <Stack direction="row" spacing={2} alignSelf="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  handleSignUp();
                }}
              >
                Confirm
              </Button>
              <Button variant="text" onClick={() => handleModal()}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
