import { Alert, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CommitteeItem from "./CommitteeItem";
import { getAllCommitteeInfo } from "../../APIs/getinvolved_functions";

export default function CommitteeList() {
  const [committeeList, setCommitteeList] = useState();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const res = await getAllCommitteeInfo();
      if (res.data) {
        setCommitteeList(res.data);
      } else setShowError(true);
    }
    fetchData();
  }, []);

  return (
    <div>
      {showError && (
        <Alert severity="error">
          ERROR: Cannot get committee data. (Code: 2240)
        </Alert>
      )}
      {committeeList && (
        <Grid
          container
          spacing={2}
          justifyContent="space-evenly"
          alignItems="center"
        >
          {committeeList.map((item) => {
            return (
              <Grid key={item.committee_id} item xs={10} md={6} lg={5} xl={4}>
                <CommitteeItem committee={item} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
}
