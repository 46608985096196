import React, { useState } from "react";
import {
  Box,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function ManualCheckinMemberListSearch(props) {
  const [searchValue, setSearchValue] = useState("");

  function handleSearchChange(e) {
    setSearchValue(e.target.value);
    props.setSearchValue(e.target.value);
  }
  function handleSearch() {
    console.log(searchValue);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <TextField
            label="Search"
            defaultValue=""
            size="medium"
            placeholder="Search by Name or PSID"
            onChange={(e) => handleSearchChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </form>
      </Stack>
    </Box>
  );
}
