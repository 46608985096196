import { Alert } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import AdminEventList from "../../Components-Admin/Admin-Events/AdminEventList";
import Layout from "../../Components/Utils/Layout";
import { useMessage } from "../../Components/Utils/MessageContext";
import TitleBar from "../../Components/Utils/TitleBar";

export default function AdminEvent() {
  const { displayMessage, messageType, statusMessage } = useMessage()
  return (
    <div>
      <Layout>
        {displayMessage && (
          <Box className="pageMessage">
            <Alert severity={messageType}>
              {messageType.toUpperCase()}: {statusMessage}
            </Alert>
          </Box>
        )}
        <TitleBar title="Admin Events" description="Features: Late Check-in, Add, Search, Edit, Delete events " />
        <AdminEventList />
      </Layout>
    </div>
  );
}
