import React, { useEffect, useState } from "react";
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardMedia,
} from "@mui/material/";
import { useAuth } from "../../Components/Authentication/AuthContext";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import "../../Styles/styles-memberboard.css";
import { useMessage } from "../../Components/Utils/MessageContext";
import { getAllCommitteeInfo } from "../../APIs/getinvolved_functions";
import AdminGetInvolvedMenuOption from "./AdminCommitteeMenuOption";

const TABLE_HEAD = [
  { label: "", align: "left" },
  { label: "Committee Name", align: "left" },
  { label: "Description", align: "left" },
  { label: "Officer Name", align: "left" },
  { label: "Groupme", align: "left" },
  { label: "Open?", align: "left" },
  { label: "Logo", align: "center" },
];

export default function AdminCommitteeList() {
  const [committeeList, setCommitteeList] = useState([]);
  const { setLoading } = useAuth();
  const { setMessage, showMessage } = useMessage();

  // Get All Admin Events
  async function fetchItems() {
    setLoading(true);
    const res = await getAllCommitteeInfo();
    if (res.data) {
      setCommitteeList(res.data);
    } else {
      setMessage("Cannot get admin committee events. Code (35164)");
      showMessage(true);
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      <TableContainer className="accountCard">
        {/* TABLE HEADER */}
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((item, index) => (
                <TableCell key={index} align={item.align}>
                  {item.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* MEMBER LIST */}
          <TableBody>
            {committeeList.map((item, index) => {
              return (
                <TableRow key={index} id="row">
                  <TableCell>
                    <AdminGetInvolvedMenuOption chosenItem={item} fetchItems={fetchItems}/>
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.officer_name}</TableCell>
                  <TableCell>
                    <Link href={`${item.groupme_link}`} target="_blank">
                      GroupMe
                    </Link>
                  </TableCell>
                  <TableCell>
                    {item.is_open ? <DoneIcon /> : <CloseIcon />}
                  </TableCell>
                  <TableCell>
                    <CardMedia
                      component="img"
                      height="50"
                      image={`${item.logo_link}`}
                      sx={{ borderRadius: "12px", objectFit: "cover" }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
