import { Modal, Typography, Stack, Button } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useAuth } from "../../Components/Authentication/AuthContext";
import { useMessage } from "../../Components/Utils/MessageContext";
import { deleteResumeByPSID } from "../../APIs/member_functions";

export default function DeleteMixerResumeModal({
  openModal,
  handleModal,
  profile,
  resume,
  setResumeUploaded,
}) {
  const { showMessage, setMessage } = useMessage();
  const { setLoading } = useAuth();

  async function handleDelete() {
    setLoading(true);
    try {
      const res = await deleteResumeByPSID(profile.psid);
      if (res.data) {
        setMessage(
          `Successfully removed your Career Mixer Resume. You can upload a new one.`,
          "success"
        );
        setResumeUploaded(false);
      } else {
        setMessage(
          `Unable to delete ${resume.res_name}. (Code: 7539741)`,
          "error"
        );
      }
    } catch (e) {
      setMessage(
        `Failed to delete ${resume.res_name}.. (Code: 7539742)`,
        "error"
      );
    }
    handleModal();
    setLoading(false);
    showMessage(true);
  }

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalForm">
          <Typography variant="h6">
            Are you sure you want to delete this resume?
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="contained" onClick={handleDelete}>
              Confirm
            </Button>
            <Button variant="text" onClick={() => handleModal()}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
