import React, { useEffect, useState } from "react";
import "../../Styles/styles.css";
import "../../Styles/styles-officer-board.css";

import Layout from "../../Components/Utils/Layout";
import OfficerList from "../../Components/Officer-Board/OfficerList";
import { getOfficerList } from "../../APIs/officer_functions";
import TitleBar from "../../Components/Utils/TitleBar";
import { useAuth } from "../../Components/Authentication/AuthContext";
import { useMessage } from "../../Components/Utils/MessageContext";
import { Box } from "@mui/system";
import { Alert } from "@mui/material";

const TEAM = [
  {
    name: "Executive",
  },
  {
    name: "Education",
  },
  {
    name: "Corporate Relations",
  },
  {
    name: "Public Relations",
  },
];

export default function OfficerBoard() {
  const [officerList, setOfficerList] = useState([]);
  const [teams, setTeams] = useState(TEAM);
  const { setLoading } = useAuth();
  const { displayMessage, messageType, statusMessage } = useMessage()

  // get list of officers
  useEffect(() => {
    async function fetchOfficers() {
      setLoading(true);
      const res = await getOfficerList();
      if (res.data) {
        setOfficerList(res.data);
      }
      setLoading(false);
    }
    fetchOfficers();
  }, []);

  return (
    <>
      <Layout>
        {displayMessage && (
          <Box className="pageMessage">
            <Alert severity={messageType}>
              {messageType.toUpperCase()}: {statusMessage}
            </Alert>
          </Box>
        )}
        {teams.map(function (team, index) {
          return (
            <div key={index}>
              <TitleBar title={team.name} />
              <OfficerList team={team} officers={officerList} />
            </div>
          );
        })}
      </Layout>
    </>
  );
}
