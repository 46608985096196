import React from "react";
import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import "../../Styles/styles-modal.css";
import { useMessage } from "../../Components/Utils/MessageContext";
import { useAuth } from "../../Components/Authentication/AuthContext";
import { updateSocialMedia } from "../../Admin-APIs/admin_getinvoled_functions";

export default function EditSocialMediaModal({
  openModal,
  handleModal,
  chosenItem,
  fetchItems
}) {
  const { showMessage, setMessage } = useMessage();
  const { setLoading } = useAuth();

  async function handleEditEducation() {
    setLoading(true);
    try {
      const updateSocialSuccessfully = await updateSocialMedia(formik.values);
      if (updateSocialSuccessfully.data) {
        setMessage(
          `${chosenItem.sm_name} was updated successfully`,
          "success"
        );
      } else {
        setMessage(`Unable to update social media. (Code: 975632)`, "error");
      }
      fetchItems();
    } catch (e) {
      setMessage("Failed to update social media. (Code: 1324645)", "error");
    }
    showMessage(true);
    handleModal(!openModal);
    setLoading(false);
  }

  const validationSchema = yup.object({
    sm_link: yup
      .string()
      .max(255, "Max 45 characters.")
      .url("Must include 'https://' at the beginning.")
      .required("This field is required."),
    sm_name: yup
      .string()
      .max(255, "Max 45 characters.")
      .required("This field is required."),
  });

  const defaultValues = {
    sm_id: chosenItem.sm_id,
    sm_link: chosenItem.sm_link,
    sm_name: chosenItem.sm_name
  };

  // Initiate formik
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: handleEditEducation,
  });

  const {
    touched,
    errors, // error condition for text field
    getFieldProps, // method to help touch error
    handleSubmit,
    isSubmitting,
  } = formik;

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalAdminForm" overflow="auto">
          <Typography variant="h6">
            Update {chosenItem.name} Coding Time
          </Typography>
          <FormikProvider value={formik}>
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Stack spacing={2} padding={2}>
                <TextField
                  fullWidth
                  size="small"
                  name="sm_name"
                  label="Social Media Name"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("sm_name")}
                  error={Boolean(touched.sm_name && errors.sm_name)}
                  helperText={touched.sm_name && errors.sm_name}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="sm_link"
                  label="Social Media Link"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("sm_link")}
                  error={Boolean(touched.sm_link && errors.sm_link)}
                  helperText={touched.sm_link && errors.sm_link}
                />
              </Stack>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  className="checkInButton"
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
                <Button
                  className="checkInButton"
                  variant="text"
                  onClick={handleModal}
                >
                  Cancel
                </Button>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </div>
  );
}
