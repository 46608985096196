import React from "react";
import { ThemeProvider } from "@emotion/react";
import theme from "./Styles/theme";

import { AuthProvider } from "./Components/Authentication/AuthContext";
import { MessageProvider } from "./Components/Utils/MessageContext";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import { ProfileProvider } from "./Components/Utils/ProfileContext";

function App() {
  return (
    <AuthProvider>
      <ProfileProvider>
        <ThemeProvider theme={theme}>
          <MessageProvider>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </MessageProvider>
        </ThemeProvider>
      </ProfileProvider>
    </AuthProvider>
  );
}

export default App;
