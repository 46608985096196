import { Alert, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CodingTimeItem from "./CodingTimeItem";
import { getAllCodingTime } from "../../APIs/education_functions";

export default function CodingTimes() {
  const [codingTimeList, setCodingTimeList] = useState();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const res = await getAllCodingTime();
      if (res.data) {
        setCodingTimeList(res.data);
      } else setShowError(true);
    }
    fetchData();
  }, []);

  return (
    <>
      {showError && (
        <Alert severity="error">
          ERROR: Cannot get coding time data. (Code: 7246)
        </Alert>
      )}
      {codingTimeList && (
        <Grid container justifyContent="center">
          {codingTimeList.map((item) => {
            return (
              <Grid key={item.coding_time_id} item xl={4} p={"10px"}>
                <CodingTimeItem codingTime={item} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
}
