import React from "react";
import { SidebarData, AdminSidebarData } from "./SidebarData";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Authentication/AuthContext";

import { Button, Stack, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import InfoIcon from "@mui/icons-material/Info";
import logo from "../../Styles/MISSO-logo-white.png";

export default function SidebarMenuList() {
  // useNavigate hook to navigate through menu item
  const navigate = useNavigate();
  const { signOut, profile, isAdmin } = useAuth();
  const greetingName = profile.first_name;

  return (
    <>
      {/* MISSO Logo */}
      <div className="sidebarLogo">
        <img
          src={logo}
          width="100%"
          alt="MISSO Logo"
          onClick={() => window.open("https://misso.org")}
        />
      </div>
      {/* Greeting message */}
      <div className="greeting">
        <Typography variant="subtitle1" id="message" textAlign="center">
          Hello, {profile && greetingName}!
        </Typography>
      </div>

      <div className="menuList">
        {SidebarData.map((item, index) => {
          return (
            // map() to get each menu item
            // When click on each item navigate to path
            <div
              className="menuItem"
              id={item.path === window.location.pathname ? "active" : ""}
              key={index}
              onClick={() => {
                navigate(item.path);
              }}
            >
              <Stack direction={"row"} spacing={2}>
                {item.icon}
                <span>{item.title}</span>
              </Stack>
            </div>
          );
        })}
      </div>

      {isAdmin() && (
        <div className="menuList">
          {AdminSidebarData.map((item, index) => {
            return (
              // map() to get each admin menu item
              // When click on each item navigate to path
              <div
                className="menuItem"
                id={item.path === window.location.pathname ? "active" : ""}
                key={index}
                onClick={() => {
                  navigate(item.path);
                }}
              >
                <Stack direction={"row"} spacing={2}>
                  {item.icon}
                  <span>{item.title}</span>
                </Stack>
              </div>
            );
          })}
        </div>
      )}

      <div className="techSupport">
        <Stack spacing={1}>
          <Button
            id="button"
            variant="filled"
            onClick={() =>
              window.open("https://misso.org/misso-member-app-guide/")
            }
          >
            User Manual
            <InfoIcon sx={{ paddingLeft: "2px" }} />
          </Button>
          <Button
            id="button"
            variant="filled"
            onClick={() =>
              window.open("https://misso.org/contact-tech-support/")
            }
          >
            Tech Support
            <ContactSupportIcon sx={{ paddingLeft: "2px" }} />
          </Button>
        </Stack>
      </div>

      <div className="signOut">
        <Button id="button" variant="filled" onClick={() => signOut()}>
          Sign Out
          <LogoutIcon sx={{ paddingLeft: "2px" }} />
        </Button>
      </div>
    </>
  );
}
