import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { useMessage } from "../Utils/MessageContext";

import "../../Styles/styles-modal.css";
import {
  deleteMemberFromEventAttendance,
  insertToActivityLog,
  insertMemberToEventAttendance,
} from "../../APIs/member_functions"
import { useAuth } from "../Authentication/AuthContext";

export default function CheckInModal({ openModal, handleModal, code, event }) {
  const [isValid, setIsValid] = useState(false);
  const { setLoading, profile } = useAuth();
  const codeRef = useRef();

  const { showMessage, setMessage } = useMessage();

  function validateCode() {
    if (code === codeRef.current.value) {
      setIsValid(true);
    } else setIsValid(false);
    return isValid;
  }
  async function handleCheckIn() {
    setLoading(true);
    // console.log(codeRef.current.value);
    // console.log(profile.member_id);
    try {
      // insert event attendance
      const insertEventAttendance = await insertMemberToEventAttendance(
        profile.psid,
        event.event_id
      );
      // insert activity log
      if (insertEventAttendance.data) {
        const insertLog = await insertToActivityLog(
          profile.psid,
          event.event_id,
          event.activity_code,
          `Check-in ${event.title}`,
          "Self"
        );
        if (insertLog.data)
          setMessage(`Check in successfully to ${event.title}`, "success");
        else {
          // If failed to update log, cancel insert event attendance
          deleteMemberFromEventAttendance(profile.psid, event.event_id);
          setMessage(
            "Check-in unsuccessfully. Failed to update activity log. (Code: 4326)",
            "error"
          );
        }
      } else setMessage("You have already checked in to this event.", "error");
    } catch {
      setMessage("Failed to check in to event. (Code: 4204)", "error");
    }
    showMessage(true);
    handleModal(!openModal);
    setLoading(false);
  }

  // Validation for signup form using Yup package
  const validationSchema = yup.object({
    code: yup
      .string()
      .required("Please enter check-in code")
      .test("valid-code", "Wrong code!", validateCode),
  });

  const defaultValues = {
    code: "",
  };

  // Initiate formik
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: handleCheckIn,
  });

  const {
    touched,
    errors, // error condition for text field
    isSubmitting, // boolean of submit status
    handleSubmit, // method to handle form submission
    getFieldProps, // method to help touch error
  } = formik;

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalForm">
          <Typography variant="body">{event.title}</Typography>
          <Typography variant="h6">Enter Check-in Code</Typography>
          <FormikProvider value={formik}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Stack spacing={2} padding={"5px"}>
                <TextField
                  className="checkInCodeInput"
                  fullWidth
                  id="code"
                  name="code"
                  label="Check-in Code"
                  inputRef={codeRef}
                  {...getFieldProps("code")}
                  error={Boolean(touched.code && errors.code)}
                  helperText={touched.code && errors.code}
                  autoFocus={true}
                />
                <Button
                  className="checkInButton"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting || !isValid ? true : false}
                >
                  Submit
                </Button>
                <Button
                  className="checkInButton"
                  variant="text"
                  onClick={() => handleModal()}
                  disabled={isSubmitting ? true : false}
                >
                  Cancel
                </Button>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </div>
  );
}
