import React from "react";

import { CardMedia, Stack, Typography } from "@mui/material";
import "../../Styles/styles-getinvolved.css";

export default function SocialMediaItem(props) {
  const socialMedia = props.socialMedia;
  // Don't let Remind icon open a blank space to improve UX
  function handleClick(e) {
    if (!socialMedia.sm_name.includes("Remind"))
    window.open(`${socialMedia.sm_link}`, "_blank")
  }

  return (
    <div className="socialMedia">
      <Stack alignItems="center" spacing={1}>
        <CardMedia
          component="img"
          sx={{ width: 80, height: 80 }}
          image={`${socialMedia.sm_logo}`}
          id="logo"
          onClick={handleClick}
        />
        <Typography variant="subtitle2" textAlign="center">
          {socialMedia.sm_name}
        </Typography>
      </Stack>
    </div>
  );
}
