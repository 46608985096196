const domain = process.env.REACT_APP_DOMAIN_WP;

export const getEmailWordpress = async (email) => {
  const res = await fetch(`${domain}/getemailwordpress/${email}`);
  return res.json();
};

export const getMemberInfoWordpress = async (email) => {
  const res = await fetch(`${domain}/getmemberinfowordpress/${email}`);
  return res.json();
};
