import React, { useRef, useState } from "react";
import * as yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { useAuth } from "../Authentication/AuthContext";
import { useProfile } from "../Utils/ProfileContext";
import { getEmailWordpress } from "../../APIs/WP_functions";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import "../../Styles/styles.css";
import { useMessage } from "../Utils/MessageContext";
import { useNavigate } from "react-router-dom";
import { insertMemberToDatabase } from "../../APIs/member_functions";
import LoadingBackdrop from "../Utils/LoadingBackdrop";

export default function SignupForm() {
  // useRef to reference the current value of the text field
  const emailRef = useRef();
  const passwordRef = useRef();
  const [showPassword, setShowPassword] = useState(false);

  // Import functions from Firebas Auth
  const { signup, currentUser, loading, setLoading } = useAuth();
  // Import functions from useProfile to set profile and insert user to member db
  const { setProfileForBackEnd, memberProfile } = useProfile();

  // Import MessageContext features to display

  const {
    showMessage,
    setMessage,
    statusMessage,
    messageType,
    displayMessage,
  } = useMessage();

  // Routing features from react-router-dom
  const navigate = useNavigate();
  // Go back to the previous location othewise go to log in

  // Set default value for signup formik field
  const defaultValues = {
    email: "",
    password: "",
    passwordConfirm: "",
  };

  // Show password function
  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  // Signup function passing from AuthContext
  async function handleSignUp() {
    /*
    ------------------------------------------------------
    get request to Wordpress DB contact form
    if(res.data == null) -> User never registered
    If user is member from previous semester, get the 
    ------------------------------------------------------
    */
    // Validate email address with WP contact form

    setLoading(true);
    // Set submitting message
    setMessage("Subbmitting...", "info");
    showMessage(true);
    try {
      const res = await getEmailWordpress(emailRef.current.value);
      if (res.length > 0) {
        // If there is email address in WP
        // const wp_emails = Object.values(res[0]);
        // console.log(wp_emails);
        // Set profile using whichever email just signed up to insert to member db
        await setProfileForBackEnd(emailRef.current.value);
        const insertSuccessful = await insertMemberToDatabase(memberProfile);
        if (insertSuccessful.data) {
          // prevent creating firebase account if insert unseccessfully
          try {
            // get current text field value passed to signup function
            await signup(emailRef.current.value, passwordRef.current.value);
            console.log(currentUser);
            setMessage(
              "Your account has been created. You can log in now.",
              "success"
            );
            showMessage(true);
            navigate("/login");
          } catch (e) {
            // something wrong with firebase
            let errorMessage = JSON.stringify(e);
            // If this is a firebase error, print the error code
            if (errorMessage.includes("Firebase")) {
              errorMessage = e.code;
            }
            // If not firebase error, print general error message
            else {
              errorMessage =
                "Cannot create an account, please try again or contact tech@misso.org. (Code 1149)";
            }
            setMessage(errorMessage, "error");
            showMessage(true);
            // console.log(e);
          }
        } else {
          // something wrong with inserting to member db, could be dubplicate entry PSID
          setMessage(
            "Cannot create your account. Did you already create a MISSO App account? If not, please contact tech@misso.org. (Code 1145)",
            "error"
          );
          showMessage(true);
        }
      } else {
        // if can't find email in wp, meaning member hasn't registered or paid
        setMessage(
          "Cannot find your membership, please register by clicking 'Register' above. " +
            "If you already registered, please contact tech@misso.org. (Code: 1146)",
          "error"
        );
        showMessage(true);
      }
    } catch (e) {
      // if something wrong with getEmailWordpress() call
      setMessage(
        "Cannot validate your membership, please contact tech@misso.org. (Code 1147)",
        "error"
      );
      showMessage(true);
    }
    setLoading(false);
  }

  // Validation for signup form using Yup package
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Please enter a valid email.")
      .required("Email is required."),
    password: yup
      .string()
      .required("Password is required.")
      .min(6, "Password must have at least 6 characters"),
    passwordConfirm: yup
      .string()
      .required("Confirm password is required.")
      .oneOf([yup.ref("password"), null], "Pasword must match."),
  });

  // Initiate formik
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: handleSignUp,
  });

  const {
    touched,
    errors, // error condition for text field
    isSubmitting, // boolean of submi status
    handleSubmit, // method to handle form submission
    getFieldProps, // method to get field name
  } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Stack spacing={2} justifyContent="center">
            <TextField
              className="signupInput"
              fullWidth
              autoFocus
              id="email"
              name="email"
              label="Email Address"
              inputRef={emailRef}
              {...getFieldProps("email")} // Get the field value from the initial value
              error={Boolean(touched.email && errors.email)} // set error condition
              helperText={formik.touched.email && formik.errors.email} // display error message
            />
            <TextField
              className="signupInput"
              fullWidth
              id="password"
              name="password"
              label="Password"
              inputRef={passwordRef}
              type={showPassword ? "text" : "password"}
              {...getFieldProps("password")}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className="signupInput"
              fullWidth
              id="passwordConfirm"
              name="passwordConfirm"
              label="Confirm Password"
              type="password"
              {...getFieldProps("passwordConfirm")}
              error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
              helperText={touched.passwordConfirm && errors.passwordConfirm}
            />

            <Button
              className="signUpButton"
              variant="contained"
              type="submit"
              disabled={isSubmitting ? true : false}
            >
              Create An Account
            </Button>
            {displayMessage && (
              <Alert severity={messageType} id="alertBox">
                {messageType.toUpperCase()}: {statusMessage}
              </Alert>
            )}
          </Stack>
        </Form>
      </FormikProvider>
      <LoadingBackdrop loading={loading} />
    </>
  );
}
