import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import ViewMemberModal from "./Admin-Member-Modals/ViewMemberModal";
// import EditMemberModal from "./EditMemberModal";
import AddPointsModal from "./Admin-Member-Modals/AddPointsModal";
import AddOtherPointsModal from "./Admin-Member-Modals/AddOtherPointsModal";

// Pop up menu for adding point for members

export default function AdminMembersMenuOptions({
  chosenItem,
  dutyTypes,
  committees,
}) {
  const [openViewModal, setOpenViewModal] = useState(false);
  // const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddPointsModal, setOpenAddPointsModal] = useState(false);
  const [openAddOtherPointsModal, setOpenAddOtherPointsModal] = useState(false);
  // const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [coDutyTypes, setCoDutyTypes] = useState([]);
  const [otherDutyTypes, setOtherDutyTypes] = useState([]);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  function handleClose() {
    setAnchorEl(null);
  }
  function handleViewModal() {
    setAnchorEl(null);
    setOpenViewModal(!openViewModal);
  }
  // function handleEditModal() {
  //   setAnchorEl(null);
  //   setOpenEditModal(!openEditModal);
  // }
  function handleAddPointsModal() {
    setAnchorEl(null);
    setOpenAddPointsModal(!openAddPointsModal);
  }
  // function handleDeleteModal() {
  //   setAnchorEl(null);
  //   setOpenDeleteModal(!openDeleteModal);
  // }
  function handleAddOtherPointsModal() {
    setAnchorEl(null);
    setOpenAddOtherPointsModal(!openAddOtherPointsModal);
  }

  // SET ARRAY FOR COMMITTEE DUTY TYPES
  useEffect(() => {
    setCoDutyTypes(
      dutyTypes.filter((dutyType) => dutyType.code.includes("com"))
    );
  }, [dutyTypes]);

  // SET ARRAY FOR FUNDRAISING AND VOLUNTEER DUTY TYPES
  useEffect(() => {
    setOtherDutyTypes(
      dutyTypes.filter(
        (dutyType) =>
          dutyType.code.includes("fun") || dutyType.code.includes("vol")
      )
    );
  }, [dutyTypes]);

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={(e) => handleViewModal()}>View</MenuItem>
        <MenuItem onClick={(e) => handleAddPointsModal()}>
          Committee Points
        </MenuItem>
        {/* Removed Edit option because rarely needed. Faster to do it in DB */}
        {/* Removed Delete option because Database constraint in activity log. */}
        <MenuItem onClick={(e) => handleAddOtherPointsModal()}>
          Other Points
        </MenuItem>
      </Menu>
      {chosenItem && (
        <ViewMemberModal
          openModal={openViewModal}
          handleModal={handleViewModal}
          profile={chosenItem}
        />
      )}
      {/* <EditMemberModal
        openModal={openEditModal}
        handleModal={handleEditModal}
        chosenItem={chosenItem}
      /> */}
      {chosenItem && 0 < coDutyTypes.length && 0 < committees.length && (
        <AddPointsModal
          openModal={openAddPointsModal}
          handleModal={handleAddPointsModal}
          chosenItem={chosenItem}
          dutyTypes={coDutyTypes}
          committees={committees}
        />
      )}
      {/* <DeleteMemberModal
        openModal={openDeleteModal}
        handleModal={handleDeleteModal}
        chosenItem={chosenItem}
      /> */}
      {chosenItem && 0 < otherDutyTypes.length && 0 < committees.length && (
        <AddOtherPointsModal
          openModal={openAddOtherPointsModal}
          handleModal={handleAddOtherPointsModal}
          chosenItem={chosenItem}
          dutyTypes={otherDutyTypes}
        />
      )}
    </div>
  );
}
