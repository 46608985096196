import React from "react";
import { Box, Button, Modal, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../Styles/styles-modal.css";

export default function VideoModal({ openModal, handleModal, video }) {
  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalVideo">
          <Stack spacing={2}>
            <iframe
              className="videoIframe"
              frameBorder="0"
              allowFullScreen
              allow-same-origin="true"
              title="video"
              src={`https://www.youtube-nocookie.com/embed/${video.contentDetails.videoId}`}
            />
          </Stack>
          <Button variant="text" onClick={handleModal} className="videoClose">
            Close <CloseIcon />
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
