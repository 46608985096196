import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableSortLabel,
  Stack,
  Button,
} from "@mui/material/";
import "../../Styles/styles-memberboard.css";
import { useMessage } from "../../Components/Utils/MessageContext";
import ManualCheckinMemberListSearch from "./ManualCheckinMemberListSearch";
import { useAuth } from "../../Components/Authentication/AuthContext";
import {
  getEventAttendanceList,
} from "../../Admin-APIs/admin_event_functions";
import { fDateTime } from "../../Formatter/DateTimeFormat";
import { CSVLink } from "react-csv";

const TABLE_HEAD = [
  { id: "first", label: "First", align: "left" },
  { id: "last", label: "Last", align: "left" },
  { id: "psid", label: "PSID", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "cougarnet", label: "Cougarnet", align: "left" },
  { id: "created_time", label: "Time", align: "left" },
];

export default function ViewAttendanceList({ eventToView }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [memberList, setMemberList] = useState([]);
  const [filterMemberList, setFilteredMemberList] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();
  const [sortIconDirection, setSortIconDirection] = useState("asc");
  const [searchValue, setSearchValue] = useState("");
  const { setLoading } = useAuth();
  const { setMessage, showMessage } = useMessage();

  const { event_id, activity_code, title } = eventToView;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortData = (column) => {
    if (order === "asc") {
      const sortedData = [...memberList].sort((a, b) =>
        a[column] > b[column] ? 1 : -1
      );
      setMemberList(sortedData);
      setOrderBy(column);
      setSortIconDirection("asc");
      setOrder("desc");
    }
    if (order === "desc") {
      const sortedData = [...memberList].sort((a, b) =>
        a[column] < b[column] ? 1 : -1
      );
      setMemberList(sortedData);
      setOrderBy(column);
      setSortIconDirection("desc");
      setOrder("asc");
    }
  };

  // Get member list for check in
  async function fetchMembers() {
    setLoading(true);
    const res = await getEventAttendanceList(eventToView.event_id);
    if (res.data) {
      setMemberList(res.data);
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchMembers();
  }, [memberList.length]);

  useEffect(() => {
    // Set member list based on the search value. Default is ""
    setFilteredMemberList(
      searchValue === ""
        ? memberList
        : memberList.filter(
            (member) =>
              member.first_name
                .toLowerCase()
                .startsWith(searchValue.toLowerCase()) ||
              member.last_name
                .toLowerCase()
                .startsWith(searchValue.toLowerCase()) ||
              member.psid.toString().startsWith(searchValue)
          )
    );
  }, [searchValue, memberList]);

  return (
    <>
      <TableContainer className="accountCard">
        <Stack direction="row" alignItems="center" spacing={2}>
          {/* SEARCH */}
          <ManualCheckinMemberListSearch setSearchValue={setSearchValue} />
          {/* EXPORT EVENTS */}
          {memberList.length > 0 && (
            <Button
              variant="contained"
              sx={{
                maxWidth: "fit-content",
              }}
            >
              <CSVLink
                id="fileExport"
                data={memberList}
                filename={`Attendance for ${eventToView.title}`}
                target="_blank"
              >
                Export Attendance
              </CSVLink>
            </Button>
          )}
        </Stack>
        {/* TABLE HEADER */}
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((item) => (
                <TableCell
                  key={item.id}
                  align={item.align}
                  onClick={() => sortData(item.id)}
                >
                  <TableSortLabel
                    active={orderBy === item.id}
                    direction={sortIconDirection}
                  >
                    {item.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* MEMBER LIST */}
          <TableBody>
            {filterMemberList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((member) => {
                return (
                  <TableRow key={member.psid} id="row">
                    <TableCell>{member.first_name}</TableCell>
                    <TableCell>{member.last_name}</TableCell>
                    <TableCell>{member.psid}</TableCell>
                    <TableCell>{member.email}</TableCell>
                    <TableCell>{member.cougarnet}</TableCell>
                    <TableCell>{fDateTime(member.created_time)}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {filterMemberList.length === 0 && (
          <Typography align="center" fontStyle="italic">No data to show.</Typography>
        )}
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={memberList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
