import React, { useEffect, useState } from "react";
import "../../Styles/styles.css";
import "../../Styles/styles-officer-board.css";

import Layout from "../../Components/Utils/Layout";
import OpportunitiesList from "../../Components/Opportunities/OpportunitiesList";
import { getOpportunities } from "../../APIs/opportunities_functions";
import TitleBar from "../../Components/Utils/TitleBar";
import { useAuth } from "../../Components/Authentication/AuthContext";
import { useMessage } from "../../Components/Utils/MessageContext";
import { Box } from "@mui/system";
import { Alert, Typography } from "@mui/material";

export default function Opportunities() {
  const [opportunityList, setOpportunityList] = useState([]);
  const { setLoading } = useAuth();
  const { displayMessage, messageType, statusMessage } = useMessage();

  // get list of opportunities
  async function fetchOpportunities() {
    setLoading(true);
    const res = await getOpportunities();
    if (res.data) {
      setOpportunityList(res.data);
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchOpportunities();
  }, []);

  return (
    <Layout>
      {displayMessage && (
        <Box className="pageMessage">
          <Alert severity={messageType}>
            {messageType.toUpperCase()}: {statusMessage}
          </Alert>
        </Box>
      )}
      <TitleBar title={"External Opportunities"} />
      {opportunityList.length > 0 ? (
        <OpportunitiesList opportunityList={opportunityList} />
      ) : (
        <Typography variant="h4" align="center">
          We are searching for more opportunities! Please come back later.
        </Typography>
      )}
    </Layout>
  );
}
