import React from "react";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";

import "../../Styles/styles-modal.css";
import { useMessage } from "../../Components/Utils/MessageContext";
import { useAuth } from "../../Components/Authentication/AuthContext";
import { deleteEvent } from "../../Admin-APIs/admin_event_functions";

export default function DeleteEventModal({ openModal, handleModal, event, fetchEvents }) {
  const { showMessage, setMessage } = useMessage();
  const { setLoading } = useAuth();

  async function handleDeleteEvent() {
    setLoading(true);
    try {
      const deleteEventSuccessfully = await deleteEvent(event);
      if (deleteEventSuccessfully.data) {
        setMessage("Event was deleted successfully.", "success");
        fetchEvents()
      }
      else
        setMessage(
          "Cannot delete event with members checked in. (Code: 32168)",
          "error"
        );
    } catch (e) {
      setMessage("Unable to delete event. (Code: 432156)", "error");
    }
    handleModal();
    showMessage(true);
    setLoading(false);
  }

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalConfirm">
          <Stack spacing={3}>
            <Typography variant="h6">
              Do you want to delete this event? This cannot be undone!
            </Typography>
            <Typography variant="body1">Event title: {event.title}</Typography>
            <Typography variant="body1">Event company: {event.company}</Typography>
            <Stack direction="row" spacing={2} alignSelf="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  handleDeleteEvent();
                }}
              >
                Confirm
              </Button>
              <Button variant="text" onClick={() => handleModal()}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
