import { Alert, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllSocialMedia } from "../../APIs/getinvolved_functions";
import SocialMediaItem from "./SocialMediaItem";

export default function SocialMediaList() {
  const [socialMediaList, setSocialMediaList] = useState();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const res = await getAllSocialMedia();
      if (res.data) {
        setSocialMediaList(res.data);
        // console.log(res.data);
      } else setShowError(true);
    }
    fetchData();
  }, []);

  return (
    <div>
      {showError && (
        <Alert severity="error">
          ERROR: Cannot get social media data. (Code: 2244)
        </Alert>
      )}
      {socialMediaList && (
        <Grid container spacing={5} justifyContent="center" pb={1}>
          {socialMediaList.map((item) => {
            return (
              <Grid key={item.sm_id} item md={2}>
                <SocialMediaItem
                  socialMedia={item}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
}
