import React from "react";

import { Grid } from "@mui/material";
import OpportunityItem from "./OpportunityItem";

const OpportunitiesList = ({ opportunityList }) => {
  return (
    <>
      <Grid container spacing={5}>
        {opportunityList.map((opportunity) => {
          return (
            <Grid item xs={12} xl={6} key={opportunity.id}>
              <OpportunityItem opportunity={opportunity} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default OpportunitiesList;
