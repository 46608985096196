import React from "react";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { useMessage } from "../Utils/MessageContext";

import "../../Styles/styles-modal.css";
import { insertToEventSignUp } from "../../APIs/member_functions";
import { useAuth } from "../Authentication/AuthContext";

export default function SignUpModal({
  openModal,
  handleModal,
  event,
  setCancelSignUp,
  cancelSignUp,
}) {
  const { profile, setLoading } = useAuth();

  const { showMessage, setMessage } = useMessage();

  async function handleSignUp() {
    setLoading(true);
    try {
      const insertEventSignUp = await insertToEventSignUp(
        profile.psid,
        event.event_id
      );
      if (insertEventSignUp.data) {
        setMessage(`Sign up successfully to ${event.title}`, "success");
        setCancelSignUp(!cancelSignUp); // Show Cancel Sign Up button
      } else {
        setMessage("You have already signed up to this event.", "error");
      }
    } catch {
      setMessage("Failed to sign up to event. (Code: 4216)", "error");
    }
    setLoading(false);
    showMessage(true);
    handleModal();
  }

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalConfirm">
          <Stack spacing={3}>
            <Typography variant="h6">
              Do you want to RSVP for {event.title}?
            </Typography>
            <Stack direction="row" spacing={2} alignSelf="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  handleSignUp();
                }}
              >
                Confirm
              </Button>
              <Button variant="text" onClick={() => handleModal()}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
