import React, { useState } from "react";

import { Box, MenuItem, Select, Stack } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

export default function EventFilterBar(props) {
  // const [filterType, setFilterType] = useState("all");
  const [selectedTime, setSelectedTime] = useState("all");

  // const handleTypeChange = (event) => {
  //   setFilterType(event.target.value);
  //   props.setFilterBy(event.target.value);
  // };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
    props.setFilterTime(event.target.value);
  };

  return (
    <Box>
      <Stack direction="row" spacing={1} pb={2} alignItems="center">
        <FilterListIcon />

        {/* Filter by Time */}
        <Select
          value={selectedTime}
          sx={{ minWidth: 250 }}
          onChange={handleTimeChange}
        >
          <MenuItem value="all">All Time</MenuItem>
          <MenuItem value="today">Today</MenuItem>
          <MenuItem value="thisWeek">This Week</MenuItem>
          <MenuItem value="nextWeek">Next Week</MenuItem>
          <MenuItem value="thisMonth">This Month</MenuItem>
          <MenuItem value="nextMonth">Next Month</MenuItem>
        </Select>

        {/* Filter by Type */}
        {/* <Select
          value={filterType}
          sx={{ minWidth: 250 }}
          onChange={handleTypeChange}
        >
          <MenuItem value="all">All Types</MenuItem>
          <MenuItem value="career">Career Mixer</MenuItem>
          <MenuItem value="fund">Fundraising</MenuItem>
          <MenuItem value="hack">Hackathon</MenuItem>
          <MenuItem value="mentor">Mentorship Mixer</MenuItem>
          <MenuItem value="pm">Professional Meeting</MenuItem>
          <MenuItem value="soc">Social</MenuItem>
          <MenuItem value="spe">Special</MenuItem>
          <MenuItem value="vol">Volunteer</MenuItem>
          <MenuItem value="ws">Workshop</MenuItem>
        </Select> */}
      </Stack>
    </Box>
  );
}
