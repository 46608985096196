import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: { main: "#0e4779", dark: "#143D62" },
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 650,
      md: 700,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          textAlign: "left"
        }
      }
    }
  }
});

theme.typography.h3 = {
  fontSize: "1.5rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
  },
  fontFamily: "Raleway, Arial",
};

theme.typography.h4 = {
  fontSize: "1.2rem",
  "@media (min-width:600px)": {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.2rem",
  },
  fontFamily: "Raleway, Arial",
};

theme.typography.subtitle1 = {
  fontSize: "1rem",
  "@media (min-width:600px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
  fontFamily: "Raleway, Arial",
};

export default theme;
