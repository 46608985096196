import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import EditSocialMediaModal from "../Admin-Events/EditSocialMediaModal";

// Pop up menu for adding point for members

export default function AdminSocialMenuOption({ chosenItem, fetchItems }) {
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  function handleClose() {
    setAnchorEl(null);
  }
  function handleModal() {
    setAnchorEl(null);
    setOpenModal(!openModal);
  }

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={(e) => handleModal()}>Edit</MenuItem>
        {/* Only Tech can delete an event to prevent data loss */}
      </Menu>
      <EditSocialMediaModal
        openModal={openModal}
        handleModal={handleModal}
        chosenItem={chosenItem}
        fetchItems={fetchItems}
      />
    </div>
  );
}
