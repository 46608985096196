import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { attire } from "../../Components/Utils/DressCodes";
import * as yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";

import "../../Styles/styles-modal.css";
import { useMessage } from "../../Components/Utils/MessageContext";
import {
  getActivityTypes,
  insertEvent,
} from "../../Admin-APIs/admin_event_functions";
import { fDateTimeLocal } from "../../Formatter/DateTimeFormat";
import { useAuth } from "../../Components/Authentication/AuthContext";


export default function AddEventModal({ openModal, handleModal, fetchEvents }) {
  const [activityTypes, setActivityTypes] = useState([]);
  const [selected, setSelected] = useState("");
  const [hasSignUp, setHasSignUp] = useState(false);
  const { showMessage, setMessage } = useMessage();
  const { setLoading, profile } = useAuth();

  function handleSelected(event) {
    setSelected(event.target.value);
  }

  async function handleAddEvent() {
    setLoading(true);
    try {
      const insertEventSucessfully = await insertEvent(formik.values);
      if (insertEventSucessfully.data) {
        setMessage(`${formik.values.title} was added successfully`, "success");
        fetchEvents();
      } else {
        setMessage(`Unable to add event. (Code: 1230)`, "error");
      }
    } catch (e) {
      setMessage("Failed to add event. (Code: 4289)", "error");
    }
    showMessage(true);
    handleModal(!openModal);
    setLoading(false);
  }

  useEffect(() => {
    async function fetchActivityTypes() {
      const res = await getActivityTypes();
      if (res.data) {
        setActivityTypes(res.data);
      }
    }
    fetchActivityTypes();
  }, [activityTypes.length]);

  const validationSchema = yup.object({
    title: yup
      .string()
      .max(45, "Max 45 characters.")
      .matches(
        /^[ A-Za-z0-9!@#$%^&*()_+-.]*$/,
        "Enter letter, digits or _@./#&+- only."
      )
      .required("This field is required."),
    company: yup
      .string()
      .max(45, "Max 45 characters.")
      .matches(
        /^[ A-Za-z0-9!@#$%^&*()_+-.]*$/,
        "Enter letter, digits or _@./#&+- only."
      )
      .required("This field is required."),
    description: yup
      .string()
      .max(255)
      .matches(
        /^[ A-Za-z0-9!@#$%^&*()_+-.]*$/,
        "Enter letter, digits or _@./#&+- only."
      )
      .required("Please add some description for this event."),
    location: yup
      .string()
      .max(45, "Max 45 characters.")
      .matches(
        /^[ A-Za-z0-9!@#$%^&*()_+-.]*$/,
        "Enter letter, digits or _@./#&+- only."
      )
      .required("This field is required."),
    start: yup.date().required("This field is required."),
    end: yup.date().required("This field is required."),
    meeting_link: yup.string().url("Must include 'https://' at the beginning."),
    map_link: yup.string().url("Must include 'https://' at the beginning."),
    image_link: yup.string().url("Must include 'https://' at the beginning."),
    checkin_code: yup
      .string()
      .matches(
        /^[ A-Za-z0-9!@#$%^&*()_+-.]*$/,
        "Enter letter, digits or _@./#&+- only."
      )
      .min(5, "Code must have at least 5 characters.")
      .max(20, "Code must have maximum 20 characters."),

    signup_link: yup.string().url("Must include 'https://' at the beginning."),
  });

  const defaultValues = {
    activity_code: "pm",
    title: "",
    company: "",
    description: "",
    attire: "Business Casual",
    location: "",
    start: fDateTimeLocal(new Date()),
    end: fDateTimeLocal(new Date()),
    meeting_link: "",
    map_link: "",
    image_link:
      "https://misso.org/wp-content/uploads/2019/01/cropped-misso-logo-1.jpg",
    checkin_code: "",
    has_signup: false,
    signup_link: "",
    signup_exp: fDateTimeLocal(new Date()),
    created_by: profile.email,
  };

  // Initiate formik
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: handleAddEvent,
  });

  const {
    touched,
    errors, // error condition for text field
    getFieldProps, // method to help touch error
    handleSubmit,
    isSubmitting,
  } = formik;

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalAdminForm" overflow="auto">
          <Typography variant="h6">Add new event</Typography>
          <FormikProvider value={formik}>
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Stack spacing={1.2} padding={2}>
                <Select
                  value={selected}
                  size="small"
                  sx={{ minWidth: 250 }}
                  onChange={(e) => handleSelected(e)}
                  {...getFieldProps("activity_code")}
                >
                  {activityTypes.map((item) => {
                    return (
                      <MenuItem key={item.activity_id} value={`${item.code}`}>
                        {item.name} - {item.points} points
                      </MenuItem>
                    );
                  })}
                </Select>
                <TextField
                  fullWidth
                  size="small"
                  name="title"
                  label="Event Title *"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("title")}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="company"
                  label="Company Name *"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("company")}
                  error={Boolean(touched.company && errors.company)}
                  helperText={touched.company && errors.company}
                />
                <TextField
                  fullWidth
                  multiline
                  size="small"
                  rows={3}
                  name="description"
                  label="Event Description *"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("description")}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
                <Select
                  value={selected}
                  size="small"
                  sx={{ minWidth: 250 }}
                  onChange={(e) => handleSelected(e)}
                  {...getFieldProps("attire")}
                >
                  {attire.map((item) => {
                    return (
                      <MenuItem key={item.id} value={`${item.type}`}>
                        {item.type}
                      </MenuItem>
                    );
                  })}
                </Select>
                <TextField
                  fullWidth
                  size="small"
                  name="location"
                  label="Event Location or Address *"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("location")}
                  error={Boolean(touched.location && errors.location)}
                  helperText={touched.location && errors.location}
                />
                <Typography variant="caption" fontStyle="italic" pl={1} pb={2}>
                  {`If online, put the meeting platform (Zoom, Teams...)`}
                </Typography>
                <Stack direction="row" spacing={2}>
                  <TextField
                    name="start"
                    size="small"
                    label="Start Date &amp; Time"
                    type="datetime-local"
                    {...getFieldProps("start")}
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    name="end"
                    size="small"
                    label="End Date &amp; Time"
                    type="datetime-local"
                    {...getFieldProps("end")}
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
                <TextField
                  fullWidth
                  size="small"
                  name="checkin_code"
                  label="Check-in Code (Optional)"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("checkin_code")}
                  error={Boolean(touched.checkin_code && errors.checkin_code)}
                  helperText={touched.checkin_code && errors.checkin_code}
                />
                <Typography variant="caption" fontStyle="italic" pl={1} pb={2}>
                  Leave blank if check-in is not required.
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="meeting_link"
                  label="Meeting Link (Optional)"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("meeting_link")}
                  error={Boolean(touched.meeting_link && errors.meeting_link)}
                  helperText={touched.meeting_link && errors.meeting_link}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="map_link"
                  label="Google Map Link (Optional)"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("map_link")}
                  error={Boolean(touched.map_link && errors.map_link)}
                  helperText={touched.map_link && errors.map_link}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="image_link"
                  label="Event Image Link (Optional)"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("image_link")}
                  error={Boolean(touched.image_link && errors.image_link)}
                  helperText={touched.image_link && errors.image_link}
                />

                <Stack direction="row" alignItems="center">
                  <Typography>Need RSVP?</Typography>
                  <Checkbox
                    checked={hasSignUp}
                    {...getFieldProps("has_signup")}
                    onChange={() => setHasSignUp(!hasSignUp)}
                    sx={{ width: "50px" }}
                  />
                </Stack>
                {hasSignUp && (
                  <>
                    <TextField
                      name="signup_exp"
                      size="small"
                      label="Signup Deadline"
                      type="datetime-local"
                      {...getFieldProps("signup_exp")}
                      sx={{ width: 220 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      name="signup_link"
                      size="small"
                      label="External Signup Link (Optional)"
                      //   inputRef={linkedInRef}
                      {...getFieldProps("signup_link")}
                      error={Boolean(touched.signup_link && errors.signup_link)}
                      helperText={touched.signup_link && errors.signup_link}
                    />
                    <Typography
                      variant="caption"
                      fontStyle="italic"
                      pl={1}
                      pb={2}
                    >
                      Link to a google form, survey, pole... if necessary.
                    </Typography>
                  </>
                )}
                <TextField
                  fullWidth
                  size="small"
                  name="created_by"
                  label="Created By"
                  //   inputRef={linkedInRef}
                  {...getFieldProps("created_by")}
                  disabled
                />
              </Stack>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  className="checkInButton"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting ? true : false}
                >
                  Submit
                </Button>
                <Button
                  className="checkInButton"
                  variant="text"
                  onClick={handleModal}
                >
                  Cancel
                </Button>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </div>
  );
}
