import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useMessage } from "../Utils/MessageContext";

import "../../Styles/styles-modal.css";
import { useAuth } from "../Authentication/AuthContext";
import { uploadResume } from "../../APIs/development_functions";

export default function ResumeUploadModal({ openModal, handleModal }) {
  const { profile, setLoading, loading } = useAuth();
  const { showMessage, setMessage } = useMessage();
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  async function handleSubmission() {
    setLoading(true);
    try {
      const resumeFile = new FormData();
      resumeFile.append("file", selectedFile);
      resumeFile.append("firstName", profile.first_name);
      resumeFile.append("lastName", profile.last_name);
      resumeFile.append("psid", profile.psid);
      resumeFile.append("email", profile.email);
      const res = await uploadResume(resumeFile);
      if (res) {
        setMessage(
          "Resume Uploaded! You will receive a confirmation email shortly.",
          "success"
        );
      }
    } catch (e) {
      setMessage("Failed to sign up for Resume Review. (Code: 4216)", "error");
    }
    setLoading(false);
    showMessage(true);
    handleModal();
  }

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalConfirm">
          <Stack spacing={3}>
            <Typography variant="h6">Please upload your resume</Typography>
            {/* File picker button */}
            <Stack
              direction="row"
              spacing={2}
              alignSelf="flex-start"
              justifyContent="center"
              alignItems="center"
            >
              <label htmlFor="fileButton" className="fileUploadButton">
                <input
                  accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  id="fileButton"
                  type="file"
                  onChange={changeHandler}
                />
                <Typography>Browse</Typography>
              </label>
              {selectedFile && (
                <Typography fontStyle="italic">{selectedFile.name}</Typography>
              )}
            </Stack>
            {/* File description */}
            <Typography variant="body">
              {`Allowed file type is Word document (.doc, .docx)`}
            </Typography>

            <Stack direction="row" spacing={2} alignSelf="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  handleSubmission();
                }}
                disabled={isFilePicked && !loading ? false : true}
              >
                {loading ? "Submitting..." : "Upload"}
              </Button>
              <Button variant="text" onClick={() => handleModal()}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
