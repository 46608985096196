export const attire = [
  { id: 0, type: "N/A" },
  { id: 1, type: "Business Casual" },
  { id: 2, type: "Business Professional" },
  { id: 3, type: "Casual" },
  { id: 4, type: "Smart Casual" },
  { id: 5, type: "Black Tie" },
  { id: 6, type: "Formal" },
  { id: 7, type: "Costume" },
];
