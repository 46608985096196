import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllDevelopment } from "../../APIs/development_functions";
import DevelopmentItem from "./DevelopmentItem";

export default function DevelopmentList() {
  const [pdList, setPdList] = useState();
  useEffect(() => {
    async function fetchPdList() {
      const res = await getAllDevelopment();
      if (res.data) {
        // console.log(res.data);
        setPdList(res.data);
      }
    }
    fetchPdList();
  }, []);

  return (
    <>
      {pdList && (
        <Stack spacing={3}>
          {pdList.map((item) => {
            return <DevelopmentItem key={item.pd_id} pdItem={item} />;
          })}
        </Stack>
      )}
    </>
  );
}
