import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getPointsByMember } from "../../APIs/member_functions";

import "../../Styles/styles-myaccount.css";

export default function UserPoints({ profile }) {
  const [points, setPoints] = useState();

  // Rerender points when user visit this page so that points are updated after checkin/join committee
  useEffect(() => {
    async function fetchData() {
      const res = await getPointsByMember(profile.psid);
      if (res.data.length > 0) {
        setPoints(res.data[0]);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="accountCard">
      {points && (
        <Grid container p={3}>
          <Grid item xs={4}>
            <Stack spacing={2}>
              <Typography id="label">Points</Typography>
            </Stack>
          </Grid>
          
          <Grid item xs={8}>
            <Stack spacing={2} maxWidth="200px" textAlign="left">
              <Typography id="total" bgcolor={"#A4F9C8"} color={"#005f26"}>
                Total Points: {points.total_points}
              </Typography>

              <Typography
                className="pointType"
                id="events"
                bgcolor={"#ADDFFF"}
                color={"#004977"}
              >
                Event Points: {points.e_points}
              </Typography>

              <Typography
                className="pointType"
                id="committees"
                bgcolor={"#FFD582"}
                color={"#6C4800"}
              >
                Committee Points: {points.c_points}
              </Typography>

              <Typography id="m1">
                Month 1 Points: {points.m1_points}
              </Typography>

              <Typography id="m2">
                Month 2 Points: {points.m2_points}
              </Typography>

              <Typography id="m3">
                Month 3 Points: {points.m3_points}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
