import { Alert } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import AdminOfficcerList from "../../Components-Admin/Admin-Officers/AdminOfficerList";
import Layout from "../../Components/Utils/Layout";
import { useMessage } from "../../Components/Utils/MessageContext";
import TitleBar from "../../Components/Utils/TitleBar";

export default function AdminRoster() {
  const { displayMessage, messageType, statusMessage } = useMessage();
  return (
    <div>
      <Layout>
        {displayMessage && (
          <Box className="pageMessage">
            <Alert severity={messageType}>
              {messageType.toUpperCase()}: {statusMessage}
            </Alert>
          </Box>
        )}
        <TitleBar
          title="Officer Roster"
          description="Features: View, Export officer roster"
        />
        <AdminOfficcerList />
      </Layout>
    </div>
  );
}
