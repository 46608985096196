import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../Authentication/AuthContext";
import { useMessage } from "./MessageContext";

export default function PrivateRoute({ children }) {
  const { currentUser, profile, isAdmin } = useAuth();
  const { showMessage, setMessage } = useMessage();
  const location = useLocation();

  // Print message if user attempt to view a private page without logging in
  useEffect(() => {
    if (!currentUser || !isAdmin()) {
      setMessage("You do not have the permission to view this page.", "error");
      showMessage(true);
    }
  }, []);

  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected (const location).
  // This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  return (
    <>
      {currentUser && isAdmin() ? ( // if there is firebase user and profile from logging in
        profile && children
      ) : (
        <Navigate to="/events" state={{ from: location }} replace />
      )}
    </>
  );
}
