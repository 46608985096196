import React, { useEffect, useState } from "react";

import { Button, CardMedia, Stack, Typography } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import "../../Styles/styles-getinvolved.css";
import JoinCommitteeModal from "../Modals/JoinCommitteeModal";
import { checkIfMemberInCommittee } from "../../APIs/member_functions";
import CancelCommitteeModal from "../Modals/CancelCommitteeModal";
import { useAuth } from "../Authentication/AuthContext";

export default function CommitteeItem(props) {
  const [committee, setEvent] = useState(props.committee);
  const [openSignUpModal, setOpenSignUpModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [cancelSignUp, setCancelSignUp] = useState();
  const { profile } = useAuth();

  function handleJoin() {
    setOpenSignUpModal(!openSignUpModal);
  }

  function handleCancel() {
    setOpenCancelModal(!openCancelModal);
  }

  // Check if member is in a committee to disable join button
  useEffect(() => {
    async function fetchData() {
      const res = await checkIfMemberInCommittee(
        profile.psid,
        committee.committee_id
      );
      if (res.data.length > 0) {
        setCancelSignUp(true);
      }
    }
    fetchData();
  }, [cancelSignUp]);

  function handleEmail() {
    window.open(`mailto:${committee.officer_email}`);
  }

  function handleJoinGroupme() {
    window.open(`${committee.groupme_link}`, "_blank");
  }

  return (
    <div>
      {openSignUpModal && (
        <JoinCommitteeModal
          openModal={openSignUpModal}
          handleModal={handleJoin}
          committee={committee}
          setCancelSignUp={setCancelSignUp}
          cancelSignUp={cancelSignUp}
        />
      )}
      {openCancelModal && (
        <CancelCommitteeModal
          openModal={openCancelModal}
          handleModal={handleCancel}
          committee={committee}
          setCancelSignUp={setCancelSignUp}
          cancelSignUp={cancelSignUp}
        />
      )}
      <div className="involveCard">
        {/* Stack horizontally flyer and event info */}
        <Stack>
          {/* Flyer */}
          <div className="involveLogo">
            <CardMedia
              component="img"
              sx={{ width: 100, height: 100 }}
              image={`${committee.logo_link}`}
              id="photo"
            />
          </div>
          {/* Content */}
          <div className="involveInfo">
            {/* Committee Name*/}
            <Typography variant="h6" p={"5px"}>
              {committee.name}
            </Typography>
            {/* Email Officer */}
            <Button className="involveButton" id="name" onClick={handleEmail}>
              <EmailOutlinedIcon sx={{ padding: "2px" }} />
              {committee.officer_name}
            </Button>
            {/* Description */}
            <div className="involvedDescription">
              <Typography p={"10px"}>{committee.description}</Typography>
            </div>
            {/* Action Button */}
            <Stack spacing={1} direction="row" justifyContent="center">
              {/* DYNAMIC JOIN BUTTON */}
              <Button
                className="involveButton"
                id={cancelSignUp ? "cancel" : "join"}
                onClick={cancelSignUp ? handleCancel : handleJoin}
                disabled={committee.is_open ? false : true}
              >
                {cancelSignUp ? "Leave" : "Join"}
              </Button>
              {/* JOIN GROUPME */}
              <Button
                className="involveButton"
                id="groupme"
                onClick={handleJoinGroupme}
              >
                GroupMe
              </Button>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
}
