import React, { useRef } from "react";
import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";

import { useMessage } from "../Utils/MessageContext";

import "../../Styles/styles-modal.css";
import { useAuth } from "../Authentication/AuthContext";
import { updateMemberSocialMedia } from "../../APIs/member_functions";

export default function EditSocialMediaModal({ openModal, handleModal }) {
  const { showMessage, setMessage } = useMessage();
  const { profile, setLoading } = useAuth();
  const linkedInRef = useRef();
  const instagramRef = useRef();

  async function handleChangeSocial() {
    console.log(formik.values);
    setLoading(true);
    try {
      const smUpdate = await updateMemberSocialMedia({
        ...formik.values,
        psid: profile.psid,
      });
      if (smUpdate.data) {
        setMessage("Successfully updated your profile.", "success");
      } else {
        setMessage("Unable to update your profile. (Code: 89743548)", "error");
      }
    } catch (e) {
      setMessage("Failed to update your profile. (Code: 21654965)", "error");
    }
    showMessage(true);
    handleModal();
    setLoading(false);
  }

  // function validateInstagram() {
  //   if (
  //     instagramRef.current.value
  //       .toLowerCase()
  //       .indexOf("instagram.com") === -1
  //   )
  //     return true;
  //   else return false;
  // }

  // Validation for signup form using Yup package
  const validationSchema = yup.object({
    linkedIn: yup
    .string()
    .max(255, "Maximum 255 characters.")
    .url("Your URL should contain 'https://'")
    .matches(
      /(https:\/\/(www.)?linkedin\.com\/in\/)+([a-zA-Z]+)/,
      "Please enter a valid LinkedIn URL"
    ),
    instagram: yup.string(),
  });

  const defaultValues = {
    linkedIn: profile.linkedIn || "",
    instagram: profile.instagram || "",
  };

  // Initiate formik
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: handleChangeSocial,
  });

  const {
    touched,
    errors, // error condition for text field
    isSubmitting, // boolean of submit status
    handleSubmit, // method to handle form submission
    getFieldProps, // method to help touch error
  } = formik;

  return (
    <div>
      <Modal open={openModal} onClose={() => handleModal()} id="modalContainer">
        <Box className="modalForm">
          <Typography variant="h6">Update your Social Media Profile</Typography>
          <FormikProvider value={formik}>
            <Form
              onSubmit={(e) => {
                // e.preventDefault();
                handleSubmit();
              }}
            >
              <Stack spacing={2} padding={2} width={"500px"}>
                <TextField
                  className="linkedInInput"
                  fullWidth
                  id="linkedin"
                  name="linkedIn"
                  label="LinkedIn URL"
                  inputRef={linkedInRef}
                  {...getFieldProps("linkedIn")}
                  error={Boolean(touched.linkedIn && errors.linkedIn)}
                  helperText={touched.linkedIn && errors.linkedIn}
                />
                <TextField
                  className="instagramInput"
                  fullWidth
                  id="instagram"
                  name="instagram"
                  label="Instagram (Username Only)"
                  inputRef={instagramRef}
                  {...getFieldProps("instagram")}
                  error={Boolean(touched.instagram && errors.instagram)}
                  helperText={touched.instagram && errors.instagram}
                />
              </Stack>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  className="checkInButton"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting ? true : false}
                >
                  Submit
                </Button>
                <Button
                  className="checkInButton"
                  variant="text"
                  onClick={() => handleModal()}
                  disabled={isSubmitting ? true : false}
                >
                  Cancel
                </Button>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </div>
  );
}
