import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
} from "@mui/material/";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import { useAuth } from "../../Components/Authentication/AuthContext";

import "../../Styles/styles-memberboard.css";
import { useMessage } from "../../Components/Utils/MessageContext";
import { getOfficerRoster } from "../../Admin-APIs/admin_officers_functions";

const TABLE_HEAD = [
  { label: "Name", align: "left" },
  { label: "Position", align: "left" },
  // { label: "Team", align: "left" },
  { label: "Email", align: "left" },
  { label: "Alias", align: "left" },
  { label: "Personal Email", align: "left" },
  { label: "Phone", align: "left" },
  { label: "PSID", align: "left" },
  { label: "Office Access", align: "left" },
  { label: "LinkedIn", align: "left" },
  { label: "Instagram", align: "left" },
  { label: "Shirt Size", align: "left" },
];

export default function AdminOfficcerList() {
  const [officerList, setOfficerList] = useState([]);
  const { setLoading } = useAuth();
  const { setMessage, showMessage } = useMessage();

  // Get All Admin Events
  useEffect(() => {
    async function fetchOfficers() {
      setLoading(true);
      const res = await getOfficerRoster();
      if (res.data) {
        setOfficerList(res.data);
      } else {
        setMessage("Cannot get officer roster. Code (6966)");
        showMessage(true);
      }
      setLoading(false);
    }
    fetchOfficers();
  }, []);

  function handleExport() {}

  return (
    <>
      <TableContainer className="accountCard">
        {/* EXPORT OFFICERS */}
        <Button
          variant="contained"
          sx={{
            maxWidth: "fit-content",
            marginLeft: "20px",
            marginBottom: "10px",
          }}
        >
          <CSVLink
            id="fileExport"
            data={officerList}
            filename="MISSO-officer-roster"
            target="_blank"
          >
            Export Officers
          </CSVLink>
        </Button>
        {/* TABLE HEADER */}
        <Table sx={{ width: "max-content" }}>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((item, index) => (
                <TableCell key={index} align={item.align}>
                  {item.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* MEMBER LIST */}
          <TableBody>
            {officerList.map((item, index) => {
              return (
                <TableRow key={index} id="row">
                  <TableCell>
                    {item.first_name} {item.last_name}
                  </TableCell>
                  <TableCell>{item.position}</TableCell>
                  {/* <TableCell>{item.team}</TableCell> */}
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{item.alias}</TableCell>
                  <TableCell>{item.personal_email}</TableCell>
                  <TableCell>
                    {`(${item.phone.toString().slice(0, 3)}) 
                    ${item.phone.toString().slice(3, 6)}-${item.phone
                      .toString()
                      .slice(6, 10)}`}
                  </TableCell>
                  <TableCell>{item.psid}</TableCell>
                  <TableCell width="10px">
                    {item.office_access === 1 ? <DoneIcon /> : <CloseIcon />}
                  </TableCell>
                  <TableCell>
                    {item.linkedIn ? (
                      <Link
                        href={`${item.linkedIn}`}
                        target="_blank"
                        fontSize="12px"
                      >
                        LinkedIn
                      </Link>
                    ) : (
                      <Typography fontSize="12px">None</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {item.instagram ? (
                      <Link
                        href={`https://www.instagram.com/${item.instagram}`}
                        target="_blank"
                        fontSize="12px"
                      >
                        Instagram
                      </Link>
                    ) : (
                      <Typography fontSize="12px">None</Typography>
                    )}
                  </TableCell>
                  <TableCell>{item.shirt_size}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
