import React, { useContext, useState, useEffect } from "react";
import { getProfileByEmail } from "../../APIs/member_functions.js";
import LoadingBackdrop from "../Utils/LoadingBackdrop.js";
import { auth } from "./firebase.js"; // auth module contains authentication features

// Create a global authentication state to be used throughtout the children components
const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  // Current logged in user
  const [currentUser, setCurrentUser] = useState();
  const [waitCurrentUser, setWaitCurrentUser] = useState(true); // wait for current user
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState([]);

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function signOut() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateUser(new_name) {
    return auth.currentUser.updateProfile({
      displayName: new_name,
    });
  }

  function isAdmin() {
    return profile.is_admin === 1;
  }

  function isTechGod() {
    return profile.is_admin === 1 && profile.email === "tech@misso.org";
  }

  async function setProfileForFrontEnd(user) {
    const res = await getProfileByEmail(user.email);
    if (res.data) {
      setProfile(res.data[0]);
    }
  }

  // onAUthStateChaned listens to the authentication event (login logout signup)
  // this method return 1 single parameter
  // Set current user after auth state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      // Take the user returned by onAuthStateChaned() and pass to set user
      // Find current user first
      setCurrentUser(user);
      // Then stop the wait
      if (user) {
        setLoading(true);
        await setProfileForFrontEnd(user);
      }
      setWaitCurrentUser(false);
      setLoading(false);
      // console.log(user);
    });
    return unsubscribe; // return unsubscribe() and stop listener
  }, [profile.length]); // [] makes unsubscribe runs once on first render

  const value = {
    currentUser,
    signup,
    login,
    signOut,
    resetPassword,
    updateUser,
    loading,
    setLoading,
    setProfileForFrontEnd,
    profile,
    isAdmin,
    isTechGod,
  };

  return (
    <AuthContext.Provider value={value}>
      {/* Only render the children, which is our app, when there is a current user */}
      <LoadingBackdrop loading={loading} />
      {!waitCurrentUser && children}
    </AuthContext.Provider>
  );
}
