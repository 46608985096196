import React, { useEffect, useState } from "react";

import { Button, CardMedia, Stack, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EventNoteIcon from "@mui/icons-material/EventNote";
import PeopleAltIcon from "@mui/icons-material/Group";
import CheckroomIcon from "@mui/icons-material/Checkroom";

import CheckInModal from "../Modals/CheckInModal";

import {
  fDate,
  fDateFull,
  fDateOnly,
  fMonthOnly,
  fTimeShort,
} from "../../Formatter/DateTimeFormat";
import "../../Styles/styles-events.css";
import SignUpModal from "../Modals/SignUpModal";
import { getRsvpByMemberEvent } from "../../APIs/member_functions";
import CancelSignUpModal from "../Modals/CancelSignUpModal";
import { useAuth } from "../Authentication/AuthContext";
/*
      {"#A4F9C8"}>Professional Meeting
      {"#ADDFFF"}>Social
      {"#F9B5AC"}>Volunteer
      {"#E5A4CB"}>Fundraising
      {"#E9D985"}>Workshop
      {"#EFEBCE"}>Special Event
*/

// Card layout for each event item
export default function EventItem(props) {
  const event = props.event;
  const [openCheckInModal, setOpenCheckInModal] = useState(false);
  const [openSignUpModal, setOpenSignUpModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [cancelSignUp, setCancelSignUp] = useState(); // Cancel Signup is Visible
  const { profile } = useAuth();

  function handleCheckIn() {
    setOpenCheckInModal(!openCheckInModal);
  }

  function handleSignUp() {
    setOpenSignUpModal(!openSignUpModal);
  }

  function handleCancel() {
    setOpenCancelModal(!openCancelModal);
  }

  function handleOpenLink(link) {
    window.open(`${link}`, "_blank");
  }

  // Format date to number
  const startDate = fDateOnly(event.start);
  const endDate = fDateOnly(event.end);
  const startMonth = fMonthOnly(event.start);
  const endMonth = fMonthOnly(event.end);
  // const startYear = fYearOnly(event.start);
  // const endYear = fYearOnly(event.end);
  const today = new Date();

  // Check if member RSVP to an event
  useEffect(() => {
    async function fetchData() {
      const res = await getRsvpByMemberEvent(profile.psid, event.event_id);
      if (res.data.length > 0) {
        setCancelSignUp(true);
      }
    }
    fetchData();
  }, [cancelSignUp]);

  return (
    <div className="eventCard">
      {openCheckInModal && (
        <CheckInModal
          openModal={openCheckInModal}
          handleModal={handleCheckIn}
          code={event.checkin_code}
          event={event}
        />
      )}
      {openSignUpModal && (
        <SignUpModal
          openModal={openSignUpModal}
          handleModal={handleSignUp}
          event={event}
          setCancelSignUp={setCancelSignUp}
        />
      )}
      {openCancelModal && (
        <CancelSignUpModal
          openModal={openCancelModal}
          handleModal={handleCancel}
          event={event}
          setCancelSignUp={setCancelSignUp}
        />
      )}

      {/* Stack flyer-description and details-buttons */}
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        {/* Flyer */}
        <div className="eventFlyer">
          <CardMedia
            component="img"
            p={2}
            sx={{
              width: { xs: "100%", md: 200 },
              height: { xs: "250px", md: 200 },
            }}
            image={event.image_link}
            id="photo"
          />
        </div>

        {/* Stack Event flags - title - description */}
        <Stack p={2} spacing={1}>
          {/* Activity Type and Points Flag */}
          <div>
            <Stack direction="row" spacing="5px">
              <Typography
                id="type"
                bgcolor={"#A4F9C8"}
                textTransform="uppercase"
                variant="subtitle2"
              >
                {event.activity_type}
              </Typography>
              <Typography
                id="type"
                bgcolor={"#FFD582"}
                textTransform="uppercase"
                variant="subtitle2"
              >
                {event.points} Points
              </Typography>
              {/* Show TODAY flag for today's events */}
              {(fDate(event.start) === fDate(today) ||
                fDate(event.end) === fDate(today)) && (
                <Typography
                  id="today"
                  bgcolor={"#EB6534"}
                  textTransform="uppercase"
                  variant="subtitle2"
                >
                  Today
                </Typography>
              )}
            </Stack>
          </div>

          {/* Event Title */}
          <div>
            <Typography variant="h3" pl={1} pt={2} textTransform="capitalize">
              {event.title}
            </Typography>
          </div>

          {/* Description */}
          <div className="eventDescription">
            <Typography p={1}>{event.description}</Typography>
          </div>
        </Stack>
      </Stack>

      {/* Stack  Details and Button horizontally*/}
      <Stack direction="row" justifyContent="space-between">
        {/* Stack Company - Date - Time - Location */}
        <Stack p={2} spacing={1} justifyContent="flex-start" width="60%">
          {/* Company */}
          <Stack direction="row" spacing="5px" alignItems="center">
            <PeopleAltIcon />
            <Typography variant="subtitle1" textTransform="capitalize">
              {event.company}
            </Typography>
          </Stack>

          {/* Attire */}
          <Stack direction="row" spacing="5px" alignItems="center">
            <CheckroomIcon />
            <Typography variant="subtitle1" textTransform="capitalize">
              {event.attire}
            </Typography>
          </Stack>

          {/* Date */}
          <Stack direction="row" spacing="5px" alignItems="center">
            <EventNoteIcon />
            <Typography>
              {/* If event duration is more than 1 day, show start - end.
                  Otherwise, show start*/}
              {endDate - startDate > 0 || endMonth - startMonth > 0
                ? `${fDate(event.start)} - ${fDate(event.end)}`
                : fDateFull(event.start)}
            </Typography>
          </Stack>

          {/* Time */}
          <Stack direction="row" spacing="5px" alignItems="center">
            <AccessTimeIcon />
            <Typography>
              {fTimeShort(event.start)} - {fTimeShort(event.end)}{" "}
            </Typography>
          </Stack>

          {/* Location */}
          <Stack direction="row" spacing="5px" alignItems="center">
            <LocationOnIcon />
            <Typography textTransform="capitalize">{event.location}</Typography>
          </Stack>
        </Stack>

        {/* Stack Buttons Vertically */}
        <Stack
          p={2}
          justifyContent="flex-start"
          width={{ sm: "150px", md: "200px" }}
        >
          {/* DYNAMIC CHECKIN BUTTON - Only show when there is checkin code */}
          <div>
            {event.checkin_code && event.checkin_code.length > 0 && (
              <Button
                className="eventButton"
                id="checkin"
                onClick={handleCheckIn}
                // disabled={(fDate(today) > fDate(event.end) && fTimeHour(today) > fTimeHour(event.end)) ? true : false}
              >
                Check In
              </Button>
            )}
          </div>

          {/* DYNAMIC RSVP BUTTON - Only show when has signup is 1*/}
          <div>
            {event.has_signup === 1 && (
              <Button
                className="eventButton"
                id={cancelSignUp ? "cancel" : "signup"}
                // onClick={
                //   cancelSignUp
                //     ? event.signup_link
                //       ? window.open(`${event.signup_link}`)
                //       : handleCancel
                //     : handleSignUp
                // }
                onClick={() => {
                  if (!event.signup_link) {
                    if (cancelSignUp) handleCancel();
                    else handleSignUp();
                  } else {
                    if (cancelSignUp) handleCancel();
                    else {
                      handleSignUp();
                      window.open(`${event.signup_link}`);
                    }
                  }
                }}
              >
                {cancelSignUp ? "Cancel RSVP" : "Sign Up"}
              </Button>
            )}
          </div>

          {/* Show Join if there's a meeting link */}
          <div>
            {event.meeting_link && (
              <Button
                className="eventButton"
                id="joinEvent"
                onClick={() => handleOpenLink(event.meeting_link)}
              >
                Join
              </Button>
            )}
          </div>

          {/* Show Map if there's a map link */}
          <div>
            {event.map_link && (
              <Button
                className="eventButton"
                id="viewMap"
                onClick={() => handleOpenLink(event.map_link)}
              >
                View Map
              </Button>
            )}
          </div>
        </Stack>
      </Stack>
    </div>
  );
}
