import { Stack, Typography, Button, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fDateTime } from "../../Formatter/DateTimeFormat";
import { DeleteForever } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import "../../Styles/styles-myaccount.css";
import MixerResumeUploadModal from "./MixerResumeUploadModal";
import {
  getResumeByPSID,
  getResumeFileByPSID,
} from "../../APIs/member_functions";
import DeleteMixerResumeModal from "./DeleteMixerResumeModal";

export default function CareerMixerResume({ profile }) {
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [resume, setResume] = useState();
  const [resumeUploaded, setResumeUploaded] = useState(false);

  async function fetchData() {
    const res = await getResumeByPSID(profile.psid);
    if (res.data.length > 0) {
      // console.log(res.data[0]);
      setResume(res.data[0]);
      setResumeUploaded(true);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  function handleResumeModal() {
    setOpenResumeModal(!openResumeModal);
  }

  function handleDeleteModal() {
    setOpenDeleteModal(!openDeleteModal);
  }

  async function viewMixerResume() {
    const res = await getResumeFileByPSID(profile.psid);
    // Create URL for resume file
    const fileURL = URL.createObjectURL(res);
    window.open(fileURL);
  }

  return (
    <div className="accountCard">
      {/* Label */}
      <Typography id="label" pl={2}>
        Upload your Resume to attend Career Mixer
      </Typography>

      {/* Info Message */}
      <Stack p={2} spacing={1} width="480px">
        <Typography variant="body1">
          You will need to upload your resume here in order to attend the MISSO
          Career Mixer.
        </Typography>
        <Typography variant="body1">
          You can delete your current resume and upload a new one at anytime.
        </Typography>
        <Typography variant="body1">
          Don't forget to sign up for <b>Resume Review</b> in the Development
          tab to receive feedbacks from our PD officer!
        </Typography>
      </Stack>

      {/* Stack Upload button and file uploaded */}
      <Stack direction="row" spacing={2} alignItems="center" p={2}>
        {/* Button to open Modal to upload Resume for Corp/PD to share with companies */}
        <Button
          variant="contained"
          onClick={handleResumeModal}
          disabled={resumeUploaded}
        >
          Upload Resume
        </Button>
        {/* Button to Delete Current Resume from DB */}
        {resumeUploaded ? (
          <Stack>
            <Stack direction="row" alignItems="center">
              <Link
                justifyContent={"center"}
                onClick={viewMixerResume}
                sx={{ cursor: "pointer" }}
              >
                {resume.res_name}
              </Link>
              <IconButton onClick={handleDeleteModal}>
                <DeleteForever color="error" />
              </IconButton>
            </Stack>
            <Typography fontSize="12px">
              Uploaded on: {fDateTime(resume.created_date)}
            </Typography>
          </Stack>
        ) : (
          <Typography fontStyle="italic">
            No resume uploaded for Career Mixer
          </Typography>
        )}
      </Stack>

      <MixerResumeUploadModal
        openModal={openResumeModal}
        handleModal={handleResumeModal}
        profile={profile}
        fetchData={fetchData}
      />

      <DeleteMixerResumeModal
        openModal={openDeleteModal}
        handleModal={handleDeleteModal}
        profile={profile}
        resume={resume}
        setResumeUploaded={setResumeUploaded}
      />
    </div>
  );
}
