import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableSortLabel,
} from "@mui/material/";
import { fDateTime } from "../../Formatter/DateTimeFormat";
import PointHistoryFilterBar from "./PointHistoryFilterBar";

import "../../Styles/styles-myaccount.css";
import { getActivityLogByMember } from "../../APIs/member_functions";
import { useAuth } from "../Authentication/AuthContext";

const TABLE_HEAD = [
  { id: "type", label: "Type", align: "left" }, // PM, volunteer, social, committee
  { id: "points", label: "Points", align: "left" }, // 35, 15, 25
  { id: "description", label: "Description", align: "left" }, // check in [event name], committee duties, join [committee name]
  { id: "date", label: "Date", align: "left" }, //
];

export default function PointHistory({ profile }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pointLogList, setPointLogList] = useState([]);
  const [filteredPointLogList, setFilteredPointLogList] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();
  const [sortIconDirection, setSortIconDirection] = useState("asc");
  const [filterBy, setFilterBy] = useState("all");
  const { setLoading } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortData = (column) => {
    if (order === "asc") {
      const sortedData = [...pointLogList].sort((a, b) =>
        a[column] > b[column] ? 1 : -1
      );
      setPointLogList(sortedData);
      setOrderBy(column);
      setSortIconDirection("asc");
      setOrder("desc");
    }
    if (order === "desc") {
      const sortedData = [...pointLogList].sort((a, b) =>
        a[column] < b[column] ? 1 : -1
      );
      setPointLogList(sortedData);
      setOrderBy(column);
      setSortIconDirection("desc");
      setOrder("asc");
    }
  };
  // Sort descending function by date
  const sortList = (arr) =>
    arr.sort(function (a, b) {
      var keyA = a.date,
        keyB = b.date;
      // Compare 2 'date' fields
      if (keyA < keyB) return 1; // if a < b -> switch
      if (keyA > keyB) return -1;
      return 0;
    });

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const res = await getActivityLogByMember(profile.psid);
      if (res.data) {
        setPointLogList(res.data);
      }
      setLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    let point_log = sortList(pointLogList);
    setFilteredPointLogList(
      filterBy === "all"
        ? point_log
        : filterBy === "committee"
        ? point_log.filter((log) => log.activity_code.startsWith("committee"))
        : filterBy === "other"
        ? point_log.filter(
            (log) =>
              log.activity_code.startsWith("fund") ||
              log.activity_code.startsWith("vol")
          )
        : point_log.filter(
            (log) =>
              !log.activity_code.startsWith("committee") &&
              !log.activity_code.startsWith("fund") &&
              !log.activity_code.startsWith("vol")
          )
    );
    setLoading(false);
  }, [filterBy, pointLogList]);

  return (
    <TableContainer className="accountCard">
      {/* FILTER OPTIONS */}
      <PointHistoryFilterBar setFilterBy={setFilterBy} />
      <Table sx={{ minWidth: 650 }} aria-label="point log table">
        {/* TABLE HEAD */}
        <TableHead>
          <TableRow>
            {TABLE_HEAD.map((item) => (
              <TableCell
                key={item.id}
                align={item.align}
                onClick={() => sortData(item.id)}
              >
                <TableSortLabel
                  active={orderBy === item.id}
                  direction={sortIconDirection}
                >
                  {item.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {/* TABLE BODY */}
        <TableBody>
          {filteredPointLogList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((log) => {
              return (
                <TableRow key={log.log_id} id="row">
                  <TableCell>
                    {log.activity_code.startsWith("committee")
                      ? "Committee Activity"
                      : log.activity_code.startsWith("vol")
                      ? "Volunteer"
                      : log.activity_code.startsWith("fund")
                      ? "Fundraising"
                      : "Event Attendance"}
                  </TableCell>
                  <TableCell>
                    <Typography className="points">{log.points}</Typography>
                  </TableCell>
                  <TableCell>{log.description}</TableCell>
                  <TableCell>{fDateTime(log.created_time)}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {filteredPointLogList.length === 0 && (
        <Typography align="center" fontStyle="italic">
          No data to show.
        </Typography>
      )}
      {/* PAGE SELECTION */}
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={pointLogList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}
